import React, { FC, Fragment, ReactNode } from 'react'

import { RevenueItemType } from '../../../common/types/invoice'
import { InputOrValue, InputOrValueProps } from '../../input-utils'
import { BackLink } from '../back-link'
import { BusinessLookup, BusinessLookupProps } from '../business-lookup'
import { Button, ButtonProps } from '../button'
import { CharactersLeft, CharactersLeftProps } from '../characters-left'
import { ChoiceProps, renderChoice } from '../choice'
import { ItemTable, ItemTableProps } from '../item-table'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { Log, LogProps } from '../log'
import { Payment, PaymentProps } from '../payment'
import { TotalsTable, TotalsTableProps } from '../totals-table'
import { RevenueGeneral, RevenueGeneralProps } from './general'
import { RevenueSearchPanel, RevenueSearchPanelProps } from './search-panel'

type Mode = 'view' | 'edit'
export type RevenueViewParams = { isNew: true } | { isNew: false; id: string; mode: Mode }

interface BusinessDetails {
    lookup: BusinessLookupProps
    copyRevenueLink?: LinkProps
}

interface CitizenDetails {
    input: InputOrValueProps
    charactersLeft?: CharactersLeftProps
}

interface VendorProps {
    logoUrl?: string
    name: string
}

export type RevenueButton =
    | { type: 'button'; props: ButtonProps }
    | { type: 'link'; props: LinkProps }

export interface RevenueEditProps {
    isLoading: false
    backLink: boolean
    searchSidebar?: RevenueSearchPanelProps
    paymentSidebar?: PaymentProps
    vendor?: VendorProps
    recipientTitle: string
    customerType?: ChoiceProps<'business' | 'citizen' | ''>
    businessDetails?: BusinessDetails
    citizenDetails?: CitizenDetails
    title: string
    general: RevenueGeneralProps
    itemTable: ItemTableProps<RevenueItemType>
    addItemButtons?: ButtonProps[]
    commentInput?: InputOrValueProps
    commentCharactersLeft?: CharactersLeftProps
    totalsTable: TotalsTableProps
    customerTypeNote?: string
    buttons: RevenueButton[]
    creditRevenueButton?: RevenueButton
    log?: LogProps
    originalRevenue?: { id: string; number: string }
    creditRevenueComment?: string
}

const renderVendor = ({ logoUrl, name }: VendorProps): ReactNode => {
    const contents = logoUrl ? (
        <img className="logo" src={logoUrl} />
    ) : (
        <h1 className="invoice__vendor-name">{name}</h1>
    )

    return <div className="invoice__vendor">{contents}</div>
}

const renderBusinessDetails = ({ lookup, copyRevenueLink }: BusinessDetails): ReactNode => {
    return (
        <>
            <BusinessLookup {...lookup} />
            {copyRevenueLink && (
                <div>
                    <Link {...copyRevenueLink} className="copy-invoice-link" />
                </div>
            )}
        </>
    )
}

const renderCitizenDetails = ({ input, charactersLeft }: CitizenDetails): ReactNode => (
    <div className="text-multiline">
        <InputOrValue {...input} />
        {charactersLeft && <CharactersLeft {...charactersLeft} />}
    </div>
)

const renderTopLeft = ({
    vendor,
    recipientTitle,
    customerType,
    businessDetails,
    citizenDetails,
}: RevenueEditProps): ReactNode => (
    <div className="invoice__top-left">
        {vendor && renderVendor(vendor)}
        <h1 className="title">{recipientTitle}</h1>
        {customerType && (
            <div>
                {renderChoice({
                    ...customerType,
                    buttonClassName: 'button--primary',
                    selectedButtonClassName: 'button--primary-selected',
                })}
            </div>
        )}
        <div className="invoice__customer-details">
            {businessDetails && renderBusinessDetails(businessDetails)}
            {citizenDetails && renderCitizenDetails(citizenDetails)}
        </div>
    </div>
)

const renderTopRight = ({ general, title }: RevenueEditProps): ReactNode => (
    <div className="invoice__top-right">
        <h1 className="title">{title}</h1>
        <RevenueGeneral {...general} />
    </div>
)

const renderBottomLeft = (props: RevenueEditProps): ReactNode => {
    const {
        addItemButtons,
        commentInput,
        commentCharactersLeft,
        originalRevenue,
        creditRevenueComment,
    } = props

    let comment: ReactNode

    if (commentInput) {
        comment = (
            <>
                <InputOrValue {...commentInput} />
                {commentCharactersLeft && <CharactersLeft {...commentCharactersLeft} />}
            </>
        )
    } else {
        const { id, number } = originalRevenue!

        comment = (
            <>
                {creditRevenueComment + ' '}
                <a href={'#/invoices/view/' + id}>{number}</a>
            </>
        )
    }

    return (
        <div className="invoice__bottom-left">
            {addItemButtons && (
                <div>
                    {addItemButtons.map((button, index) => (
                        <Fragment key={index}>
                            <Button {...button} />{' '}
                        </Fragment>
                    ))}
                </div>
            )}
            <div className="invoice__comment-area">{comment}</div>
        </div>
    )
}

const renderButton = (button: RevenueButton): ReactNode => {
    if (button.type === 'link') {
        return <Link {...button.props} />
    } else {
        return <Button {...button.props} />
    }
}

const renderCreditRevenueButton = (button?: RevenueButton): ReactNode => {
    if (!button) {
        return null
    }

    return <div className="top-margin">{renderButton(button)}</div>
}

const renderBottomRight = ({
    totalsTable,
    customerTypeNote,
    buttons,
    creditRevenueButton,
}: RevenueEditProps) => (
    <div className="invoice__bottom-right">
        <TotalsTable {...totalsTable} />
        <div className="text-right">
            {customerTypeNote}
            <div className="top-margin">
                {buttons.map((button, index) => (
                    <Fragment key={index}>{renderButton(button)} </Fragment>
                ))}
            </div>
            {renderCreditRevenueButton(creditRevenueButton)}
        </div>
    </div>
)

export const RevenueEdit: FC<RevenueEditProps | { isLoading: true }> = (props) => {
    if (props.isLoading) {
        return <LoadingPage />
    }

    const { backLink, searchSidebar, paymentSidebar, itemTable, log } = props

    return (
        <div className="content-area">
            {searchSidebar && <RevenueSearchPanel {...searchSidebar} />}
            {paymentSidebar && <Payment {...paymentSidebar} />}
            <div className="content invoice">
                {backLink && <BackLink />}
                <div className="invoice__top">
                    {renderTopLeft(props)}
                    {renderTopRight(props)}
                </div>
                <ItemTable {...itemTable} />
                <div className="invoice__bottom">
                    {renderBottomLeft(props)}
                    {renderBottomRight(props)}
                </div>
                {log && <Log {...log} />}
            </div>
        </div>
    )
}
