import React, { FC } from 'react'

import { getMinDay } from '../../../common/company-utils'
import { InputValues } from '../../../common/types/inputs'
import {
    getDueDateOptions,
    getStatusOptions,
    getTermOptions,
    renderClearFiltersButton,
} from '../../expense-archive-utils'
import { DateShortcut, getMonthShortcuts, getYearShortcuts } from '../../get-date-shortcuts'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { DateInput } from '../date-input'
import { LoadingPage } from '../loading-page'
import { ExpenseArchiveSidebar } from './archive-sidebar'

const archiveInputs = inputs.expense.archive
const dateInputs = archiveInputs.date

const renderStatusChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.status,
        inputValues,
        options: getStatusOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

const renderShortcut = (shortcut: DateShortcut) => (
    <Button
        text={shortcut.label}
        onClick={() => {
            dateInputs.from.set(shortcut.from)
            dateInputs.to.set(shortcut.to)
        }}
        className="button--secondary archive__date-shortcut"
    />
)

const renderDueDateChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.dueDate,
        inputValues,
        options: getDueDateOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

const renderTermChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.term,
        inputValues,
        options: getTermOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

export const ExpenseArchiveGeneral: FC<RootData> = ({ inputValues, companyData, session }) => {
    if (!companyData.companies) {
        return <LoadingPage />
    }

    const { interimDate } = getCompany(companyData, session)
    const minDate = getMinDay(interimDate)

    return (
        <div className="content-area">
            <ExpenseArchiveSidebar selected="general" />
            <div className="content archive">
                <div>
                    <h1 className="title archive__title">{t.status.get()}</h1>
                    <div>{renderStatusChoice(inputValues)}</div>
                    <h1 className="title archive__title">{t.invoices.date.get()}</h1>
                    <div className="archive__date">
                        <span className="archive__date-label">{t.date.from.get()}</span>
                        <DateInput
                            input={dateInputs.from}
                            inputValues={inputValues}
                            minDate={minDate}
                            className="date-button archive__date-input"
                        />
                    </div>
                    <div className="archive__date">
                        <span className="archive__date-label">{t.date.to.get()}</span>
                        <DateInput
                            input={dateInputs.to}
                            inputValues={inputValues}
                            minDate={minDate}
                            className="date-button archive__date-input"
                        />
                    </div>
                    <div>
                        <div>{getMonthShortcuts().map(renderShortcut)}</div>
                        <div>{getYearShortcuts().map(renderShortcut)}</div>
                    </div>
                    <h1 className="title archive__title">{t.dueDate.get()}</h1>
                    <div>{renderDueDateChoice(inputValues)}</div>
                    <h1 className="title archive__title">{t.term.get()}</h1>
                    <div>{renderTermChoice(inputValues)}</div>
                </div>
                <div className="top-margin">
                    <a className="button button--primary" href="#/expenses/archive/vendor">
                        {t.forward.get()}
                    </a>{' '}
                    <a className="button button--secondary" href="#/expenses/archive/results">
                        {t.show.get()}
                    </a>{' '}
                    {renderClearFiltersButton('general', inputValues)}
                </div>
            </div>
        </div>
    )
}
