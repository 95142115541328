import React from 'react'

import { t } from '../../i18n'

interface Props {
    url?: string
}

const renderImage = (src: string) => <img src={src} className="logo settings__logo" />

const renderNoLogoText = () => <span>{t.settings.logo.notSet.get()}</span>

// TODO render loading icon while generating data URL
export const Logo = ({ url }: Props) => (url ? renderImage(url) : renderNoLogoText())
