import React from 'react'

import { t } from '../../i18n'

export const NoReportData = () => (
    <div className="no-data">
        <div className="no-data__text">{t.reports.noData.get()}</div>
        <div>
            <a href="#/financial-history/balance/edit" className="button no-data__button">
                {t.reports.noData.enter.get()}
            </a>
        </div>
    </div>
)
