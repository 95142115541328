import React, { FC } from 'react'

import { renderAmount } from '../../render-amount'
import { renderValidationErrors } from '../../val-err'
import { Button, ButtonProps } from '../button'
import { DateInput, DateInputProps } from '../date-input'
import { Input, InputProps } from '../input'
import { MenuToggle } from '../menu-toggle'
import { ValidationErrorProps } from '../validation-error'

interface AmountInputsProps {
    interest: {
        title: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    totalToPay: {
        title: string
        amount: number
        isInvalid: boolean
    }
    fromPrepaid: {
        title: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    fromBank: {
        title: string
        amount: number
        isInvalid: boolean
    }
}

export interface VatPaymentSidebarProps {
    onClose: () => void
    title: string
    overpaid?: {
        title: string
        amount: number
    }
    amountInputs?: AmountInputsProps
    date: {
        title: string
        input: DateInputProps
        errors: ValidationErrorProps[]
    }
    errors: ValidationErrorProps[]
    saveButton: Omit<ButtonProps, 'className'>
}

const renderAmountInputs = ({ interest, totalToPay, fromPrepaid, fromBank }: AmountInputsProps) => {
    const totalClass = totalToPay.isInvalid ? 'sidebar-warning' : ''
    const fromBankClass = fromBank.isInvalid ? 'sidebar-warning' : ''

    return (
        <>
            <h3 className="sidebar__section-title">{interest.title}</h3>
            <div>
                <Input {...interest.input} className="input input--white sidebar__input amount" />
                {' €'}
                {renderValidationErrors(interest.errors)}
            </div>
            <h3 className="sidebar__section-title">{totalToPay.title}</h3>
            <div className={totalClass}>{renderAmount(totalToPay.amount)}</div>
            <h3 className="sidebar__section-title">{fromPrepaid.title}</h3>
            <div>
                <Input
                    {...fromPrepaid.input}
                    className="input input--white sidebar__input amount"
                />
                {' €'}
                {renderValidationErrors(fromPrepaid.errors)}
            </div>
            <h3 className="sidebar__section-title">{fromBank.title}</h3>
            <div className={fromBankClass}>{renderAmount(fromBank.amount)}</div>
        </>
    )
}

const renderDate = ({ title, input, errors }: VatPaymentSidebarProps['date']) => (
    <>
        <h3 className="sidebar__section-title">{title}</h3>
        <DateInput
            {...input}
            className="date-button date-button--white sidebar__wide-button"
            renderAsBlock={true}
        />
        {renderValidationErrors(errors)}
    </>
)

export const VatPaymentSidebar: FC<VatPaymentSidebarProps> = (props) => {
    const { onClose, title, overpaid, amountInputs, date, errors, saveButton } = props

    return (
        <div className="sidebar sidebar--flex open">
            <div>
                <MenuToggle onClick={onClose} />
                <h1 className="title sidebar__title">{title}</h1>
                {overpaid && (
                    <>
                        <h3 className="sidebar__section-title">{overpaid.title}</h3>
                        <div>{renderAmount(overpaid.amount)}</div>
                    </>
                )}
                {amountInputs && renderAmountInputs(amountInputs)}
                {renderDate(date)}
                <div className="top-margin bottom-margin">{renderValidationErrors(errors)}</div>
            </div>
            <Button
                {...saveButton}
                className="payment__save-button button--white"
                loadingColor="white"
            />
        </div>
    )
}
