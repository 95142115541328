import React, { FC, Fragment } from 'react'

import { renderAmount } from '../../render-amount'
import { Button, ButtonProps } from '../button'
import { Link, LinkProps } from '../link'
import { MonthNav, MonthNavProps } from '../month-nav'
import { renderVatTable, VatTableProps } from './vat-table'

export interface VatSectionProps {
    title: string
    table: VatTableProps
    selectButton?: Omit<ButtonProps, 'className'>
    editButtons?: ButtonProps[]
}

export interface VatPaymentProps {
    title: string
    amount: number
    paidText?: string
    paymentLink?: LinkProps
}

export interface DeclarationRow {
    text: string
    amount: number
}

interface DeclarationInfoProps {
    showHideButton: ButtonProps
    rows: DeclarationRow[]
}

export interface TaxesVatMainProps {
    isLoading: false
    monthNav: MonthNavProps
    revenueSection: VatSectionProps
    expenseSection: VatSectionProps
    payment?: VatPaymentProps
    declarationInfo?: DeclarationInfoProps
    prepaidText: string
}

const VatSection: FC<VatSectionProps> = ({ title, table, selectButton, editButtons }) => {
    return (
        <>
            <h2 className="taxes__heading">{title}</h2>
            {renderVatTable(table)}
            {selectButton && (
                <div>
                    <Button {...selectButton} className="button--primary" />
                </div>
            )}
            {editButtons && (
                <div>
                    {editButtons.map((button, index) => (
                        <Fragment key={index}>
                            <Button {...button} />{' '}
                        </Fragment>
                    ))}
                </div>
            )}
        </>
    )
}

const VatPayment: FC<VatPaymentProps> = ({ title, amount, paidText, paymentLink }) => (
    <>
        <h2 className="taxes__heading nowrap">{title}</h2>
        {renderAmount(amount)} {paidText}
        {paymentLink && <Link {...paymentLink} className="taxes__overpaid-link" />}
    </>
)

const DeclarationInfo: FC<DeclarationInfoProps> = ({ showHideButton, rows }) => (
    <div className="top-margin extra">
        <Button {...showHideButton} />
        {rows.map(({ text, amount }, index) => (
            <div key={index}>
                {text}
                {' = '}
                {renderAmount(amount)}
            </div>
        ))}
    </div>
)

export const TaxesVatMain: FC<TaxesVatMainProps> = (props) => {
    const { monthNav, revenueSection, expenseSection, payment, declarationInfo, prepaidText } =
        props

    return (
        <>
            <div className="taxes__month-wrapper">
                <div>
                    <MonthNav {...monthNav} />
                </div>
            </div>
            <VatSection {...revenueSection} />
            <VatSection {...expenseSection} />
            {payment && <VatPayment {...payment} />}
            {declarationInfo && <DeclarationInfo {...declarationInfo} />}
            <div className="top-margin">{prepaidText}</div>
        </>
    )
}
