import lodashFind from 'lodash/find'
import React, { FC } from 'react'

import { getAmountWithoutVat, getAmountWithVat } from '../../../common/card-payment-utils'
import { addressToString } from '../../../common/company-utils'
import { CardPaymentStatus } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ApiCardPayment } from '../../../common/types/card-payment'
import { ApiCompany } from '../../../common/types/company'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { getDefaultVatPercentage, isVatPayer } from '../../../common/vat-utils'
import { formatAmount } from '../../format-amount'
import { inputs } from '../../inputs'
import { getCompany, INIT_PAYMENT_PROCESS, initFirstPayment } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { Checkbox } from '../checkbox'
import { Input } from '../input'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

// TODO i18n

const bilInputs = inputs.initCompany.billing

const isPending = (status: CardPaymentStatus) =>
    status === CardPaymentStatus.initial ||
    status === CardPaymentStatus.waiting_for_sca ||
    status === CardPaymentStatus.sent_for_processing ||
    status === CardPaymentStatus.waiting_for_3ds_response

const renderCompany = (company: ApiCompany) => {
    return (
        <>
            <div className="init-company__billing-company-title">BOOKY TEENUSE EEST TASUJA</div>
            <div className="init-company__billing-company-field">
                <div className="init-company__billing-company-label">Nimi :</div>
                <div className="init-company__billing-company-value">{company.name}</div>
            </div>
            <div className="init-company__billing-company-field">
                <div className="init-company__billing-company-label">Registrinumber :</div>
                <div className="init-company__billing-company-value">{company.regCode}</div>
            </div>
            <div className="init-company__billing-company-field">
                <div className="init-company__billing-company-label">Aadress :</div>
                <div className="init-company__billing-company-value">
                    {addressToString(company.address)}
                </div>
            </div>
            {company.vatId ? (
                <div className="init-company__billing-company-field">
                    <div className="init-company__billing-company-label">KMKR number :</div>
                    <div className="init-company__billing-company-value">{company.vatId}</div>
                </div>
            ) : null}
        </>
    )
}

const renderPendingPaymentWarning = (isVisible: boolean) => {
    if (!isVisible) {
        return null
    }

    return (
        <div className="init-company__text-row">
            {
                'NB! Teil on mõni varasem makse veel pooleli. Soovitame uut makset alustada ainult juhul, '
            }
            kui olete veendunud, et varasemad maksed on katkestatud.
        </div>
    )
}

const renderPaymentButton = (
    anyPendingPayments: boolean,
    inputValues: InputValues,
    processes: Processes,
) => {
    const agreementNeeded = !bilInputs.agree.get(inputValues)

    return (
        <>
            {renderPendingPaymentWarning(anyPendingPayments)}
            <div className="init-company__payment-button-row">
                <Button
                    text="Maksma"
                    onClick={initFirstPayment}
                    processes={processes}
                    processName={INIT_PAYMENT_PROCESS}
                    className="button--white init-company__payment-button"
                    loadingColor="white"
                    disabled={agreementNeeded}
                    title={agreementNeeded ? 'Jätkamiseks peate tingimustega nõustuma' : undefined}
                />
            </div>
        </>
    )
}

const renderInformation = () => (
    <div className="init-company__payment-info">
        <div>
            Enne kasutamist tutvuge{' '}
            <a
                href="https://minubooky.ee/terms/privacy"
                target="_blank"
                className="init-company__link"
                rel="noreferrer"
            >
                Booky Privaatsustingimustega
            </a>
            .
        </div>
        <p>
            Teenuse eest tasu laekumisel kasutab Booky{' '}
            <a
                href="https://every-pay.com/et/"
                target="_blank"
                className="init-company__link"
                rel="noreferrer"
            >
                EveryPay
            </a>{' '}
            makselahendust ning teie poolt sisestatud kaardiandmeid Booky ei näe.
        </p>
        <p>
            Teil on võimalik teenusest igal hetkel loobuda, sulgedes Booky rakenduses oma ettevõtte
            konto.
        </p>
        <p>Jooksva kuu eest juba ettemakstud tasu ei tagastata.</p>
        <p>Igakuise arve saadame teile siin lehel teie poolt märgitud e-posti aadressile.</p>
        <p>
            Soovi korral võtke meiega ühendust{' '}
            <a href="mailto:info@minubooky.ee" className="init-company__link">
                info@minubooky.ee
            </a>
        </p>
    </div>
)

const renderPrice = (vatPayer: boolean) => {
    const invoiceDate = Day.today()
    const vatPercentage = getDefaultVatPercentage(invoiceDate)
    const amountWithVat = getAmountWithVat(vatPayer, vatPercentage)

    if ('vatPayer') {
        const amountWithoutVat = getAmountWithoutVat(vatPayer, vatPercentage)

        return (
            <div className="init-company__text-row">
                <span className="init-company__text init-company__text--bold">
                    Raamatupidamisprogramm Booky kasutamine kuutasuga{' '}
                    {formatAmount(amountWithoutVat)}€
                </span>{' '}
                <span className="init-company__text init-company__text--thin">
                    (käibemaksuga {formatAmount(amountWithVat)}
                    €)
                </span>
            </div>
        )
    } else {
        return (
            <div className="init-company__text-row">
                <span className="init-company__text init-company__text--bold">
                    Raamatupidamisprogramm Booky kasutamine kuutasuga {formatAmount(amountWithVat)}€
                </span>
            </div>
        )
    }
}

const renderContent = (rootData: RootData, cardPayments: ApiCardPayment[]) => {
    const { companyData, inputValues, processes, session, validationErrors } = rootData
    const successfulPayment = lodashFind(cardPayments, (payment) => payment.success)

    if (successfulPayment) {
        return (
            <div className="init-company__already-paid">
                <div>Olete ettevõtte kasutamise eest juba tasunud.</div>
                <div className="top-margin">
                    <a
                        href={'#/init-company/payment-status/' + successfulPayment._id}
                        className="button button--white"
                    >
                        Vaata makse infot
                    </a>
                </div>
            </div>
        )
    }

    const anyPendingPayments = cardPayments.some((payment) => isPending(payment.status))
    const company = getCompany(companyData, session)
    const valErrors = validationErrors[INIT_PAYMENT_PROCESS]

    const footerLabels = [
        'Booky OÜ',
        'Pärnu mnt 15 10141, Tallinn, Eesti',
        'Registrinumber 14176553',
        'KMKR EE102054679',
    ]

    const vatPayer = isVatPayer(company.vatPeriods)

    return (
        <>
            <div className="flex-center">
                <div className="init-company__billing-fields">
                    {renderCompany(company)}
                    <div className="init-company__billing-contact-title">BOOKY ARVE SAAJA</div>
                    <div className="init-company__input-row">
                        <Input
                            input={bilInputs.contact.name}
                            inputValues={inputValues}
                            placeholder="Nimi"
                            className="init-company__input init-company__input--wide"
                        />
                        {valErr(valErrors, 'details.billingName')}
                    </div>
                    <div className="init-company__input-row init-company__input-row--left">
                        <datalist id="emails">
                            <option value={company.email} />
                            <option value={session!.email} />
                        </datalist>
                        <Input
                            input={bilInputs.contact.email}
                            inputValues={inputValues}
                            list="emails"
                            placeholder="E-post"
                            className="init-company__input init-company__input--wide"
                        />
                        {valErr(valErrors, 'details.billingEmail')}
                    </div>
                </div>
                {renderInformation()}
            </div>
            <div className="init-company__billing-bottom">
                {renderPrice(vatPayer)}
                <div className="init-company__text-row">
                    <Checkbox input={bilInputs.agree} inputValues={inputValues} />
                    {' Nõustun tingimuste ning igakuiste maksetega'}
                </div>
                <div className="init-company__credit-card-icons">
                    <img
                        src="/icons/mastercard.png"
                        className="init-company__credit-card-icon"
                        alt="Mastercard"
                        title="Mastercard"
                    />
                    <img
                        src="/icons/visa.png"
                        className="init-company__credit-card-icon"
                        alt="VISA"
                        title="VISA"
                    />
                </div>
                {renderPaymentButton(anyPendingPayments, inputValues, processes)}
            </div>
            <div className="init-company__billing-footer">{footerLabels.join(' \u2022 ')}</div>
        </>
    )
}

export const InitCompanyBilling: FC<RootData> = (rootData) => {
    const { companyData, serverConf, session } = rootData

    if (!companyData.companies || !companyData.cardPayments || !serverConf) {
        return <LoadingPage />
    }

    const company = getCompany(companyData, session)

    return (
        <div className="init-company">
            <InitCompanyNav activeItem="billing" company={company} />
            <div className="init-company__main">
                {renderContent(rootData, companyData.cardPayments)}
            </div>
        </div>
    )
}
