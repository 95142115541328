import lodashFind from 'lodash/find'
import React, { ReactElement } from 'react'

import { cleanString } from '../common/clean-string'
import { normalizeBusinessName } from '../common/normalize-business-name'

export const highlight = (original: string, search: string) => {
    const cleanSearch = cleanString(search, true)

    const normalized = normalizeBusinessName(original)
    const [fullNorm] = normalized
    const matchedNorm = lodashFind(normalized, (norm) => norm.startsWith(cleanSearch))!

    const start = fullNorm.length - matchedNorm.length
    const end = start + cleanSearch.length - 1

    const segments: Array<ReactElement> = []
    let segmentStart = 0

    const addSegment = (bold: boolean, text: string) =>
        segments.push(React.createElement(bold ? 'b' : 'span', null, text))

    let normPos = 0
    let prevBold = false

    for (let pos = 0; pos < original.length; pos += 1) {
        const c = original[pos]
        const clean = cleanString(c, false)
        let bold: boolean

        if (clean === fullNorm[normPos]) {
            bold = normPos >= start && normPos <= end
            normPos += 1
        } else {
            bold = false
        }

        if (bold !== prevBold) {
            addSegment(prevBold, original.substring(segmentStart, pos))
            segmentStart = pos
        }

        prevBold = bold
    }

    addSegment(prevBold, original.substring(segmentStart))
    return <span>{segments}</span>
}
