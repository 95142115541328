import React from 'react'

// TODO change text: string to content: React.Node?
export interface LinkProps {
    text: string
    to?: string
    onClick?: () => any
    className?: string
    tooltip?: string
}

export const Link = ({ text, to, onClick, className, tooltip }: LinkProps) => (
    <a href={to} onClick={onClick} className={className} title={tooltip}>
        {text}
    </a>
)
