import classnames from 'classnames'
import React, { Component } from 'react'

import { cleanString } from '../../common/clean-string'
import { Input, InputValues } from '../../common/types/inputs'

interface Props {
    input: Input<string>
    inputValues: InputValues
    options: Map<string, string>
    searchSize?: number
    onSelect?: (value: string) => void
}

interface State {
    searchValue: string
    isOpen: boolean
    highlightedIndex: number
}

interface Match {
    label: string
    value: string
}

export class Dropdown extends Component<Props, State> {
    override state = {
        searchValue: '',
        isOpen: false,
        highlightedIndex: 0,
    }

    static defaultProps: Partial<Props> = { searchSize: 20 }

    private searchInputNode: HTMLInputElement | null

    private getSelectedOptionLabel() {
        const { input, inputValues, options } = this.props

        if (!input.hasValue(inputValues)) {
            return ''
        }

        const inputValue = input.get(inputValues)
        const selectedOptionLabel = options.get(inputValue)

        if (!selectedOptionLabel) {
            throw new Error('Invalid value for dropdown: ' + inputValue)
        }

        return selectedOptionLabel
    }

    override componentDidMount() {
        this.setState({ searchValue: this.getSelectedOptionLabel() })
    }

    override componentDidUpdate() {
        if (this.state.isOpen && this.searchInputNode) {
            this.searchInputNode.focus()
        }
    }

    private onSearchChange(evt: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchValue: evt.target.value,
            isOpen: true,
            highlightedIndex: 0,
        })
    }

    private getMatches() {
        const { options } = this.props
        const matches: Match[] = []
        const textToMatch = cleanString(this.state.searchValue, true)

        options.forEach((label, value) => {
            const pos = cleanString(label, true).indexOf(textToMatch)

            if (pos !== -1) {
                matches.push({ label, value })
            }
        })

        return matches
    }

    private onEnter(matches: Match[]) {
        const { highlightedIndex } = this.state
        const { input, onSelect } = this.props
        const match = matches[highlightedIndex]
        input.set(match.value)

        if (onSelect) {
            onSelect(match.value)
        }

        this.setState({
            isOpen: false,
            searchValue: match.label,
            highlightedIndex: 0,
        })
    }

    private onArrowUp() {
        const { highlightedIndex } = this.state

        if (highlightedIndex > 0) {
            this.setState({ highlightedIndex: highlightedIndex - 1 })
        }
    }

    private onArrowDown(maxIndex: number) {
        const { highlightedIndex } = this.state

        if (highlightedIndex < maxIndex) {
            this.setState({ highlightedIndex: highlightedIndex + 1 })
        }
    }

    private onKeyDown(evt: React.KeyboardEvent<HTMLElement>) {
        const { key } = evt

        if (key !== 'Enter' && key !== 'ArrowUp' && key !== 'ArrowDown') {
            return
        }

        const matches = this.getMatches()
        const { highlightedIndex } = this.state

        if (highlightedIndex < matches.length) {
            if (key === 'Enter') {
                this.onEnter(matches)
            }

            if (key === 'ArrowUp') {
                this.onArrowUp()
            }

            if (key === 'ArrowDown') {
                this.onArrowDown(matches.length - 1)
            }
        }

        evt.stopPropagation()
        evt.preventDefault()
    }

    private renderSearchInput() {
        const { input, inputValues } = this.props
        const { isOpen } = this.state

        if (!input.hasValue(inputValues) || isOpen) {
            return (
                <input
                    ref={(node) => (this.searchInputNode = node as HTMLInputElement)}
                    value={this.state.searchValue}
                    onChange={(evt) => this.onSearchChange(evt)}
                    size={this.props.searchSize}
                />
            )
        } else {
            return <span>{this.getSelectedOptionLabel()}</span>
        }
    }

    private toggleOpen() {
        const isOpen = !this.state.isOpen
        this.setState({ isOpen })
    }

    private renderOpenButton() {
        return (
            <img
                src="/icons/triangle.svg"
                className="open-auto-complete"
                onClick={() => this.toggleOpen()}
            />
        )
    }

    private renderMatch(match: Match, index: number) {
        const { input, onSelect } = this.props
        const { highlightedIndex } = this.state
        const onMouseEnter = () => this.setState({ highlightedIndex: index })

        const onClick = () => {
            input.set(match.value)

            if (onSelect) {
                onSelect(match.value)
            }

            this.setState({
                isOpen: false,
                searchValue: match.label,
            })
        }

        const className = classnames('match', { highlighted: index === highlightedIndex })

        return (
            <div className={className} onMouseEnter={onMouseEnter} onClick={onClick}>
                {match.label}
            </div>
        )
    }

    private renderMatches() {
        if (!this.state.isOpen) {
            return null
        }

        const matches = this.getMatches()

        if (!matches.length) {
            // TODO: no matches text
            return null
        }

        return (
            <div className="auto-complete">
                {matches.map((match, index) => this.renderMatch(match, index))}
            </div>
        )
    }

    override render() {
        return (
            <div onKeyDown={(evt) => this.onKeyDown(evt)}>
                {this.renderSearchInput()}
                {this.renderOpenButton()}
                {this.renderMatches()}
            </div>
        )
    }
}
