import React, { FC, ReactNode } from 'react'

import { expenseTypes } from '../../../common/enums'
import { ExpenseType } from '../../../common/types/enums'
import { ApiExpense } from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import {
    copyItemsFromExpense,
    getLatestExpenseFromSameCompany,
    SAVE_PROCESS,
} from '../../state/expense-actions'
import { RootData } from '../../state/root-data'
import { BusinessLookup } from '../business-lookup'
import { renderChoice } from '../choice'

interface Props {
    isNew: boolean
    type: ExpenseType
    editMode: boolean
    rootData: RootData
    vatPayer: boolean
}

const regionInputs = inputs.expense.vendor.region

const renderEeButtons = (inputValues: InputValues) => (
    <div>
        {renderChoice({
            type: 'buttons',
            options: [
                { id: 'yes', label: t.expenses.region.ee.get() },
                { id: 'no', label: t.expenses.region.nonEe.get() },
            ],
            input: regionInputs.ee,
            inputValues,
            forceSelection: true,
            buttonClassName: 'button--primary',
            selectedButtonClassName: 'button--primary-selected',
        })}
    </div>
)

const renderEuButtons = (inputValues: InputValues) => {
    if (regionInputs.ee.get(inputValues) !== 'yes') {
        return (
            <div>
                {renderChoice({
                    type: 'buttons',
                    options: [
                        { id: 'yes', label: t.expenses.region.eu.get() },
                        { id: 'no', label: t.expenses.region.other.get() },
                    ],
                    input: regionInputs.eu,
                    inputValues,
                    forceSelection: true,
                    buttonClassName: 'button--primary',
                    selectedButtonClassName: 'button--primary-selected',
                })}
            </div>
        )
    } else {
        return null
    }
}

const renderRegion = (editMode: boolean, inputValues: InputValues): ReactNode => {
    if (editMode) {
        return (
            <>
                {renderEeButtons(inputValues)}
                {renderEuButtons(inputValues)}
            </>
        )
    } else {
        if (regionInputs.ee.get(inputValues) === 'yes') {
            return t.expenses.region.ee.get()
        } else if (regionInputs.eu.get(inputValues) === 'yes') {
            return t.expenses.region.eu.get() + ' (' + t.expenses.region.nonEe.get() + ')'
        } else {
            return t.expenses.region.other.get()
        }
    }
}

const renderCopyExpenseLink = (
    isEmptyNewRegular: boolean,
    expenses: ApiExpense[] | null,
    inputValues: InputValues,
) => {
    if (isEmptyNewRegular) {
        const latestExpense = getLatestExpenseFromSameCompany(expenses, inputValues)

        if (latestExpense) {
            const onClick = async () => copyItemsFromExpense(latestExpense)

            return (
                <div>
                    <a onClick={onClick} className="copy-expense-link">
                        {t.copyItems.outgoing.get()}
                    </a>
                </div>
            )
        }
    }

    return null
}

export const ExpenseTopLeft: FC<Props> = (props) => {
    const { isNew, type, editMode, rootData, vatPayer } = props

    const {
        businessLookup,
        expenseData: { expenses, itemIds },
        inputValues,
        processes,
        validationErrors,
    } = rootData

    const valErrors = validationErrors[SAVE_PROCESS]
    const isEmptyNewRegular = isNew && type === expenseTypes.regular && !itemIds.length

    return (
        <div className="expense__top-left">
            <h1 className="title">{t.expenses.invoiceVendor.get()}</h1>
            <div className="top-margin">
                <div className="bottom-margin">{renderRegion(editMode, inputValues)}</div>
                <BusinessLookup
                    collectionName="Expenses"
                    businessLookup={businessLookup}
                    inputs={inputs.expense.vendor}
                    inputValues={inputValues}
                    processes={processes}
                    vatPayer={vatPayer}
                    valErrors={valErrors}
                    errorPrefix="expense.vendor"
                    disabled={!editMode}
                />
                {renderCopyExpenseLink(isEmptyNewRegular, expenses, inputValues)}
            </div>
        </div>
    )
}
