import React from 'react'

import { Input, InputValues } from '../../../common/types/inputs'
import { renderInputOrValue } from '../../input-utils'

interface Props {
    editMode: boolean
    label: string
    input: Input<string>
    inputValues: InputValues
}

export const InputRow = ({ editMode, label, input, inputValues }: Props) => (
    <div className="settings__input-row">
        <span className="settings__label">
            {label}
            {': '}
        </span>
        {renderInputOrValue(editMode, { input, inputValues })}
    </div>
)
