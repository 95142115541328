import React from 'react'

import { Overrides, ValidationError as TValidationError } from '../common/types/errors'
import { ValidationError, ValidationErrorProps } from './components/validation-error'

export const getValidationErrorProps = (
    errors: TValidationError[] = [],
    key: string,
    overrides?: Overrides,
): ValidationErrorProps[] => {
    return errors.filter((error) => error.key === key).map((error) => ({ error, overrides }))
}

export const renderValidationErrors = (errorsProps: ValidationErrorProps[] = []) => (
    <>
        {errorsProps.map((errorProps, index) => (
            <ValidationError key={index} {...errorProps} />
        ))}
    </>
)

// TODO inline?
export const valErr = (errors: TValidationError[] = [], key: string, overrides?: Overrides) => {
    return renderValidationErrors(getValidationErrorProps(errors, key, overrides))
}
