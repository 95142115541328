import React, { FC } from 'react'

import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { setRoute } from '../../route-utils'
import { RootData } from '../../state/root-data'
import { SAVE_USER_PROCESS, saveUser } from '../../state/user-settings-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { LoadingPage } from '../loading-page'
import { MenuToggle } from '../menu-toggle'
import { UserSettingsMenu } from './menu'

const renderInputRow = (
    inputKey: 'firstName' | 'lastName',
    inputValues: InputValues,
    editMode: boolean,
    valErrors: ValidationError[] | undefined,
) => {
    return (
        <>
            <div className="user-settings__input-row">
                <div className="user-settings__input-label">{t[inputKey].get()}:</div>
                {renderInputOrValue(editMode, {
                    input: inputs.userSettings.general[inputKey],
                    inputValues,
                    className: 'init-company__input init-company__input--wide',
                })}
            </div>
            {valErr(valErrors, inputKey)}
        </>
    )
}

const renderEditSaveButton = (editMode: boolean, processes: Processes) => {
    const className = 'button button--white user-settings__button user-settings__button--wide'

    if (editMode) {
        return (
            <Button
                className={className}
                text={t.save.get()}
                onClick={saveUser}
                processes={processes}
                processName={SAVE_USER_PROCESS}
                domId="save-user"
                loadingColor="white"
            />
        )
    } else {
        return (
            <a id="edit-user-settings" href="#/user-settings/general/edit" className={className}>
                {t.edit.get()}
            </a>
        )
    }
}

export const UserSettingsGeneral: FC<RootData> = (rootData) => {
    const {
        inputValues,
        userData: { profile },
        processes,
        validationErrors,
        view,
    } = rootData

    if (!profile) {
        return <LoadingPage />
    }

    const { editMode } = assertViewName(view, 'UserSettingsGeneral')
    const valErrors = validationErrors[SAVE_USER_PROCESS]

    return (
        <div className="user-settings open">
            <MenuToggle onClick={() => setRoute('#/')} />
            <div className="user-settings__title">{t.userSettings.get()}</div>
            <UserSettingsMenu selected="general" />
            <div className="user-settings__body">
                {renderInputRow('firstName', inputValues, editMode, valErrors)}
                {renderInputRow('lastName', inputValues, editMode, valErrors)}
                {
                    // No simple input for e-mail, it's a more complex case - requires verification, etc
                    <div className="user-settings__input-row">
                        <div className="user-settings__input-label">{t.email.get()}:</div>
                        {profile.email}
                    </div>
                }
                {
                    // To align loading icon
                    <div className="text-center">{renderEditSaveButton(editMode, processes)}</div>
                }
            </div>
        </div>
    )
}
