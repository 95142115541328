import classnames from 'classnames'
import React, { FC } from 'react'

import { t } from '../../i18n'
import { Link } from '../link'

type TaxesPage = 'summary' | 'vat'

interface Props {
    selected?: TaxesPage
}

const pages: TaxesPage[] = ['summary', 'vat']

const renderButton = (page: TaxesPage, isSelected: boolean) => (
    <Link
        to={'#/taxes/' + page}
        text={t.taxes.pages[page].get()}
        className={classnames(
            'button',
            isSelected ? 'button--transparent-selected' : 'button--transparent',
            'archive__section-button',
        )}
    />
)

export const TaxesSidebar: FC<Props> = ({ selected }) => (
    <div className="sidebar">
        <h1 className="sidebar__title-with-icon title">
            <img src="/menu/taxes.svg" className="sidebar__title-icon" />
            <div>{t.taxes.get()}</div>
        </h1>
        {pages.map((page) => renderButton(page, page === selected))}
    </div>
)
