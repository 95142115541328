import React, { FC } from 'react'

import { assertViewName } from '../assert-view-name'
import { t } from '../i18n'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'
import { Link } from './link'
import { BillingSettings } from './settings/billing'
import { CardUpdateStatus } from './settings/card-update-status'
import { CloseCompanyAccount } from './settings/close-account'
import { GeneralSettings } from './settings/general'
import { LogoEdit } from './settings/logo-edit'
import { LogoView } from './settings/logo-view'
import { RevenueSettings } from './settings/revenue'
import { CompanyUserSettings } from './settings/users'
import { VatSettings } from './settings/vat'

const renderContent = (rootData: RootData) => {
    const { page, pageParams } = assertViewName(rootData.view, 'Settings')

    if (page === 'general') {
        return <GeneralSettings {...rootData} />
    } else if (page === 'vat') {
        return <VatSettings {...rootData} />
    } else if (page === 'logo') {
        if (pageParams[0] === 'edit') {
            return <LogoEdit {...rootData} />
        } else {
            return <LogoView {...rootData} />
        }
    } else if (page === 'billing') {
        if (pageParams[0] === 'card-update') {
            return <CardUpdateStatus {...rootData} />
        } else {
            return <BillingSettings {...rootData} />
        }
    } else if (page === 'invoice') {
        return <RevenueSettings {...rootData} />
    } else if (page === 'users') {
        return <CompanyUserSettings {...rootData} />
    } else if (page === 'close-account') {
        return <CloseCompanyAccount {...rootData} />
    } else if (!page) {
        return null
    } else {
        throw new Error('Invalid settings page: ' + page)
    }
}

export const Settings: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    const settingsTranslation = t.settings.get()
    let menuTitle = settingsTranslation

    if (companyData.companies) {
        const company = getCompany(companyData, session)
        menuTitle = company.name + ' ' + settingsTranslation
    }

    return (
        <div className="content-area">
            <div className="sidebar settings-menu">
                <h1 className="settings-menu__title sidebar__title-with-icon title">
                    <img src="/menu/settings.svg" className="sidebar__title-icon" />
                    <div>{menuTitle}</div>
                </h1>
                <div className="settings-menu__section">
                    <div>
                        <Link
                            to="#/settings/general"
                            text={t.settings.company.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/vat"
                            text={t.vat.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/logo"
                            text={t.settings.logo.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/billing"
                            text={t.settings.billing.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/invoice"
                            text={t.settings.revenue.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/users"
                            text={t.users.get()}
                            className="settings-menu__link"
                        />
                    </div>
                    <div>
                        <Link
                            to="#/settings/close-account"
                            text={t.settings.closeAccount.get()}
                            className="settings-menu__link"
                        />
                    </div>
                </div>
            </div>
            <div className="content settings">{renderContent(rootData)}</div>
        </div>
    )
}
