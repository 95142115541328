import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { Card } from '../../../common/types/card-payment'
import { InputValues } from '../../../common/types/inputs'
import { MonthNumber } from '../../../common/types/month-number'
import { Processes } from '../../../common/types/processes'
import { upperCaseFirst } from '../../../common/upper-case-first'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany, INIT_PAYMENT_PROCESS, initCardUpdate } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { InputRow } from './input-row'

const renderRow = (label: string, value: string) => (
    <div className="settings__input-row">
        <span className="settings__label">
            {label}
            {': '}
        </span>
        {value}
    </div>
)

const renderExpiryRow = (year: number, month: MonthNumber) => {
    const expiryMonth = Day.fromNumeric(year, month, 1)
    return renderRow(t.creditCard.expires.get(), upperCaseFirst(expiryMonth.longMonth()))
}

const renderUpdateCardButton = (processes: Processes) => (
    <Button
        text={t.settings.billing.updateCard.get()}
        onClick={initCardUpdate}
        processes={processes}
        processName={INIT_PAYMENT_PROCESS}
        className="button--primary"
    />
)

const renderCardSection = (card: Card, processes: Processes) => {
    const { holderName, lastFourDigits, year, month } = card

    return (
        <>
            <h1 className="title">{t.creditCard.get()}</h1>
            {renderRow(t.creditCard.holderName.get(), holderName)}
            {renderRow(t.creditCard.number.get(), '**** **** **** ' + lastFourDigits)}
            {renderExpiryRow(year, month)}
            {renderUpdateCardButton(processes)}
        </>
    )
}

const renderContactSection = (editMode: boolean, inputValues: InputValues) => {
    const { name, email } = inputs.settings.billing.contact

    return (
        <>
            <h1 className="title">{t.settings.revenueRecipient.get()}</h1>
            <InputRow
                editMode={editMode}
                label={t.name.get()}
                input={name}
                inputValues={inputValues}
            />
            <InputRow
                editMode={editMode}
                label={t.settings.email.get()}
                input={email}
                inputValues={inputValues}
            />
        </>
    )
}

export const BillingSettings: FC<RootData> = (rootData) => {
    const editMode = false // TODO
    const { companyData, inputValues, processes, session } = rootData

    if (!companyData.companies) {
        return <LoadingIcon color="black" />
    } else {
        const company = getCompany(companyData, session)

        return (
            <div>
                {renderCardSection(company.card, processes)}
                {renderContactSection(editMode, inputValues)}
            </div>
        )
    }
}
