import React from 'react'

import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { initReset, PASSWORD_RESET_INIT_PROCESS } from '../../state/password-reset-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { Input } from '../input'

interface Props {
    inputValues: InputValues
    processes: Processes
    valErrors: ValidationError[] | undefined
}

export const Init = ({ inputValues, processes, valErrors }: Props) => (
    <>
        <div className="bottom-margin">{t.password.reset.enterEmail.get()}</div>
        <div>
            <Input
                input={inputs.passwordReset.email}
                inputValues={inputValues}
                placeholder={t.email.get()}
                onEnter={initReset}
                focusOnMount={true}
                className="password-reset__input"
            />
            {valErr(valErrors, 'email', { invalid: t.user.emailNotFound.get() })}
        </div>
        <div className="password-reset__section">
            <Button
                text={t.continue.get()}
                onClick={initReset}
                processes={processes}
                processName={PASSWORD_RESET_INIT_PROCESS}
                className="password-reset__main-button"
                loadingColor="white"
                loadingStyle={{ marginTop: '0.8em', width: 25, height: 25 }}
            />
        </div>
    </>
)
