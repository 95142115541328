import classnames from 'classnames'
import React, { CSSProperties, FC, ReactNode } from 'react'

import { Processes } from '../../common/types/processes'
import { LoadingIcon } from './loading-icon'

export interface ButtonProps {
    text: ReactNode // TODO rename
    onClick?: () => any
    processes?: Processes
    processName?: string
    disabled?: boolean
    domId?: string

    /**
     * A modifier that specifies a background color (like button--primary or button--secondary)
     * must always be applied, as the default may vary based on the user's OS and/or browser.
     */
    className: string

    title?: string
    loadingColor?: string
    loadingStyle?: CSSProperties
}

export const Button: FC<ButtonProps> = (props) => {
    const {
        text,
        onClick,
        processes,
        processName,
        disabled,
        domId,
        className,
        title,
        loadingColor,
        loadingStyle,
    } = props

    if (!disabled && !onClick) {
        throw new Error('onClick is required if button is not disabled')
    }

    if (processes && processName && processes.has(processName)) {
        return (
            <LoadingIcon
                style={loadingStyle || { verticalAlign: 'middle' }}
                color={loadingColor || 'black'}
            />
        )
    } else {
        const fullClassName = classnames('button', className)
        return (
            <button
                onClick={onClick}
                disabled={disabled}
                id={domId}
                className={fullClassName}
                title={title}
            >
                {text}
            </button>
        )
    }
}
