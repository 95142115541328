import classnames from 'classnames'
import React, { FC } from 'react'

import {
    CREDIT,
    DEBIT,
    INCOME_STATEMENT,
    Level1Accounts,
    NET_PROFIT,
    NET_PROFIT_L2,
    NET_PROFIT_L3,
} from '../../../common/accounts'
import { reportModes } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ApiAccount } from '../../../common/types/account'
import { ReportMode } from '../../../common/types/enums'
import { Period, TurnoverData } from '../../../common/types/reports'
import { Column, TableValue, WrappedExcelValue } from '../../../common/types/table'
import { upperCaseFirst } from '../../../common/upper-case-first'
import { getDefaultAccountName } from '../../account-utils'
import { getExcelButtonProps } from '../../excel-utils'
import { ExcelSpec } from '../../excel/types'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { renderExcelMoney } from '../../render-excel-money'
import { addAccountRows, anyNonZero, getAccountNodes, Row } from '../../report-utils'
import { getCompany } from '../../state/company-actions'
import { LOAD_TURNOVER_PROCESS, loadTurnover } from '../../state/report-actions'
import { RootData } from '../../state/root-data'
import { ExcelButton } from '../excel-button'
import { LoadingIcon } from '../loading-icon'
import { renderTable } from '../table'
import { ReportModeButton } from './mode-button'
import { NoReportData } from './no-data'
import { PeriodsChoice } from './periods-choice'

type GroupType = 'debit' | 'credit'

interface Group {
    level1Accounts: Level1Accounts
    type?: GroupType
    rows: Row<Col>[]
}

type Col =
    | 'opening-debit'
    | 'opening-credit'
    | 'current-debit'
    | 'current-credit'
    | 'final-debit'
    | 'final-credit'

const modeInput = inputs.reports.turnover.mode

const getClassAmount = () => ({ className: 'amount' })
const getFirstHeaderProps = () => ({ className: 'turnover-report__first-header' })

const getVal = (row: Row<Col>, col: Col): TableValue => {
    const value = row.amounts!.get(col) ?? '-'

    return {
        browser: renderBrowserAmount(value),
        excel: renderExcelAmount(value),
    }
}

const renderBrowserAmount = (value: string | number) =>
    typeof value === 'number' ? formatAmount(value) : value

const renderExcelAmount = (value: string | number): WrappedExcelValue =>
    typeof value === 'number'
        ? renderExcelMoney(value, true)
        : { value, style: { alignment: { horizontal: 'right' } } }

const getColumns = (mode: ReportMode): Column<Row<Col>>[] => [
    {
        header: { span: 2, getProps: getFirstHeaderProps },
        secondHeader: {
            content: upperCaseFirst(t.account.number.get()),
            getProps: () => ({ className: 'turnover-report__second-header text-left' }),
        },
        render: (row) => {
            const number = row.number

            if (number === NET_PROFIT_L2 || number === NET_PROFIT_L3) {
                return ''
            } else {
                return number ?? ''
            }
        },
        excelWidth: mode === reportModes.short ? 5 : 12,
    },
    {
        secondHeader: {
            content: t.account.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-left' }),
        },
        getProps: (row) => ({
            className: classnames('turnover-report__account-name', {
                'turnover-report__account-name--level4': row.level === 4,
            }),
        }),
        render: (row) => row.label || getDefaultAccountName(row.number!),
        excelWidth: 50,
    },
    {
        header: {
            content: t.reports.turnover.opening.get(),
            span: 2,
            getProps: getFirstHeaderProps,
        },
        secondHeader: {
            content: t.debit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'opening-debit'),
        excelWidth: 12,
    },
    {
        header: { getProps: getFirstHeaderProps },
        secondHeader: {
            content: t.credit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'opening-credit'),
        excelWidth: 12,
    },
    {
        header: {
            content: t.reports.turnover.current.get(),
            span: 2,
            getProps: getFirstHeaderProps,
        },
        secondHeader: {
            content: t.debit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'current-debit'),
        excelWidth: 12,
    },
    {
        header: { getProps: getFirstHeaderProps },
        secondHeader: {
            content: t.credit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'current-credit'),
        excelWidth: 12,
    },
    {
        header: {
            content: t.reports.turnover.final.get(),
            span: 2,
            getProps: getFirstHeaderProps,
        },
        secondHeader: {
            content: t.debit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'final-debit'),
        excelWidth: 12,
    },
    {
        header: { getProps: getFirstHeaderProps },
        secondHeader: {
            content: t.credit.get(),
            getProps: () => ({ className: 'turnover-report__second-header text-right' }),
        },
        getProps: getClassAmount,
        render: (row) => getVal(row, 'final-credit'),
        excelWidth: 12,
    },
]

const getRows = (data: TurnoverData, mode: ReportMode, accounts: ApiAccount[]) => {
    const groups: Group[] = [
        { level1Accounts: DEBIT, type: 'debit', rows: [] },
        { level1Accounts: CREDIT, type: 'credit', rows: [] },
        { level1Accounts: INCOME_STATEMENT, rows: [] },
    ]

    const rows: Row<Col>[] = []

    for (const group of groups) {
        const { type } = group

        const getAmounts = (number: string) => {
            const amounts = new Map<Col, number>()
            const opening = data.past[number] || 0
            const { debit, credit } = data.current[number] || { debit: 0, credit: 0 }

            amounts.set('current-debit', debit)
            amounts.set('current-credit', credit)

            if (type === 'debit') {
                amounts.set('opening-debit', opening)
                amounts.set('final-debit', opening + debit - credit)
            } else if (type === 'credit') {
                amounts.set('opening-credit', opening)
                amounts.set('final-credit', opening + credit - debit)
            }

            return amounts
        }

        const nodes = getAccountNodes(
            group.level1Accounts,
            getAmounts,
            (amounts, number) =>
                mode === reportModes.long || number === NET_PROFIT || anyNonZero(amounts),
            accounts,
        )

        addAccountRows(nodes, group.rows, mode, 1)

        if (mode === reportModes.long) {
            group.rows = group.rows.filter((row) => row.isLeaf)
        }

        if (group.rows.length) {
            group.rows[group.rows.length - 1].className = 'turnover-report__last-row-of-group'
        }

        rows.push(...group.rows)
    }

    return rows
}

const getSubtitle = ({ from, to }: Period) =>
    t.onPeriod.get(Day.fromYmd(from).dmy() + ' - ' + Day.fromYmd(to).dmy())

const renderContent = (rootData: RootData) => {
    const {
        accountData: { accounts },
        companyData,
        inputValues,
        processes,
        progress,
        reports: {
            turnover: { loaded },
            interimDateToday,
            periodsError,
        },
        session,
        validationErrors,
    } = rootData

    const title = t.reports.turnover.get()
    const subtitle = loaded ? getSubtitle(loaded.period) : null

    const titleElement = (
        <h1 className="title">
            {title}
            {subtitle ? <span className="title__sub-title">{subtitle}</span> : null}
        </h1>
    )

    if (interimDateToday) {
        return (
            <>
                {titleElement}
                {t.reports.interimDateToday.get()}
            </>
        )
    }

    if (!loaded || !accounts || !companyData.companies || processes.has(LOAD_TURNOVER_PROCESS)) {
        return <LoadingIcon color="black" />
    }

    const company = getCompany(companyData, session)
    const mode = modeInput.get(inputValues)
    const columns = getColumns(mode)
    const rows = getRows(loaded.data, mode, accounts)
    const valErrors = validationErrors[LOAD_TURNOVER_PROCESS]

    const spec: ExcelSpec<Row<Col>> = {
        columns,
        rows,
        outputName: title + (subtitle ? ' ' + subtitle : ''),
        hasSecondHeader: true,
    }

    const excelButton = getExcelButtonProps(
        spec,
        '',
        processes,
        progress,
        'button--wide button--primary',
    )

    return (
        <>
            <div className="align-right relative">
                <div>
                    <div>
                        <ReportModeButton input={modeInput} inputValues={inputValues} />
                    </div>
                    <div className="top-margin">
                        <ExcelButton {...excelButton} />
                    </div>
                    <PeriodsChoice
                        inputValues={inputValues}
                        periodsError={periodsError}
                        company={company}
                        valErrors={valErrors}
                        load={loadTurnover}
                        singlePeriodMode={true}
                    />
                </div>
            </div>
            {titleElement}
            {renderTable({
                columns,
                rows,
                hasSecondHeader: true,
                noWrapper: true,
                stickyHeader: true,
                tableClassName: 'turnover-report',
            })}
        </>
    )
}

export const TurnoverReport: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    if (companyData.companies) {
        const company = getCompany(companyData, session)

        if (!company.hasReportData) {
            return <NoReportData />
        }
    }

    return (
        <div className="content-area">
            <div className="content report">{renderContent(rootData)}</div>
        </div>
    )
}
