import { assertNever } from './assert-never'
import {
    assetTypes,
    expenseAccountTypes,
    expenseItemTypes,
    moneyForms,
    revenueAccountTypes,
    revenueItemTypes,
} from './enums'
import { mapMember } from './map-member'
import { sort } from './sort'
import { AccountType, ApiAccount, DbAccount } from './types/account'
import { AssetType, MoneyForm } from './types/enums'
import { ExpenseItemType } from './types/expense'
import { RevenueItemType } from './types/invoice'
import { SimpleData } from './types/reports'

export type Level3Accounts = Set<number>
export type Level2Accounts = Record<string, Level3Accounts>
export type Level1Accounts = Record<string, Level2Accounts>

const set1 = new Set([1])
const set2 = new Set([1, 2])
const set3 = new Set([1, 2, 3])

export const CURRENT_ASSETS: Level1Accounts = {
    '1': { '1': set2 },
    '2': { '1': set1 },
    '3': {
        '1': set1,
        '2': new Set([1, 2, 4]), // 3.2.3 is reserved for 9% VAT
        '3': set2,
        '4': set1,
        '5': set1,
    },
    '4': { '1': set1, '2': set1, '3': set1, '4': set1, '5': set1, '6': set1 },
}

export const MONEY_L1 = '1'
export const CASH = '1.1.1.1'
export const BANK = '1.1.2.1'
export const FINANCIAL_INVESTMENTS_L1 = '2'
export const CLAIMS_AGAINST_BUYERS = '3.1.1.1'
export const PREPAID_VAT = '3.2.2.1'
export const PREPAID_VAT_MTA = '3.2.4.1'
export const TRANSACTIONS_WITH_SHAREHOLDERS = '3.3.2.1'
export const GRANTED_LOANS_L3 = '3.5.1'
const EXPENSE_GOODS_STOCK_L3 = '4.1.1'

export const FIXED_ASSETS: Level1Accounts = {
    '20': { '1': set1, '2': set1 },
    '21': { '1': set1 },
    '22': { '1': set1 },
    '23': { '1': set1 },
    '24': { '1': set1, '2': set1, '3': set1, '4': set1, '5': set1, '6': set1 },
    '25': { '1': set1, '2': set3, '3': set1, '4': set1 },
}

export const INVESTMENTS_IN_SUBSIDIARIES_L1 = '20'
export const CLAIMS_AND_PREPAYMENTS_L1 = '22'
export const REAL_ESTATE_INVESTMENTS = '23.1.1.1'
export const MATERIAL_ASSET_L1 = '24'
const ASSET_LAND = '24.1.1.1'
const ASSET_BUILDING = '24.2.1.1'
const ASSET_DEVICE = '24.3.1.1'
const ASSET_OTHER_MATERIAL = '24.4.1.1'
const ACCUMULATED_MATERIAL_DEPRECIATION = '24.6.1.1'
export const IMMATERIAL_ASSET_L1 = '25'
const ASSET_SOFTWARE = '25.2.1.1'
const ASSET_LICENSE = '25.2.2.1'
const ASSET_OTHER_IMMATERIAL = '25.2.3.1'
const ACCUMULATED_IMMATERIAL_DEPRECIATION = '25.4.1.1'

export const DEBIT = { ...CURRENT_ASSETS, ...FIXED_ASSETS }

export const CURRENT_LIABILITIES: Level1Accounts = {
    '40': { '1': set1, '2': set1, '3': set1, '4': set1 },
    '41': {
        '1': set2,
        '2': set1,
        '3': new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
        '4': set3,
        '5': set1,
        '6': set3,
        '7': set1,
    },
    '42': { '1': set1 },
    '43': { '1': set1 },
}

export const SHORT_TERM_LOAN_OBLIGATIONS_L1 = '40'
export const SHORT_TERM_LOANS = '40.1.1.1'
export const LONG_TERM_LOAN_REPAYMENTS_L2 = '40.4'
export const DEBT_TO_SUPPLIER = '41.1.1.1'
export const DEBT_TO_SUPPLIER_FOR_ASSETS = '41.1.2.1'
export const LABOUR_NET_DEBT = '41.2.1.1'
export const TAX_DEBT_L2 = '41.3'
export const SOCIAL_TAX_L3 = '41.3.3'
export const SOCIAL_TAX = '41.3.3.1'
export const PERSONAL_INCOME_TAX = '41.3.4.1'
export const UNEMPLOYMENT_EMPLOYER_L3 = '41.3.5'
export const UNEMPLOYMENT_EMPLOYER = '41.3.5.1'
export const UNEMPLOYMENT_EMPLOYEE_L3 = '41.3.6'
export const UNEMPLOYMENT_EMPLOYEE = '41.3.6.1'
export const PENSION_PAYMENT_L3 = '41.3.7'
export const PENSION_PAYMENT = '41.3.7.1'
export const VAT_DEBT = '41.3.8.1'
export const INTEREST_DEBT = '41.4.2.1'
export const DIVIDEND_DEBT = '41.4.3.1'
export const SALES_VAT_20 = '41.6.1'
export const SALES_VAT_9 = '41.6.2'
export const SALES_VAT_22 = '41.6.3'
export const DEBT_TO_BUYERS = '41.7.1'

export const LONG_TERM_LIABILITIES: Level1Accounts = {
    '60': { '1': set1, '2': set1 },
    '61': { '1': set1, '2': set1 },
    '62': { '1': set1 },
    '63': { '1': set1 },
}

export const LONG_TERM_LOAN_OBLIGATIONS_L1 = '60'

export const EQUITY: Level1Accounts = {
    '80': {
        '1': set1,
        '2': set1,
        '3': set1,
        '4': set1,
        '5': set1,
        '6': set1,
        '7': set1,
        '8': set1,
        '99': set1,
    },
}

export const SHARE_CAPITAL = '80.1.1.1'
export const UNPAID_SHARE_CAPITAL = '80.3.1.1'
export const PREVIOUS_PROFIT = '80.8.1.1'

/**
 * Technically not a real account, but behaves similarly to other accounts in some ways
 * (e.g. adding up to parent account)
 */
export const NET_PROFIT_L2 = '80.99'
export const NET_PROFIT_L3 = NET_PROFIT_L2 + '.1'
export const NET_PROFIT = '80.99.1.1'

export const CREDIT = { ...CURRENT_LIABILITIES, ...LONG_TERM_LIABILITIES, ...EQUITY }
export const BALANCE = { ...DEBIT, ...CREDIT }

export const SALES_REVENUE_L1 = '100'

export const INCOMES: Level1Accounts = {
    '100': { '1': set3 },
    '101': { '1': new Set([1, 2, 3, 4, 5]) },
}

const INCOME_GOODS_L3 = '100.1.2'
const INCOME_SERVICE_L3 = '100.1.3'

export const EXPENSES: Level1Accounts = {
    '102': { '1': set1 },
    '103': { '1': set3 },
    '104': { '1': set1, '2': set1, '3': set1, '4': set1 },
    '105': { '1': set1, '2': set1, '3': set1 },
    '106': { '1': set3 },
    '107': { '1': new Set([1, 2, 3, 4, 5]) },
}

const EXPENSE_GOODS_EXPENSE_L3 = '102.1.1'
const EXPENSE_GOODS_GENERAL_L3 = '103.1.1'
export const BANK_EXPENSES = '103.1.2.1'
export const VEHICLE_REIMBURSEMENT = '103.1.3.1'
export const LABOUR_NET = '104.1.1.1'
export const LABOUR_TAX = '104.4.1.1'
const MATERIAL_DEPRECIATION = '105.1.1.1'
const IMMATERIAL_DEPRECIATION = '105.2.1.1'
export const MTA_INTEREST_EXPENSE = '107.1.5.1'

export const OPERATING_PROFIT = { ...INCOMES, ...EXPENSES }

export const FINANCIAL: Level1Accounts = {
    '108': { '1': set1, '2': set1 },
    '109': { '1': set1 },
    '110': { '1': set1 },
    '111': { '1': set1 },
    '112': { '1': set2, '2': set1 },
}

const SUBSIDIARY_PROFIT_L1 = '108'
const INVESTMENT_PROFIT_L1 = '109'
const INTEREST_INCOME_L1 = '110'
const INTEREST_EXPENSE_L1 = '111'
const FINANCE_OTHER_L1 = '112'
export const FINANCE_OTHER_REVENUE = '112.1.1.1'
export const FINANCE_OTHER_EXPENSE = '112.2.1.1'

export const INCOME_TAX: Level1Accounts = {
    '113': { '1': set1 },
}

const INCOME_TAX_L1 = '113'

export const INCOME_STATEMENT = { ...OPERATING_PROFIT, ...FINANCIAL, ...INCOME_TAX }

const ALL_ACCOUNTS = { ...BALANCE, ...INCOME_STATEMENT }

export const getLevel1Number = (number: string) => {
    const levels = number.split('.')
    return levels[0]
}

export const IncomeAccounts = {
    isCredit: (level1Number: string) =>
        level1Number in INCOMES || level1Number === INTEREST_INCOME_L1,
    isDebit: (level1Number: string) =>
        level1Number in EXPENSES ||
        level1Number === INTEREST_EXPENSE_L1 ||
        level1Number === INCOME_TAX_L1,
    isCreditDebit: (level1Number: string) =>
        level1Number === SUBSIDIARY_PROFIT_L1 ||
        level1Number === INVESTMENT_PROFIT_L1 ||
        level1Number === FINANCE_OTHER_L1, // TODO 112.1 only credit, 112.2 only debit?
}

export const canBeNegative = (level4Number: string) => {
    if (
        level4Number === ACCUMULATED_MATERIAL_DEPRECIATION ||
        level4Number === ACCUMULATED_IMMATERIAL_DEPRECIATION ||
        level4Number === UNPAID_SHARE_CAPITAL ||
        level4Number === NET_PROFIT ||
        level4Number === PREVIOUS_PROFIT // TODO test case that would fail without this
    ) {
        return true
    }

    const level1Number = getLevel1Number(level4Number)
    return IncomeAccounts.isDebit(level1Number) || IncomeAccounts.isCreditDebit(level1Number)
}

export const canBePositive = (level4Number: string) => {
    const level1Number = getLevel1Number(level4Number)
    return !IncomeAccounts.isDebit(level1Number)
}

const getLevel3Numbers = (level1Accounts: Level1Accounts) => {
    const level3Numbers: string[] = []

    for (const level1 of Object.keys(level1Accounts)) {
        for (const level2 of Object.keys(level1Accounts[level1])) {
            for (const level3 of level1Accounts[level1][level2]) {
                level3Numbers.push(level1 + '.' + level2 + '.' + level3)
            }
        }
    }

    return level3Numbers
}

export const ALL_LEVEL3 = getLevel3Numbers(ALL_ACCOUNTS)

// In the account number hierarchy, level 4 numbers are leaves
// (as in tree nodes that have no child nodes)
const getLeaves = (level1Accounts: Level1Accounts) => {
    const leaves: Set<string> = new Set()

    for (const level3Number of getLevel3Numbers(level1Accounts)) {
        leaves.add(level3Number + '.1')
    }

    return leaves
}

export const BALANCE_LEAVES = getLeaves(BALANCE)
export const INCOME_STATEMENT_LEAVES = getLeaves(INCOME_STATEMENT)

export const toDashes = (number: string) => number.replace(/\./g, '-')
export const fromDashes = (number: string) => number.replace(/-/g, '.')

export const convertFromDashes = <T>(data: SimpleData<T>): SimpleData<T> => {
    const result: SimpleData<T> = {}

    for (const dashedNumber of Object.keys(data)) {
        result[fromDashes(dashedNumber)] = data[dashedNumber]
    }

    return result
}

export const convertToDashes = (data: SimpleData<string | number>) => {
    const dataWithDashes: SimpleData<number> = {}

    for (const number of Object.keys(data)) {
        dataWithDashes[toDashes(number)] = Number(data[number])
    }

    return dataWithDashes
}

export const getMoneyFormAccountNumber = (moneyForm: MoneyForm): string => {
    if (moneyForm === moneyForms.cash) {
        return CASH
    } else if (moneyForm === moneyForms.bank) {
        return BANK
    } else {
        throw assertNever(moneyForm, 'money form')
    }
}

export const getAccountType = (level3Number: string): AccountType | null => {
    if (level3Number === INCOME_GOODS_L3) {
        return revenueAccountTypes.goods
    } else if (level3Number === INCOME_SERVICE_L3) {
        return revenueAccountTypes.service
    } else if (
        level3Number === EXPENSE_GOODS_STOCK_L3 ||
        level3Number === EXPENSE_GOODS_EXPENSE_L3
    ) {
        return expenseAccountTypes.goods
    } else if (level3Number === EXPENSE_GOODS_GENERAL_L3) {
        return expenseAccountTypes.general
    } else {
        return null
    }
}

export const getAccountTypeLevel3Numbers = (type: AccountType): string[] => {
    if (type === 'income-service') {
        return [INCOME_SERVICE_L3]
    } else if (type === 'income-goods') {
        return [INCOME_GOODS_L3]
    } else if (type === 'expense-goods') {
        return [EXPENSE_GOODS_STOCK_L3, EXPENSE_GOODS_EXPENSE_L3]
    } else if (type === 'expense-general') {
        return [EXPENSE_GOODS_GENERAL_L3]
    } else {
        throw new Error('Unexpected account type: ' + type)
    }
}

export const getIncomeAccountType = (type: RevenueItemType): AccountType => {
    if (type === revenueItemTypes.service) {
        return revenueAccountTypes.service
    } else if (type === revenueItemTypes.goods) {
        return revenueAccountTypes.goods
    } else {
        throw assertNever(type, 'income item type')
    }
}

export const getIncomeAccountNumber = (type: RevenueItemType): string => {
    if (type === revenueItemTypes.service) {
        return INCOME_SERVICE_L3
    } else if (type === revenueItemTypes.goods) {
        return INCOME_GOODS_L3
    } else {
        throw assertNever(type, 'income item type')
    }
}

export const getExpenseAccountType = (type: ExpenseItemType): AccountType => {
    if (type === expenseItemTypes.goodsExpense || type === expenseItemTypes.goodsStock) {
        // The reason why item types are separate from account types is here.
        // Two item types map to one account type and use the same account numbers.
        return expenseAccountTypes.goods
    } else if (type === expenseItemTypes.general) {
        return expenseAccountTypes.general
    } else {
        throw new Error('Unexpected expense item type: ' + type)
    }
}

export const getExpenseAccountNumber = (type: ExpenseItemType): string => {
    if (type === expenseItemTypes.goodsExpense) {
        return EXPENSE_GOODS_EXPENSE_L3
    } else if (type === expenseItemTypes.goodsStock) {
        return EXPENSE_GOODS_STOCK_L3
    } else if (type === expenseItemTypes.general) {
        return EXPENSE_GOODS_GENERAL_L3
    } else {
        throw new Error('Unexpected expense item type: ' + type)
    }
}

export const getAssetAccountNumber = (assetType: AssetType): string => {
    if (assetType === assetTypes.land) {
        return ASSET_LAND
    } else if (assetType === assetTypes.building) {
        return ASSET_BUILDING
    } else if (assetType === assetTypes.device) {
        return ASSET_DEVICE
    } else if (assetType === assetTypes.otherMaterial) {
        return ASSET_OTHER_MATERIAL
    } else if (assetType === assetTypes.software) {
        return ASSET_SOFTWARE
    } else if (assetType === assetTypes.license) {
        return ASSET_LICENSE
    } else if (assetType === assetTypes.otherImmaterial) {
        return ASSET_OTHER_IMMATERIAL
    } else {
        throw assertNever(assetType, 'asset type')
    }
}

export const isMaterialAsset = (number: string) => number.startsWith(MATERIAL_ASSET_L1 + '.')
export const isImmaterialAsset = (number: string) => number.startsWith(IMMATERIAL_ASSET_L1 + '.')

export const getDepreciationAccount = (isMaterial: boolean, isAccumulated: boolean) => {
    if (isAccumulated) {
        return isMaterial ? ACCUMULATED_MATERIAL_DEPRECIATION : ACCUMULATED_IMMATERIAL_DEPRECIATION
    } else {
        return isMaterial ? MATERIAL_DEPRECIATION : IMMATERIAL_DEPRECIATION
    }
}

const createSet = () => new Set()

export const getValidAccounts = (accounts: DbAccount[]): Map<AccountType, Set<number>> => {
    const validAccounts = new Map<AccountType, Set<number>>()

    for (const account of accounts) {
        mapMember(validAccounts, account.type, createSet).add(account.number)
    }

    return validAccounts
}

export const isCustomAccount = (accountNumber: string, customAccounts: DbAccount[]): boolean => {
    const level4SeparatorIndex = accountNumber.lastIndexOf('.')

    if (level4SeparatorIndex === -1) {
        return false
    }

    const level3Number = accountNumber.substring(0, level4SeparatorIndex)
    const level4Component = accountNumber.substring(level4SeparatorIndex + 1)
    const type = getAccountType(level3Number)

    if (!type) {
        return false
    }

    return customAccounts.some(
        (account) => account.type === type && account.number.toString() === level4Component,
    )
}

export const isAllowedDefaultAccount = (accountNumber: string): boolean =>
    ALL_LEVEL3.some(
        (level3Number) => level3Number !== NET_PROFIT_L3 && accountNumber === level3Number + '.1',
    )

export const buildAllAccounts = (customAccounts: ApiAccount[]) => {
    const groupedCustomAccounts = new Map<string, ApiAccount[]>()

    for (const account of customAccounts) {
        for (const level3Number of getAccountTypeLevel3Numbers(account.type)) {
            mapMember(groupedCustomAccounts, level3Number, () => []).push(account)
        }
    }

    const allAccounts = new Map<string, ApiAccount | string>()

    for (const level3Number of ALL_LEVEL3) {
        const defaultLevel4Number = level3Number + '.1'
        allAccounts.set(defaultLevel4Number, defaultLevel4Number)

        const accounts = groupedCustomAccounts.get(level3Number)

        // TODO: error check if there are custom accounts that don't match any level 3 number
        if (accounts) {
            sort(accounts, (account) => account.number)

            for (const account of accounts) {
                const customLevel4Number = level3Number + '.' + account.number
                allAccounts.set(customLevel4Number, account)
            }
        }
    }

    return allAccounts
}
