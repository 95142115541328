import React, { Component } from 'react'

import { ServerError } from '../../common/server-error'
import { WrappedError } from '../../common/types/errors'
import { Column } from '../../common/types/table'
import { t } from '../i18n'
import { renderTable } from './table'

interface Props {
    errors: WrappedError[]
    closePanel: () => any
}
interface State {
    expanded: boolean
}

export class ErrorPanel extends Component<Props, State> {
    override state = { expanded: false }

    getColumns(): Column<WrappedError>[] {
        const getTimeClass = () => ({ className: 'time' })
        const getMessageClass = () => ({ className: 'message' })

        return [
            {
                header: { content: t.time.get(), getProps: getTimeClass },
                getProps: getTimeClass,
                render: (row) => row.time.asLocal().hms(),
            },
            {
                header: { content: t.error.get() },
                getProps: getMessageClass,
                render: ({ error }) => {
                    if (error instanceof ServerError) {
                        return t.serverError[error.response.errorCode].get()
                    } else {
                        return error.message
                    }
                },
            },
        ]
    }

    renderDetails() {
        if (this.state.expanded) {
            return renderTable({
                columns: this.getColumns(),
                rows: this.props.errors,
                headerClassName: 'error-table-header',
                noWrapper: true,
            })
        } else {
            const onClick = () => this.setState({ expanded: true })

            return (
                <div className="top-margin extra">
                    <a className="details-link" onClick={onClick}>
                        {t.error.showDetails.get()}
                    </a>
                </div>
            )
        }
    }

    override render() {
        return (
            <div className="error-panel">
                <div className="close-button" onClick={this.props.closePanel}>
                    ×
                </div>
                <div className="highlighted first">{t.error.heading.get()}</div>
                <div>{t.error.reportInfo.get()}</div>
                <div className="highlighted">{t.error.recommendations.heading.get()}</div>
                <div className="text-multiline">{t.error.recommendations.get()}</div>
                {this.renderDetails()}
            </div>
        )
    }
}
