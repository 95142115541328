import React from 'react'

import { findById } from '../common/find-by-id'
import { calculateRevenue } from '../common/invoice-utils'
import { keys } from '../common/keys'
import { isOverdue } from '../common/payment-utils'
import { Day } from '../common/time'
import { ChoiceOption, Input, InputValues } from '../common/types/inputs'
import { ApiCustomer, ApiRevenue, FilterSection } from '../common/types/invoice'
import { Column } from '../common/types/table'
import { ButtonProps } from './components/button'
import type { BaseRow } from './components/table'
import { ViewIcon } from './components/view-icon'
import { t, withElements } from './i18n'
import { inputs } from './inputs'
import { getDueDateDescription } from './payment-utils'
import { renderAmountOrDash } from './render-amount'
import { renderExcelMoney } from './render-excel-money'
import { getCustomerName } from './revenue-utils'
import { browserOnly } from './table-utils'

interface RevenueFilter {
    id: string
    inputs: Input<string>[]
    predicate: (revenue: ApiRevenue, ...values: string[]) => boolean
    label: string
    render: (...values: string[]) => string
}

export interface Row extends BaseRow {
    id: string
    confirmed: boolean
    customer: ApiCustomer
    date: string
    term: number
    withoutVat: number
    withVat: number
    number?: string
    paid?: boolean
}

const archiveInputs = inputs.invoice.archive
const customerInputs = archiveInputs.customer

export const getStatusOptions = (): ChoiceOption<string>[] => [
    { id: 'confirmed', label: t.confirmed.get() },
    { id: 'unconfirmed', label: t.unconfirmed.get() },
]

export const getPaidOptions = (): ChoiceOption<string>[] => [
    { id: 'paid', label: t.revenues.paidInvoices.get() },
    { id: 'unpaid', label: t.revenues.unpaidInvoices.get() },
]

export const getDueDateOptions = (): ChoiceOption<string>[] => [
    { id: 'not-overdue', label: t.dueDate.future.get() },
    { id: 'overdue', label: t.revenues.overdueInvoices.get() },
]

export const getTermOptions = (): ChoiceOption<string>[] => {
    const days = '21'
    const numberSpan = <span className="numeric">{days}</span>

    return [
        {
            id: 'up-to-21',
            label: t.archive.term.upTo.get(days),
            renderButtonContents: () => withElements(t.archive.term.upTo, numberSpan),
        },
        {
            id: 'over-21',
            label: t.archive.term.over.get(days),
            renderButtonContents: () => withElements(t.archive.term.over, numberSpan),
        },
    ]
}

export const getCustomerTypeOptions = (): ChoiceOption<string>[] => [
    { id: 'business', label: t.business.get() },
    { id: 'citizen', label: t.citizen.get() },
]

export const getItemTypeOptions = (): ChoiceOption<string>[] => [
    { id: 'service', label: t.enums.incomeTypes.service.get() },
    { id: 'goods', label: t.enums.incomeTypes.goods.get() },
]

export const getDiscountOptions = (): ChoiceOption<string>[] => [
    { id: 'yes', label: t.discount.with.get() },
    { id: 'no', label: t.discount.without.get() },
]

const renderChoice = (choice: string, options: ChoiceOption<string>[]) => {
    const option = findById(options, choice)

    if (!option) {
        throw new Error('Option not found: ' + choice)
    }

    return option.label
}

const getFilters = (): { [section in FilterSection]: RevenueFilter[] } => ({
    general: [
        {
            id: 'status',
            inputs: [archiveInputs.status],
            predicate: (revenue, confirmStatus) =>
                revenue.confirmed === (confirmStatus === 'confirmed'),
            label: t.status.get(),
            render: (confirmStatus) => renderChoice(confirmStatus, getStatusOptions()),
        },
        {
            id: 'date',
            inputs: [archiveInputs.date.from, archiveInputs.date.to],
            predicate: (revenue, from, to) =>
                (revenue.date >= from || !from) && (revenue.date <= to || !to),
            label: t.invoices.date.get(),
            render: (from: string, to: string) => {
                const fromStr = from ? Day.fromYmd(from).longDate() : '...'
                const toStr = to ? Day.fromYmd(to).longDate() : '...'
                return fromStr + ' - ' + toStr
            },
        },
        {
            id: 'paid',
            inputs: [archiveInputs.paid],
            predicate: (revenue, paid) => {
                if (paid === 'paid' && !revenue.paid) {
                    return false
                } else if (paid === 'unpaid' && revenue.paid) {
                    return false
                }

                return true
            },
            label: t.payments.incoming.get(),
            render: (paid) => renderChoice(paid, getPaidOptions()),
        },
        {
            id: 'dueDate',
            inputs: [archiveInputs.dueDate],
            predicate: (revenue, dueDate) => {
                const overdue = isOverdue(revenue.date, revenue.term)

                if (dueDate === 'not-overdue' && (revenue.paid || overdue)) {
                    return false
                } else if (dueDate === 'overdue' && (revenue.paid || !overdue)) {
                    return false
                }

                return true
            },
            label: t.dueDate.get(),
            render: (dueDate) => renderChoice(dueDate, getDueDateOptions()),
        },
        {
            id: 'term',
            inputs: [archiveInputs.term],
            predicate: (revenue, term) =>
                (revenue.term <= 21 || term !== 'up-to-21') &&
                (revenue.term > 21 || term !== 'over-21'),
            label: t.term.get(),
            render: (term) => renderChoice(term, getTermOptions()),
        },
    ],
    customer: [
        {
            id: 'customerType',
            inputs: [customerInputs.type],
            predicate: (revenue, customerType) =>
                revenue.customer.isBusiness === (customerType === 'business'),
            label: t.customerType.get(),
            render: (customerType) => renderChoice(customerType, getCustomerTypeOptions()),
        },
        {
            id: 'business',
            inputs: [customerInputs.regCode, customerInputs.name],
            predicate: (revenue, regCode, _name) =>
                revenue.customer.isBusiness && revenue.customer.regCode === regCode,
            label: t.business.get(),
            render: (_regCode, businessName) => businessName,
        },
    ],
    contents: [
        {
            id: 'itemType',
            inputs: [archiveInputs.itemType],
            predicate: (revenue, itemType) => revenue.items.some((item) => item.type === itemType),
            label: t.type.get(),
            render: (itemType) => renderChoice(itemType, getItemTypeOptions()),
        },
        {
            id: 'discount',
            inputs: [archiveInputs.discount],
            predicate: (revenue, discount) => {
                const hasDiscount = revenue.items.some((item) => item.discount > 0)
                return hasDiscount === (discount === 'yes')
            },
            label: t.discount.get(),
            render: (discount) => renderChoice(discount, getDiscountOptions()),
        },
    ],
})

export const getActiveFilters = (inputValues: InputValues) => {
    const filters = getFilters()
    const activeFilters = []

    for (const section of keys(filters)) {
        for (const filter of filters[section]) {
            const values = filter.inputs.map((input) => input.get(inputValues))

            if (values.some(Boolean)) {
                activeFilters.push({ filter, values })
            }
        }
    }

    return activeFilters
}

const getLeftAligned = () => ({ className: 'text-left' })
const getRightAligned = () => ({ className: 'text-right' })

export const getColumns = (showTermColumn: boolean, showDueDateColumn: boolean) => {
    const columns: Column<Row>[] = [
        {
            header: { content: t.date.get(), getProps: getLeftAligned },
            render: (row) => {
                const date = Day.fromYmd(row.date)
                return { browser: date.dmy(), excel: date }
            },
            excelWidth: 12,
        },
        {
            header: { content: t.invoices.number.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => {
                const value = row.confirmed ? row.number : t.unconfirmed.get()

                return {
                    browser: value,
                    excel: {
                        value: value || '',
                        style: { alignment: { horizontal: 'right' } },
                    },
                }
            },
            excelWidth: 13,
        },
        {
            header: { content: t.customer.get(), getProps: getLeftAligned },
            render: (row) => getCustomerName(row.customer),
            excelWidth: 30,
        },
        {
            header: { content: t.vat.without.get(), getProps: getRightAligned },
            getProps: () => ({ className: 'amount' }),
            render: (row) => ({
                browser: renderAmountOrDash(row.withoutVat),
                excel: renderExcelMoney(row.withoutVat, false),
            }),
            excelWidth: 14,
        },
    ]

    if (showTermColumn) {
        columns.push({
            header: { content: t.term.get(), getProps: getLeftAligned },
            render: (row) => ({
                browser: row.term + ' ' + (row.term === 1 ? t.day.get() : t.days.get()),
                excel: row.term,
            }),
            excelWidth: 15,
        })
    }

    if (showDueDateColumn) {
        columns.push(
            {
                header: { content: t.vat.with.get(), getProps: getRightAligned },
                getProps: () => ({ className: 'amount' }),
                render: (row) => ({
                    browser: renderAmountOrDash(row.withVat),
                    excel: renderExcelMoney(row.withVat, false),
                }),
                excelWidth: 14,
            },
            {
                header: { content: t.dueDate.get(), getProps: getLeftAligned },
                render: (row) => getDueDateDescription(Boolean(row.paid), row.date, row.term),
                excelWidth: 20,
            },
        )
    }

    columns.push({
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'text-center' }),
        render: browserOnly(({ id }) => <ViewIcon href={'#/invoices/view/' + id} />),
        hideInExcel: true,
    })

    return columns
}

const filterRevenues = (revenues: ApiRevenue[], inputValues: InputValues) => {
    const activeFilters = getActiveFilters(inputValues)

    return revenues.filter((revenue) =>
        activeFilters.every(({ filter, values }) => filter.predicate(revenue, ...values)),
    )
}

export const getRows = (revenues: ApiRevenue[], inputValues: InputValues): Row[] => {
    const filteredRevenues = filterRevenues(revenues, inputValues)

    filteredRevenues.sort((revenue1, revenue2) => {
        let result = revenue2.date.localeCompare(revenue1.date)

        if (result === 0) {
            result = revenue2.log[0].time.localeCompare(revenue1.log[0].time)
        }

        return result
    })

    const rows = []

    for (const revenue of filteredRevenues) {
        const { _id, confirmed, customer, date, term } = revenue
        const { payableWithoutVat: withoutVat, payableWithVat: withVat } = calculateRevenue(revenue)
        const row: Row = { id: _id, confirmed, customer, date, term, withoutVat, withVat }

        if (revenue.confirmed) {
            row.number = revenue.number
            row.paid = revenue.paid
        }

        rows.push(row)
    }

    return rows
}

const clearFilters = (section: FilterSection, inputValues: InputValues) => {
    for (const filter of getFilters()[section]) {
        for (const input of filter.inputs) {
            if (input.hasValue(inputValues)) {
                input.set('')
            }
        }
    }
}

export const anyFiltersToClear = (section: FilterSection, inputValues: InputValues) => {
    return getFilters()[section].some((filter) =>
        filter.inputs.some((input) => Boolean(input.get(inputValues))),
    )
}

export const getClearFiltersButton = (
    section: FilterSection,
    inputValues: InputValues,
): ButtonProps => ({
    text: t.revenues.archive.clearFilter[section].get(),
    onClick: () => clearFilters(section, inputValues),
    className: 'button--secondary',
})
