import React, { ChangeEventHandler, Component, FC } from 'react'
import DayPicker, { CaptionElementProps } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

import { monthNumbers } from '../../common/month-numbers'
import { range } from '../../common/range'
import { Day } from '../../common/time'
import { Input, InputValues } from '../../common/types/inputs'
import { t } from '../i18n'
import { Button } from './button'

type OnSelect = ChangeEventHandler<HTMLSelectElement>

export interface DateInputProps {
    input: Input<string>
    inputValues: InputValues
    disabled?: boolean
    text?: string
    className?: string
    afterChange?: (newValue: string) => void
    minDate?: Day
    maxDate?: Day
    renderAsBlock?: boolean
}

interface State {
    expanded: boolean
}

interface YearMonthProps extends Partial<CaptionElementProps> {
    showMonth: (date: Date) => void
    minDate: Day
    maxDate: Day
}

const YearMonthForm: FC<YearMonthProps> = ({ date: viewDate, showMonth, minDate, maxDate }) => {
    const months = monthNumbers.map((month) => t.month[month].get())
    const years = range(minDate.year(), maxDate.year())

    const viewMonth = viewDate!.getMonth()
    const viewYear = viewDate!.getFullYear()

    const onMonthChange: OnSelect = (evt) =>
        showMonth(new Date(viewYear, Number(evt.currentTarget.value)))

    const onYearChange: OnSelect = (evt) =>
        showMonth(new Date(Number(evt.currentTarget.value), viewMonth))

    return (
        <div className="DayPicker-Caption">
            <select value={viewMonth} onChange={onMonthChange}>
                {months.map((monthName, i) => (
                    <option key={i} value={i}>
                        {monthName}
                    </option>
                ))}
            </select>{' '}
            <select value={viewYear} onChange={onYearChange}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    )
}

// Normally we try to avoid stateful components, but the third-party DayPicker component
// used here will be stateful no matter what. So we also keep the 'expanded' state here
// instead of the Redux store to keep things simpler.
export class DateInput extends Component<DateInputProps, State> {
    override state = { expanded: false }

    picker: DayPicker.default | null = null

    renderPicker(expanded: boolean, date: string, set: (date: string) => void) {
        if (!expanded) {
            return null
        }

        const minDate = this.props.minDate || Day.today().firstOfYear().addYears(-1)
        const maxDate = this.props.maxDate || Day.today().lastOfYear().addYears(9)

        if (minDate.isAfter(maxDate)) {
            throw new Error('Invalid min and max dates for DateInput')
        }

        const selectedDay = date === '' ? Day.today() : Day.fromYmd(date)
        const isInRange = minDate.isSameOrBefore(selectedDay) && maxDate.isSameOrAfter(selectedDay)
        const initialMonth = isInRange ? selectedDay : minDate

        const showMonth = (requestedMonth: Date) => {
            let actualMonth = requestedMonth
            const actualDay = Day.fromDate(actualMonth)

            if (minDate.isAfter(actualDay)) {
                actualMonth = minDate.toDate()
            } else if (maxDate.isBefore(actualDay)) {
                actualMonth = maxDate.toDate()
            }

            this.picker!.showMonth(actualMonth)
        }

        const captionElement: React.ReactElement = (
            <YearMonthForm showMonth={showMonth} minDate={minDate} maxDate={maxDate} />
        )

        return (
            <DayPicker
                ref={(node) => {
                    this.picker = node
                }}
                selectedDays={date === '' ? undefined : selectedDay.toDate()}
                initialMonth={initialMonth.toDate()}
                fromMonth={minDate.toDate()}
                toMonth={maxDate.toDate()}
                onDayClick={(value: Date, { disabled }) => {
                    if (!disabled) {
                        set(Day.fromDate(value).ymd())
                    }
                }}
                locale="et"
                localeUtils={MomentLocaleUtils}
                captionElement={captionElement}
                disabledDays={{ before: minDate.toDate(), after: maxDate.toDate() }}
            />
        )
    }

    override render() {
        const { input, inputValues, className, afterChange, renderAsBlock } = this.props

        let { text } = this.props
        const { disabled } = this.props
        const { expanded } = this.state
        const date = input.get(inputValues)

        const set = (value: string) => {
            input.set(value)

            if (afterChange) {
                afterChange(value)
            }

            this.setState({ expanded: false })
        }

        // TODO close any previously opened date pickers when opening a new one?
        const onClick = disabled ? undefined : () => this.setState({ expanded: !expanded })

        if (!text) {
            // TODO i18n for format?
            text = date === '' ? t.choose.get() : Day.fromYmd(date).longDate()
        }

        return (
            <div className={renderAsBlock ? undefined : 'inline'}>
                <Button
                    text={text}
                    onClick={onClick}
                    className={className || 'date-button'}
                    disabled={disabled}
                />
                {this.renderPicker(expanded && !disabled, date, set)}
            </div>
        )
    }
}
