import React, { Component } from 'react'

import { Button } from './button'

interface Props {
    onSelect: (file: File) => void
    text: string
    accept?: string
    className: string
}

export class FileInput extends Component<Props> {
    fileInput: HTMLInputElement | null = null

    override render() {
        const { className, text, onSelect, accept } = this.props

        return (
            <>
                <Button text={text} onClick={() => this.fileInput!.click()} className={className} />
                <input
                    type="file"
                    accept={accept}
                    ref={(input) => (this.fileInput = input)}
                    onChange={(evt) => {
                        if (evt.target.files && evt.target.files.length > 0) {
                            onSelect(evt.target.files[0])
                        }
                    }}
                    style={{ display: 'none' }}
                />
            </>
        )
    }
}
