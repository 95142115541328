import React, { FC, Fragment } from 'react'

import { ValidationError } from '../../../common/types/errors'
import { Input, InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { valErr } from '../../val-err'
import { InputRow } from './input-row'

interface AddressInputs {
    street: Input<string>
    city: Input<string>
    postcode: Input<string>
}

interface Props {
    editMode: boolean
    addressInputs: AddressInputs
    inputValues: InputValues
    valErrors: ValidationError[] | undefined
}

interface AddressElement {
    key: keyof AddressInputs
    label: string
}

const getAddressElements = (): AddressElement[] => [
    {
        key: 'street',
        label: t.business.address.get(),
    },
    {
        key: 'city',
        label: t.business.address.city.get(),
    },
    {
        key: 'postcode',
        label: t.business.address.postcode.get(),
    },
]

const renderRow = (
    { key, label }: AddressElement,
    addressInputs: AddressInputs,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
    index: number,
) => (
    <Fragment key={index}>
        <InputRow
            editMode={true}
            label={label}
            input={addressInputs[key]}
            inputValues={inputValues}
        />
        {valErr(valErrors, 'company.update.address.' + key)}
    </Fragment>
)

export const SettingsAddress: FC<Props> = (props) => {
    const { editMode, addressInputs, inputValues, valErrors } = props

    if (editMode) {
        const addressElements = getAddressElements()

        return (
            <>
                {addressElements.map((elem, index) =>
                    renderRow(elem, addressInputs, inputValues, valErrors, index),
                )}
            </>
        )
    } else {
        const street = addressInputs.street.get(inputValues)
        const city = addressInputs.city.get(inputValues)
        const postcode = addressInputs.postcode.get(inputValues)

        return (
            <div className="settings__input-row">
                <span className="settings__label">
                    {t.business.address.get()}
                    {': '}
                </span>
                <div>
                    <div>{street}</div>
                    <div>
                        {city} {postcode}
                    </div>
                </div>
            </div>
        )
    }
}
