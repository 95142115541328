import React from 'react'

import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { RootData } from '../../state/root-data'
import {
    loadLogoInputFile,
    SAVE_LOGO_PROCESS,
    saveLogo,
    VALIDATE_LOGO_PROCESS,
} from '../../state/settings-actions'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { Button } from '../button'
import { Logo } from './logo'
import { LogoInput } from './logo-input'
import { LogoNotes } from './logo-notes'

const renderLogo = (inputValues: InputValues, valErrors?: ValidationError[]) => {
    const dataUrl = inputs.settings.logo.dataUrl.get(inputValues)

    return (
        <div className="top-margin">
            <Logo url={dataUrl} />
            {valErr(valErrors, 'settings.logo')}
        </div>
    )
}

const renderSaveButton = (inputValues: InputValues, processes: Processes) => {
    const fileBase64 = inputs.settings.logo.base64.get(inputValues)

    return (
        <Button
            className="button--primary"
            onClick={saveLogo}
            text={t.save.get()}
            processes={processes}
            processName={SAVE_LOGO_PROCESS}
            domId="save"
            disabled={!fileBase64}
        />
    )
}

export const LogoEdit = ({ inputValues, processes, validationErrors }: RootData) => (
    <div>
        <BackLink />
        <h1 className="title">
            {t.settings.logo.unsaved.get()}
            <span className="title__sub-title">
                <LogoNotes />
            </span>
        </h1>
        {renderLogo(inputValues, validationErrors[VALIDATE_LOGO_PROCESS])}
        <div className="top-margin">
            {renderSaveButton(inputValues, processes)} <LogoInput onSelect={loadLogoInputFile} />
        </div>
    </div>
)
