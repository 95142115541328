import React, { FC } from 'react'

import { assertViewName } from '../assert-view-name'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { RootData } from '../state/root-data'
import { SELECT_COMPANY_PROCESS, selectCompany } from '../state/session-actions'
import { valErr } from '../val-err'
import { Button } from './button'
import { Input } from './input'

export const ChangeCompany: FC<RootData> = (rootData) => {
    const { inputValues, processes, validationErrors, view } = rootData
    const { id } = assertViewName(view, 'ChangeCompany')
    const valErrors = validationErrors[SELECT_COMPANY_PROCESS]
    const select = async () => selectCompany(id)

    return (
        <div className="change-company">
            <div>{t.changeCompany.password.get()}:</div>
            <div className="top-margin">
                <Input
                    type="password"
                    input={inputs.changeCompany.password}
                    inputValues={inputValues}
                    focusOnMount={true}
                    placeholder={t.password.get()}
                    className="change-company__password"
                    onEnter={select}
                />
                {valErr(valErrors, 'password', { invalid: t.login.invalidPassword.get() })}
            </div>
            <div className="top-margin">
                <Button
                    text={t.changeCompany.get()}
                    onClick={select}
                    processes={processes}
                    processName={SELECT_COMPANY_PROCESS}
                    className="change-company__button"
                    loadingColor="white"
                    loadingStyle={{ marginTop: '0.8em', width: 25, height: 25 }}
                />
            </div>
        </div>
    )
}
