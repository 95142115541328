import classnames from 'classnames'
import React from 'react'

import { ApiCompany } from '../../common/types/company'
import { ChoiceOption } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { ApiSession } from '../../common/types/session'
import { t } from '../i18n'
import { createCustomInput } from '../input-utils'
import { setRoute } from '../route-utils'
import {
    logout,
    LOGOUT_PROCESS,
    SELECT_COMPANY_PROCESS,
    selectCompany,
} from '../state/session-actions'
import { renderChoice } from './choice'
import { LoadingIcon } from './loading-icon'
import { Logo } from './logo'

interface Props {
    session: ApiSession | null
    companies: ApiCompany[] | null
    processes: Processes
}

const renderCompany = (
    companies: ApiCompany[] | null,
    session: ApiSession,
    processes: Processes,
) => {
    if (!companies || processes.has(SELECT_COMPANY_PROCESS)) {
        return <LoadingIcon color="black" />
    }

    const options: ChoiceOption<string>[] = []

    for (const company of companies) {
        options.push({ id: company._id, label: company.name })
    }

    // Company id-s can't contain exclamation marks,
    // so these are safe id-s for custom options.
    options.push({ id: '!separator', label: '----', disabled: true })
    options.push({ id: '!add', label: t.addNew.get() })

    return renderChoice({
        type: 'dropdown',
        input: createCustomInput({
            inputType: 'string',
            get: () => session.companyId || '',
            set: async (id) => {
                if (id === '!add') {
                    setRoute('#/add-company')
                } else if (id && id !== session.companyId) {
                    if (session.firstCompanySelection) {
                        // No password needed
                        await selectCompany(id)
                    } else {
                        // Оpen page for entering password
                        setRoute('#/change-company/' + id)
                    }
                }
            },
        }),
        inputValues: {},
        options,
        emptyLabel: t.selectCompany.get(),
    })
}

const renderLogoutButton = (processes: Processes) => {
    if (processes.has(LOGOUT_PROCESS)) {
        return <LoadingIcon color="black" />
    } else {
        return <img src="/icons/logout.svg" onClick={logout} title={t.logout.get()} id="logout" />
    }
}

const renderCompanyInfo = (
    session: ApiSession | null,
    companies: ApiCompany[] | null,
    processes: Processes,
) => {
    if (session) {
        return (
            <div id="user-info">
                {renderCompany(companies, session, processes)}
                <a href="#/user-settings/general" className="profile-link">
                    <img
                        src="/icons/profile.svg"
                        title={t.userSettings.get()}
                        className="profile-icon"
                    />
                </a>
                {renderLogoutButton(processes)}
            </div>
        )
    } else {
        return null
    }
}

const renderLogo = (isLoggedIn: boolean) => {
    const logo = <Logo />
    return isLoggedIn ? <a href="/#">{logo}</a> : logo
}

export const Header = ({ session, companies, processes }: Props) => (
    <div className={classnames('header', { 'header--login': !session })}>
        <div id="titles">
            <div id="main-title">{renderLogo(Boolean(session))}</div>
        </div>
        {renderCompanyInfo(session, companies, processes)}
    </div>
)
