import React, { FC } from 'react'

import { getExpenseAccountNumber } from '../../common/accounts'
import { MIN_DATE } from '../../common/clock'
import { MAX_ROWS } from '../../common/constants'
import { expenseAccountTypes, expenseItemTypes, expenseTypes } from '../../common/enums'
import { calculateItem, getItemUnitPrice } from '../../common/item-utils'
import { keys } from '../../common/keys'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { ApiExpense } from '../../common/types/expense'
import { Column } from '../../common/types/table'
import { getAccountName } from '../account-utils'
import { assertViewName } from '../assert-view-name'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { t } from '../i18n'
import { createMonthUrlInput } from '../input-utils'
import { inputs } from '../inputs'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { renderExcelMoney } from '../render-excel-money'
import { getShowAllButtonProps, wrapExcelButton } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { ExcelButtonProps } from './excel-button'
import { LoadingPage } from './loading-page'
import { MonthNav } from './month-nav'
import { NoData } from './no-data'
import { renderSortOptions } from './sort-options'
import { StandardPage } from './standard-page'
import { type BaseRow, renderTable } from './table'
import { ViewIcon } from './view-icon'

interface Row extends BaseRow {
    expenseId: string
    itemId: string
    date: Day
    vendor: string
    account: string
    description: string
    quantity: number
    unit: string
    unitPrice: number
    withoutVat: number
    createTime: string
}

interface Totals {
    withoutVat: number
}

export type SortId = 'date' | 'account' | 'amount' | 'vendor'

const LEVEL3_NUMBER = getExpenseAccountNumber(expenseItemTypes.goodsExpense)

const alignRight = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const getColumns = (): Column<Row, Totals>[] => [
    {
        header: { content: t.date.get() },
        render: (row) => ({ browser: row.date.dmy(), excel: row.date }),
        excelWidth: 15,
    },
    {
        header: { content: t.expenses.vendor.get() },
        render: (row) => row.vendor,
        excelWidth: 30,
    },
    {
        header: { content: t.expenses.account.get() },
        render: (row) => row.account,
        excelWidth: 25,
    },
    {
        header: { content: t.description.get() },
        render: (row) => row.description,
        excelWidth: 30,
    },
    {
        header: { content: t.quantity.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: row.quantity + ' ' + row.unit,
            excel: row.quantity,
        }),
        excelWidth: 10,
    },
    {
        header: { content: t.unit.get() },
        hideInBrowser: true,
        render: (row) => row.unit,
        excelWidth: 10,
    },
    {
        header: { content: t.unitPrice.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.unitPrice) + (row.unit ? '/' + row.unit : ''),
            excel: renderExcelMoney(row.unitPrice, false),
        }),
        excelWidth: 15,
    },
    {
        header: { content: t.total.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.withoutVat),
            excel: renderExcelMoney(row.withoutVat, false),
        }),
        getTotal: (totals) => renderAmount(totals.withoutVat),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.withoutVat }),
        getTotalProps: getAmountClass,
        excelWidth: 15,
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions text-center' }),
        render: browserOnly((row) => <ViewIcon href={'#/expenses/view/' + row.expenseId} />),
        hideInExcel: true,
    },
]

const getRows = (
    shownMonth: string,
    confirmedRegularExpenses: ApiExpense[],
    sortOption: SortOption<Row>,
    accountData: AccountData,
): Row[] => {
    const rows: Row[] = []

    for (const expense of confirmedRegularExpenses) {
        if (!expense.date.startsWith(shownMonth)) {
            continue
        }

        const createTime = expense.log[0].time

        for (const item of expense.items!) {
            const { id: itemId, type, description, quantity } = item

            if (type === expenseItemTypes.goodsExpense) {
                const account = getAccountName(
                    LEVEL3_NUMBER,
                    item.account,
                    accountData,
                    expenseAccountTypes.goods,
                )

                const unit = item.unit ? t.enums.units[item.unit].get() : ''
                const unitPrice = getItemUnitPrice(expense.calculationMode, item, MIN_DATE)
                const { payableWithoutVat } = calculateItem(expense.calculationMode, item, MIN_DATE)

                rows.push({
                    expenseId: expense._id,
                    itemId,
                    date: Day.fromYmd(expense.date),
                    vendor: expense.vendor.name,
                    account,
                    description,
                    quantity,
                    unit,
                    unitPrice,
                    withoutVat: payableWithoutVat,
                    createTime,
                })
            }
        }
    }

    return sort(rows, sortOption)
}

export const GoodsList: FC<RootData> = (rootData) => {
    const {
        accountData,
        companyData,
        expenseData: { expenses },
        inputValues,
        processes,
        progress,
        session,
        view,
    } = rootData

    if (!expenses || !accountData.accounts || !companyData.companies) {
        return <LoadingPage />
    }

    const anyGoods = expenses.some(
        (expense) =>
            expense.type === expenseTypes.regular &&
            expense.items!.some((item) => item.type === expenseItemTypes.goodsExpense),
    )

    if (!anyGoods) {
        return (
            <NoData
                addRoute="#/expenses/add/regular"
                addButtonText={t.expenses.add.goods.expense.get()}
            />
        )
    }

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        date: [
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.createTime, reverse: true },
        ],
        account: [{ getKey: (row) => row.account }],
        amount: [{ getKey: (row) => row.withoutVat, reverse: true }],
        vendor: [{ getKey: (row) => row.vendor }],
    }

    const confirmedRegularExpenses: ApiExpense[] = expenses.filter(
        (expense) => expense.type === expenseTypes.regular && expense.confirmed,
    )

    // TODO undup
    const tableInputs = inputs.expense.goods.expense
    const showAll = tableInputs.showAll.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const { month } = assertViewName(view, 'GoodsList')
    const shownMonth = month || Day.today().ym()
    const monthInput = createMonthUrlInput('#/expenses/goods/', shownMonth)

    const allRows = getRows(shownMonth, confirmedRegularExpenses, sortOptions[sortId], accountData)
    const hasMore = allRows.length > MAX_ROWS
    const rows: Row[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)
    const columns = getColumns()

    const totals: Totals = { withoutVat: allRows.reduce((sum, row) => sum + row.withoutVat, 0) }

    const parsedMonth = Day.fromYm(shownMonth)
    const title = t.expenses.goods.get()
    const subtitle = parsedMonth.longMonth()

    const company = getCompany(companyData, session)
    const interimDate = Day.fromYmd(company.interimDate)

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            outputName: title + ' - ' + subtitle,
        }

        excelButton = getExcelButtonProps(
            excelSpec,
            t.expenses.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return (
        <StandardPage
            title={title}
            subtitle={subtitle}
            sortOptions={
                rows.length
                    ? renderSortOptions({
                          // TODO move condition to StandardPage?
                          input: tableInputs.sort,
                          inputValues,
                          options: keys(sortOptions).map((key) => ({
                              id: key,
                              label: t.expenses.sortOption[key].get(),
                          })),
                      })
                    : null
            }
            buttons={
                <>
                    <MonthNav day={parsedMonth} input={monthInput} minMonth={interimDate} />
                    {excelButton && wrapExcelButton(excelButton)}
                </>
            }
            table={React.createElement(
                'div',
                null,
                renderTable({
                    columns,
                    rows,
                    totals,
                    stickyHeader: true,
                    tableClassName: 'main-table',
                    wrapperClassName: 'main-table-wrapper',
                }),
                // TODO move to StandardPage
                !showAll && hasMore && <Button {...getShowAllButtonProps(tableInputs.showAll)} />,
            )}
        />
    )
}
