import React, { FC } from 'react'

import { canConfirm } from '../../../common/access'
import { assertNever } from '../../../common/assert-never'
import { calculationModes, expenseTypes } from '../../../common/enums'
import { CalculationMode, ExpenseType } from '../../../common/types/enums'
import { ApiExpense, ExpenseData } from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { Totals } from '../../../common/types/item'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { calculateTotalsFromAssetInputs, calculateTotalsFromItemInputs } from '../../item-utils'
import { getTotalsTableProps } from '../../props/totals-table-props'
import { getCompany } from '../../state/company-actions'
import {
    CONFIRM_PROCESS,
    confirmExpense,
    createAsset,
    createRegular,
    SAVE_PROCESS,
    toggleAssetCalculationMode,
    toggleRegularCalculationMode,
    updateAsset,
    updateRegular,
} from '../../state/expense-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { TotalsTable } from '../totals-table'
import { TotalsMismatch } from './totals-mismatch'

interface Props {
    editMode: boolean
    expense: ApiExpense | undefined
    type: ExpenseType
    rootData: RootData
    vatPayer: boolean
}

const calculateTotals = (
    calculationMode: CalculationMode,
    type: ExpenseType,
    inputValues: InputValues,
    { assetIds, itemIds }: ExpenseData,
    vatPayer: boolean,
    expense?: ApiExpense,
): Totals<number> => {
    let totals

    if (type === expenseTypes.regular) {
        totals = calculateTotalsFromItemInputs(
            itemIds,
            inputs.expense.item,
            inputValues,
            calculationMode,
            vatPayer,
        )
    } else if (type === expenseTypes.asset) {
        const getAssetInputs = inputs.expense.asset
        totals = calculateTotalsFromAssetInputs(assetIds, getAssetInputs, inputValues, vatPayer)
    } else {
        throw assertNever(type, 'expense type')
    }

    if (expense?.totals && calculationMode === calculationModes.manual) {
        totals.vatAmount = expense.totals.vat
        totals.payableWithVat = expense.totals.payableWithVat
    }

    return totals
}

const isVatMissing = (
    type: ExpenseType,
    { assetIds, itemIds }: ExpenseData,
    inputValues: InputValues,
) => {
    if (type === expenseTypes.regular) {
        return itemIds.every(
            (itemId) => inputs.expense.item(itemId).vatPercentage.get(inputValues) === 'null',
        )
    } else if (type === expenseTypes.asset) {
        return assetIds.every(
            (assetId) => inputs.expense.asset(assetId).vatPercentage.get(inputValues) === 'null',
        )
    } else {
        throw assertNever(type, 'expense type')
    }
}

const renderEditButton = (editMode: boolean, id: string | null) => {
    if (editMode) {
        return null
    }

    return (
        <a className="button button--primary" href={'#/expenses/edit/' + id}>
            {t.edit.get()}
        </a>
    )
}

const renderSaveButton = (
    editMode: boolean,
    existing: ApiExpense | undefined,
    type: ExpenseType,
    processes: Processes,
) => {
    if (!editMode) {
        return null
    }

    const save = async () => {
        if (!existing) {
            if (type === expenseTypes.regular) {
                return createRegular()
            } else if (type === expenseTypes.asset) {
                return createAsset()
            } else {
                throw new Error('Unexpected expense type: ' + String(type))
            }
        } else {
            if (type === expenseTypes.regular) {
                return updateRegular(existing._id)
            } else if (type === expenseTypes.asset) {
                return updateAsset(existing._id)
            } else {
                throw new Error('Unexpected expense type: ' + String(type))
            }
        }
    }

    return (
        <Button
            onClick={save}
            text={t.saveDraft.get()}
            processes={processes}
            processName={SAVE_PROCESS}
            domId="save"
            className="button--primary"
        />
    )
}

const renderConfirmButton = (
    visible: boolean,
    id: string | null,
    inputValues: InputValues,
    processes: Processes,
) => {
    if (!visible) {
        return null
    }

    const onClick = () => {
        const rev = inputs.expense.rev.get(inputValues)

        void confirmExpense(id!, rev)
    }

    return (
        <Button
            onClick={onClick}
            text={t.confirm.get()}
            processes={processes}
            processName={CONFIRM_PROCESS}
            className="button--primary"
        />
    )
}

const renderToggleCalculationModeButton = (
    editMode: boolean,
    inputValues: InputValues,
    type: ExpenseType,
) => {
    if (!editMode) {
        return null
    }

    const calculationMode = inputs.expense.calculationMode.get(inputValues)

    return (
        <Button
            text={
                t.expenses.calculationMode.get() +
                ': ' +
                t.expenses.calculationMode[calculationMode].get()
            }
            onClick={
                type === expenseTypes.asset
                    ? toggleAssetCalculationMode
                    : toggleRegularCalculationMode
            }
            className="button--secondary top-margin"
        />
    )
}

const renderButtons = (
    type: ExpenseType,
    expense: ApiExpense | undefined,
    editMode: boolean,
    userCanConfirm: boolean,
    inputValues: InputValues,
    processes: Processes,
) => {
    if (expense?.confirmed) {
        return null
    }

    const id = expense ? expense._id : null

    return (
        <div>
            {renderEditButton(editMode, id)} {renderSaveButton(editMode, expense, type, processes)}{' '}
            {renderConfirmButton(!editMode && userCanConfirm, id, inputValues, processes)}
        </div>
    )
}

export const ExpenseBottomRight: FC<Props> = (props) => {
    const { editMode, expense, rootData, type, vatPayer } = props

    const { companyData, expenseData, inputValues, processes, session, validationErrors } = rootData

    const valErrors = validationErrors[SAVE_PROCESS]

    const calculationMode = inputs.expense.calculationMode.get(inputValues)
    const totals = calculateTotals(
        calculationMode,
        type,
        inputValues,
        expenseData,
        vatPayer,
        expense,
    )
    const vatMissing = isVatMissing(type, expenseData, inputValues)

    const company = getCompany(companyData, session)
    const userCanConfirm = canConfirm(session!.companyRole, company.status)

    const totalsTable = getTotalsTableProps({
        editable: editMode,
        inputs: inputs.expense.totals,
        inputValues,
        totals,
        vatMissing,
        vatPayer,
        calculationMode,
        valErrors,
    })

    return (
        <div className="expense__bottom-right">
            <div className="align-right">
                <TotalsTable {...totalsTable} />
                {renderToggleCalculationModeButton(editMode, inputValues, type)}
            </div>
            <div className="text-right">
                <TotalsMismatch
                    vatPayer={vatPayer}
                    mismatch={expenseData.totalsMismatch}
                    calculationMode={calculationMode}
                />
                {renderButtons(type, expense, editMode, userCanConfirm, inputValues, processes)}
            </div>
        </div>
    )
}
