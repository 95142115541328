import React, { FC } from 'react'

import { EmailConfirmationStatus } from '../../common/enums'
import { assertViewName } from '../assert-view-name'
import { fromErrorCode, processWarning } from '../error-manager'
import { t } from '../i18n'
import { RootData } from '../state/root-data'

const renderLoginLink = (isVisible: boolean) => {
    if (isVisible) {
        return (
            <a href="#/" className="button button--white email-confirmation__login-button">
                {t.login.get()}
            </a>
        )
    } else {
        return null
    }
}

const getMessage = (status: EmailConfirmationStatus, email: string) => {
    if (!(status in EmailConfirmationStatus)) {
        processWarning(fromErrorCode('unexpected-email-confirmation-status').dontDisplay())
        return t.emailConfirmation.error.get()
    }

    return t.emailConfirmation[status].get(email)
}

export const EmailConfirmation: FC<RootData> = ({ view }) => {
    const { status, email } = assertViewName(view, 'EmailConfirmation')

    return (
        <div className="email-confirmation">
            <div className="email-confirmation__message">{getMessage(status, email)}</div>
            {renderLoginLink(status === EmailConfirmationStatus.success)}
        </div>
    )
}
