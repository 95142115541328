import classnames from 'classnames'
import React, { FC } from 'react'

import { FINANCIAL, INCOME_TAX, Level1Accounts, OPERATING_PROFIT } from '../../../common/accounts'
import { ApiAccount } from '../../../common/types/account'
import { ReportMode } from '../../../common/types/enums'
import { Period, PeriodIncome } from '../../../common/types/reports'
import { Column } from '../../../common/types/table'
import { getDefaultAccountName } from '../../account-utils'
import { getExcelButtonProps } from '../../excel-utils'
import { HEADER_STYLE } from '../../excel/style'
import { ExcelFont, ExcelNumberFormat, ExcelSpec, ExcelStyle } from '../../excel/types'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { addAccountRows, anyNonZero, getAccountNodes, Row } from '../../report-utils'
import { getCompany } from '../../state/company-actions'
import { LOAD_INCOME_PROCESS, loadIncome } from '../../state/report-actions'
import { RootData } from '../../state/root-data'
import { ExcelButton } from '../excel-button'
import { LoadingIcon } from '../loading-icon'
import { renderTable } from '../table'
import { ReportModeButton } from './mode-button'
import { NoReportData } from './no-data'
import { PeriodsChoice } from './periods-choice'

const modeInput = inputs.reports.income.mode

const rightAlignedExcelHeader: ExcelStyle = { ...HEADER_STYLE, alignment: { horizontal: 'right' } }

const getColumns = (loaded: PeriodIncome[]) => {
    const hasMultiple = loaded.length > 1

    const columns: Column<Row<Period>>[] = [
        {
            getProps: (row) => {
                const className = classnames('income-statement__account-name', {
                    'income-statement__account-name--total': !row.level,
                    'income-statement__account-name--level2': row.level === 2,
                    'income-statement__account-name--level3': row.level === 3,
                    'income-statement__account-name--level4': row.level === 4,
                })

                return { className }
            },
            render: (row) => {
                if (row.isEmpty) {
                    // Use non-breaking space as empty row content to avoid collapsing the height
                    return { browser: '\u00a0', excel: '' }
                }

                const value = row.label || getDefaultAccountName(row.number!)
                const style: ExcelStyle = { font: row.level ? ExcelFont.regular : ExcelFont.bold }

                if (row.level) {
                    style.alignment = { indent: row.level - 1 }
                }

                return { browser: value, excel: { value, style } }
            },
            excelWidth: 40,
        },
        ...loaded.map(
            ({ period, label }): Column<Row<Period>> => ({
                header: {
                    content: label,
                    excelContent: { value: label || '', style: rightAlignedExcelHeader },
                    getProps: () => ({ className: 'text-right' }),
                },
                getProps: (row) => ({
                    className: classnames('amount', { 'text-bold': !row.level }),
                }),
                render: (row) => {
                    let value = null

                    if (row.amounts?.has(period)) {
                        value = row.amounts.get(period)! || 0
                    }

                    if (value === null) {
                        return ''
                    }

                    return {
                        browser: formatAmount(value),
                        excel: {
                            value,
                            style: {
                                numberFormat: ExcelNumberFormat.money,
                                font: row.level ? ExcelFont.regular : ExcelFont.bold,
                            },
                        },
                    }
                },
                excelWidth: Math.max(15, label && hasMultiple ? label.length + 5 : 0),
            }),
        ),
    ]

    if (hasMultiple) {
        columns.push({
            header: {
                content: t.total.get(),
                excelContent: { value: t.total.get(), style: rightAlignedExcelHeader },
                getProps: () => ({ className: 'text-bold text-right' }),
            },
            getProps: () => ({ className: 'amount text-bold' }),
            render: (row) => {
                if (row.isEmpty || typeof row.total !== 'number') {
                    return ''
                }

                return {
                    browser: formatAmount(row.total),
                    excel: {
                        value: row.total,
                        style: { font: ExcelFont.bold, numberFormat: ExcelNumberFormat.money },
                    },
                }
            },
            excelWidth: 15,
        })
    }

    return columns
}

const getRows = (
    loaded: PeriodIncome[],
    mode: ReportMode,
    accounts: ApiAccount[],
): Row<Period>[] => {
    const rows: Row<Period>[] = []

    const getAmounts = (number: string) => {
        const amounts = new Map<Period, number>()

        for (const periodIncome of loaded) {
            amounts.set(periodIncome.period, periodIncome.accounts[number] || 0)
        }

        return amounts
    }

    const addRows = (level1Accounts: Level1Accounts) => {
        const nodes = getAccountNodes(level1Accounts, getAmounts, anyNonZero, accounts)
        addAccountRows(nodes, rows, mode, 1)
    }

    const totalAmounts = {
        operatingProfit: new Map<Period, number>(),
        profitBeforeTax: new Map<Period, number>(),
        netProfit: new Map<Period, number>(),
    }

    for (const { period, totals } of loaded) {
        totalAmounts.operatingProfit.set(period, totals.operatingProfit)
        totalAmounts.profitBeforeTax.set(period, totals.profitBeforeTax)
        totalAmounts.netProfit.set(period, totals.netProfit)
    }

    addRows(OPERATING_PROFIT)
    let operatingProfitTotal = 0
    totalAmounts.operatingProfit.forEach((amount) => (operatingProfitTotal += amount))

    rows.push({ isEmpty: true })

    rows.push({
        label: t.profitLoss.operating.get(),
        amounts: totalAmounts.operatingProfit,
        total: operatingProfitTotal,
    })

    addRows(FINANCIAL)
    let profitBeforeTaxTotal = 0
    totalAmounts.profitBeforeTax.forEach((amount) => (profitBeforeTaxTotal += amount))

    rows.push({ isEmpty: true })

    rows.push({
        label: t.profitLoss.beforeIncomeTax.get(),
        amounts: totalAmounts.profitBeforeTax,
        total: profitBeforeTaxTotal,
    })

    addRows(INCOME_TAX)
    let netProfitTotal = 0
    totalAmounts.netProfit.forEach((amount) => (netProfitTotal += amount))

    rows.push({ isEmpty: true })

    rows.push({
        label: t.profitLoss.net.get(),
        amounts: totalAmounts.netProfit,
        total: netProfitTotal,
        className: 'income-statement__total-row',
    })

    return rows
}

const getSubtitle = (loaded: PeriodIncome[]) =>
    loaded.length === 1 ? t.onPeriod.get(loaded[0].label!) : null

const renderContent = (rootData: RootData) => {
    const {
        accountData: { accounts },
        companyData,
        inputValues,
        processes,
        progress,
        reports: {
            income: { loaded },
            interimDateToday,
            periodsError,
        },
        session,
        validationErrors,
    } = rootData

    const title = t.reports.income.get()
    const subtitle = getSubtitle(loaded)

    const titleElement = (
        <h1 className="title">
            {title}
            {subtitle ? <span className="title__sub-title">{subtitle}</span> : null}
        </h1>
    )

    if (interimDateToday) {
        return (
            <>
                {titleElement}
                {t.reports.interimDateToday.get()}
            </>
        )
    }

    if (
        !loaded.length ||
        !accounts ||
        !companyData.companies ||
        processes.has(LOAD_INCOME_PROCESS)
    ) {
        return <LoadingIcon color="black" />
    }

    const company = getCompany(companyData, session)
    const mode = modeInput.get(inputValues)
    const columns = getColumns(loaded)
    const rows = getRows(loaded, mode, accounts)
    const valErrors = validationErrors[LOAD_INCOME_PROCESS]

    const spec: ExcelSpec<Row<Period>> = {
        columns,
        rows,
        outputName: title + (subtitle ? ' ' + subtitle : ''),
        noHeader: loaded.length < 2,
    }

    const excelButton = getExcelButtonProps(
        spec,
        '',
        processes,
        progress,
        'button--wide button--primary',
    )

    return (
        <>
            <div className="align-right relative">
                <div>
                    <div>
                        <ReportModeButton input={modeInput} inputValues={inputValues} />
                    </div>
                    <div className="top-margin">
                        <ExcelButton {...excelButton} />
                    </div>
                    <PeriodsChoice
                        inputValues={inputValues}
                        periodsError={periodsError}
                        company={company}
                        valErrors={valErrors}
                        load={loadIncome}
                    />
                </div>
            </div>
            {titleElement}
            {renderTable({
                columns,
                rows,
                noHeader: loaded.length < 2,
                noWrapper: true,
                stickyHeader: true,
                tableClassName: 'income-statement',
            })}
        </>
    )
}

export const IncomeReport: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    if (companyData.companies) {
        const company = getCompany(companyData, session)

        if (!company.hasReportData) {
            return <NoReportData />
        }
    }

    return (
        <div className="content-area">
            <div className="content report">{renderContent(rootData)}</div>
        </div>
    )
}
