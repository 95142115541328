import React, { FC } from 'react'

import { getExpenseAccountNumber, getExpenseAccountType } from '../../../common/accounts'
import { expenseTypes } from '../../../common/enums'
import { ExpenseType } from '../../../common/types/enums'
import { ApiExpense, ExpenseItemType, FileStorageUsage } from '../../../common/types/expense'
import { Processes } from '../../../common/types/processes'
import { ServerConf } from '../../../common/types/server-conf'
import { isVatPayerAt } from '../../../common/vat-utils'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getExpensePaymentProps } from '../../props/expense-payment-props'
import { getCompany } from '../../state/company-actions'
import {
    afterAccountChange,
    getById,
    removeItem,
    SAVE_PROCESS,
    setLogVisible,
} from '../../state/expense-actions'
import { RootData } from '../../state/root-data'
import { valErr } from '../../val-err'
import { AssetItemTable } from '../asset-item-table'
import { BackLink } from '../back-link'
import { ItemTable } from '../item-table'
import { LoadingIcon } from '../loading-icon'
import { LoadingPage } from '../loading-page'
import { Log } from '../log'
import { Payment } from '../payment'
import { ExpenseBottomLeft } from './bottom-left'
import { ExpenseBottomRight } from './bottom-right'
import { ExpenseFiles } from './files'
import { ExpenseTopLeft } from './top-left'
import { ExpenseTopRight } from './top-right'

const renderSidebar = (rootData: RootData, isNew: boolean, id: string) => {
    const {
        expenseData: { paymentState },
    } = rootData

    if (!isNew && paymentState && paymentState.type === 'expense' && paymentState.id === id) {
        return <Payment {...getExpensePaymentProps(rootData, id)} />
    } else {
        return null
    }
}

const renderChanged = (expense?: ApiExpense) => {
    if (!expense) {
        return null
    }

    if (
        expense.type === expenseTypes.asset &&
        expense.assets!.some((asset) => asset.valueChanges.length > 0)
    ) {
        return <div className="expense__change-note">{t.expenses.changed.asset.get()}</div>
    } else if (
        expense.type === expenseTypes.regular &&
        expense.items!.some((item) => Boolean(item.stockChanges?.length))
    ) {
        return <div className="expense__change-note">{t.expenses.changed.stock.get()}</div>
    } else {
        return null
    }
}

const renderItems = (
    rootData: RootData,
    type: ExpenseType,
    editMode: boolean,
    vatPayer: boolean,
) => {
    if (type !== expenseTypes.regular) {
        return null
    }

    const {
        expenseData: { itemIds },
        accountData,
        inputValues,
        validationErrors,
    } = rootData
    const valErrors = validationErrors[SAVE_PROCESS]

    const calculationMode = inputs.expense.calculationMode.get(inputValues)

    return (
        <ItemTable
            itemIds={itemIds}
            removeItem={removeItem}
            getItemInputs={inputs.expense.item}
            getItemType={(itemInputs) => itemInputs.type.get(inputValues)}
            renderItemType={(itemType: ExpenseItemType) => t.expenseItemTypes[itemType].get()}
            inputValues={inputValues}
            editMode={editMode}
            valErrors={valErrors}
            errorPrefix="expense.items"
            vatPayer={vatPayer}
            hasDiscount={false}
            calculationMode={calculationMode}
            getAccountInputProps={(id, itemInputs) => ({
                id,
                itemInputs,
                inputValues,
                getAccountType: getExpenseAccountType,
                getAccountNumber: getExpenseAccountNumber,
                accountData,
                editMode,
                afterChange: async (number) => {
                    const itemType = itemInputs.type.get(inputValues)
                    await afterAccountChange(number, id, itemType, itemInputs)
                },
            })}
        />
    )
}

const renderAssetItems = (
    { expenseData: { assetIds }, inputValues, validationErrors }: RootData,
    type: ExpenseType,
    editMode: boolean,
    vatPayer: boolean,
    expense?: ApiExpense,
) => {
    if (type !== expenseTypes.asset) {
        return null
    }

    const calculationMode = inputs.expense.calculationMode.get(inputValues)
    const valErrors = validationErrors[SAVE_PROCESS]

    return (
        <>
            <AssetItemTable
                assetIds={assetIds}
                editMode={editMode}
                inputValues={inputValues}
                valErrors={valErrors}
                vatPayer={vatPayer}
                calculationMode={calculationMode}
                expense={expense}
            />
            {valErr(valErrors, 'expense.assets', { empty: t.validation['no-rows'].get() })}
        </>
    )
}

const renderLog = ({ expenseData, userData }: RootData, expense: ApiExpense | undefined) => {
    if (!expense) {
        return null
    }

    return (
        <Log
            visible={expenseData.logVisible}
            setVisible={setLogVisible}
            entries={expense.log}
            payments={expense.payments.concat(expense.removedPayments || [])}
            incoming={false}
            userData={userData}
            assets={expense.assets}
        />
    )
}

const renderFiles = (
    editMode: boolean,
    expense: ApiExpense | undefined,
    fileStorageUsage: FileStorageUsage | null,
    serverConf: ServerConf | null,
    processes: Processes,
) => {
    if (editMode) {
        // TODO render disabled button with tooltip?
        return null
    }

    if (!expense || !fileStorageUsage || !serverConf) {
        return <LoadingIcon color="black" />
    }

    return (
        <ExpenseFiles
            expense={expense}
            fileStorageUsage={fileStorageUsage}
            processes={processes}
            serverConf={serverConf}
        />
    )
}

export const ExpenseEdit: FC<RootData> = (rootData) => {
    const {
        accountData,
        companyData,
        expenseData,
        formsReady,
        inputValues,
        processes,
        serverConf,
        session,
        view,
    } = rootData

    const { mode, id, type: typeFromView } = assertViewName(view, 'ExpenseEdit')
    const isNew = mode === 'add'

    if (
        !formsReady.has('expense') ||
        !accountData.accounts ||
        (!isNew && !expenseData.expenses) ||
        !companyData.companies
    ) {
        return <LoadingPage />
    }

    const company = getCompany(companyData, session)

    let type: ExpenseType
    let editMode = true
    let expense
    let confirmed = false
    let vatPayer = false

    if (!isNew) {
        editMode = mode === 'edit'
        expense = getById(expenseData, id)!
        type = expense.type
        confirmed = expense.confirmed

        if (confirmed) {
            vatPayer = expense.vatPayer!
        }
    } else {
        type = typeFromView as ExpenseType // TODO
    }

    if (!confirmed) {
        const date = inputs.expense.date.get(inputValues)
        vatPayer = isVatPayerAt(company.vatPeriods, date)
    }

    return (
        <div id="expense" className="content-area">
            {renderSidebar(rootData, isNew, id)}
            <div className="content">
                {type === expenseTypes.asset || !isNew ? <BackLink /> : null}
                {renderChanged(expense)}
                <div className="expense__top">
                    <ExpenseTopLeft
                        isNew={isNew}
                        type={type}
                        editMode={editMode}
                        rootData={rootData}
                        vatPayer={vatPayer}
                    />
                    <ExpenseTopRight editMode={editMode} rootData={rootData} type={type} />
                </div>
                {renderItems(rootData, type, editMode, vatPayer)}
                {renderAssetItems(rootData, type, editMode, vatPayer, expense)}
                <div className="expense__bottom">
                    <ExpenseBottomLeft editMode={editMode} inputValues={inputValues} type={type} />
                    <ExpenseBottomRight
                        editMode={editMode}
                        expense={expense}
                        rootData={rootData}
                        type={type}
                        vatPayer={vatPayer}
                    />
                </div>
                {renderLog(rootData, expense)}
                {renderFiles(
                    editMode,
                    expense,
                    expenseData.fileStorageUsage,
                    serverConf,
                    processes,
                )}
            </div>
        </div>
    )
}
