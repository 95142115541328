import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { ValidationError } from '../../../common/types/errors'
import { Input, InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { SAVE_GENERAL_PROCESS, saveGeneral } from '../../state/settings-actions'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { InputRow } from './input-row'

interface AddressInputs {
    street: Input<string>
    city: Input<string>
    postcode: Input<string>
}

const renderBackLink = (editMode: boolean) => (editMode ? <BackLink /> : null)

const renderRow = (
    editMode: boolean,
    label: string,
    input: Input<string>,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
    errorKey: string,
) => (
    <>
        <InputRow editMode={editMode} label={label} input={input} inputValues={inputValues} />
        {valErr(valErrors, errorKey)}
    </>
)

const renderAddress = (
    editMode: boolean,
    addressInputs: AddressInputs,
    inputValues: InputValues,
    valErrors?: ValidationError[],
) => {
    if (editMode) {
        return (
            <>
                {renderRow(
                    editMode,
                    t.business.address.get(),
                    addressInputs.street,
                    inputValues,
                    valErrors,
                    'company.address.street',
                )}
                {renderRow(
                    editMode,
                    t.business.address.city.get(),
                    addressInputs.city,
                    inputValues,
                    valErrors,
                    'company.address.city',
                )}
                {renderRow(
                    editMode,
                    t.business.address.postcode.get(),
                    addressInputs.postcode,
                    inputValues,
                    valErrors,
                    'company.address.postcode',
                )}
            </>
        )
    } else {
        const street = addressInputs.street.get(inputValues)
        const city = addressInputs.city.get(inputValues)
        const postcode = addressInputs.postcode.get(inputValues)

        return (
            <div className="settings__input-row">
                <span className="settings__label">
                    {t.business.address.get()}
                    {': '}
                </span>
                <div>
                    <div>{street}</div>
                    <div>
                        {city} {postcode}
                    </div>
                </div>
            </div>
        )
    }
}

const renderBookyInfo = ({ name, interimDate, firstPayment }: ApiCompany) => {
    const formattedFirstPayment = firstPayment ? Day.fromYmd(firstPayment).longDate() : '-'
    const formattedInterimDate = Day.fromYmd(interimDate).longDate()

    return (
        <>
            <h1 className="title">{t.settings.bookyInfo.get()}</h1>
            <p>{t.settings.bookyInfo.firstPayment.get(name, formattedFirstPayment)}</p>
            <p>{t.settings.bookyInfo.interimDate.get(name, formattedInterimDate)}</p>
        </>
    )
}

const renderButton = (editMode: boolean, processes: Processes) => {
    // TODO: align button to bottom
    if (editMode) {
        return (
            <Button
                className="button--primary"
                text={t.save.get()}
                onClick={saveGeneral}
                processes={processes}
                processName={SAVE_GENERAL_PROCESS}
            />
        )
    } else {
        return (
            <a href="#/settings/general/edit" className="button button--primary">
                {t.edit.get()}
            </a>
        )
    }
}

export const GeneralSettings: FC<RootData> = (rootData) => {
    const { companyData, inputValues, processes, validationErrors, session, view } = rootData

    if (!companyData.companies) {
        return <LoadingIcon color="black" />
    }

    const { pageParams } = assertViewName(view, 'Settings')
    const editMode = pageParams[0] === 'edit'
    const { name, regCode, address, email, website } = inputs.settings.general
    const valErrors = validationErrors[SAVE_GENERAL_PROCESS]
    const company = getCompany(companyData, session)

    return (
        <>
            <div>
                {renderBackLink(editMode)}
                <h1 className="title">{t.settings.requisites.get()}</h1>
                {renderRow(
                    editMode,
                    t.business.name.get(),
                    name,
                    inputValues,
                    valErrors,
                    'company.name',
                )}
                {renderRow(
                    editMode,
                    t.business.regCode.get(),
                    regCode,
                    inputValues,
                    valErrors,
                    'company.regCode',
                )}
                {renderAddress(editMode, address, inputValues, valErrors)}
                <h1 className="title">{t.settings.contact.get()}</h1>
                {renderRow(
                    editMode,
                    t.settings.email.get(),
                    email,
                    inputValues,
                    valErrors,
                    'company.email',
                )}
                {renderRow(
                    editMode,
                    t.settings.website.get(),
                    website,
                    inputValues,
                    valErrors,
                    'company.website',
                )}
                {renderBookyInfo(company)}
            </div>
            <div>{renderButton(editMode, processes)}</div>
        </>
    )
}
