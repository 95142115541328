import format from 'string-format'

/**
 * Used also as the locale parameter of Moment.js and Numeral.js.
 * If some locales we add are not compatible in these libraries, a more
 * complex solution is needed.
 */
export type Language = 'et' | 'en'

type FormatByLanguage = { [L in Language]?: string }

export interface Translation {
    getFormatForLanguage(language: Language): string
    getForLanguage(language: Language, ...args: Array<string | number>): string
}

const tr = (defaultFormat: string, formatByLanguage: FormatByLanguage = {}): Translation => {
    const getFormatForLanguage = (language: Language) => {
        if (language in formatByLanguage) {
            return formatByLanguage[language]!
        } else {
            if (language !== 'et') {
                // TODO: notification for developers
                console.log('Translation not found for', defaultFormat)
            }

            return defaultFormat
        }
    }

    return {
        getFormatForLanguage,
        getForLanguage: (language: Language, ...args: Array<string | number>) => {
            const formatString = getFormatForLanguage(language)
            return format(formatString, ...args)
        },
    }
}

export const t = {
    acceptInvite: tr('Võta vastu'),
    account: {
        ...tr('Konto'),
        '1': tr('Raha'),
        '1.1': tr('Raha'),
        '1.1.1': tr('Kassa'),
        '1.1.2': tr('Pangakonto'),
        '2': tr('Finantsinvesteeringud'),
        '2.1': tr('Investeeringud väärtpaberitesse'),
        '2.1.1': tr('Investeeringud väärtpaberitesse'),
        '3': tr('Nõuded ja tehtud ettemaksed'),
        '3.1': tr('Nõuded ostjate vastu'),
        '3.1.1': tr('Nõuded ostjate vastu'),
        '3.2': tr('Maksude ettemaksed ja tagasinõuded'),
        '3.2.1': tr('Maksude ettemaksed ja tagasinõuded'),
        '3.2.2': tr('Ettemakstud käibemaks'),
        '3.2.4': tr('MTA ettemaksukonto'),
        '3.3': tr('Muud lühiajalised nõuded'),
        '3.3.1': tr('Muud lühiajalised nõuded'),
        '3.3.2': tr('Arveldused aktsionäride või osanikega'),
        '3.4': tr('Ettemaksed teenuste eest'),
        '3.4.1': tr('Ettemaksed teenuste eest'),
        '3.5': tr('Antud laenud'),
        '3.5.1': tr('Antud laenud'),
        '4': tr('Varud'),
        '4.1': tr('Varud'),
        '4.1.1': tr('Varud'),
        '4.2': tr('Tooraine ja materjal'),
        '4.2.1': tr('Tooraine ja materjal'),
        '4.3': tr('Lõpetamata toodang'),
        '4.3.1': tr('Lõpetamata toodang'),
        '4.4': tr('Valmistoodang'),
        '4.4.1': tr('Valmistoodang'),
        '4.5': tr('Müügiks ostetud kaubad'),
        '4.5.1': tr('Müügiks ostetud kaubad'),
        '4.6': tr('Ettemaksed varude eest'),
        '4.6.1': tr('Ettemaksed varude eest'),
        '20': tr('Investeeringud tütar- ja sidusettevõtetesse'),
        '20.1': tr('Tütarettevõtete aktsiad või osad'),
        '20.1.1': tr('Tütarettevõtete aktsiad või osad'),
        '20.2': tr('Sidusettevõtete aktsiad või osad'),
        '20.2.1': tr('Sidusettevõtete aktsiad või osad'),
        '21': tr('Finantsinvesteeringud'),
        '21.1': tr('Muud aktsiad ja väärtpaberid'),
        '21.1.1': tr('Muud aktsiad ja väärtpaberid'),
        '22': tr('Nõuded ja tehtud ettemaksed'),
        '22.1': tr('Pikaajalised laenu- ja muud nõuded'),
        '22.1.1': tr('Pikaajalised laenu- ja muud nõuded'),
        '23': tr('Kinnisvarainvesteeringud'),
        '23.1': tr('Kinnisvarainvesteeringud'),
        '23.1.1': tr('Kinnisvarainvesteeringud'),
        '24': tr('Materiaalsed põhivarad'),
        '24.1': tr('Maa'),
        '24.1.1': tr('Maa'),
        '24.2': tr('Ehitised'),
        '24.2.1': tr('Ehitised'),
        '24.3': tr('Masinad ja seadmed'),
        '24.3.1': tr('Masinad ja seadmed'),
        '24.4': tr('Muud materiaalsed põhivarad'),
        '24.4.1': tr('Muud materiaalsed põhivarad'),
        '24.5': tr('Lõpetamata ehitised ja ettemaksed'),
        '24.5.1': tr('Lõpetamata ehitised ja ettemaksed'),
        '24.6': tr('Akumuleeritud materiaalse põhivara kulum'),
        '24.6.1': tr('Akumuleeritud materiaalse põhivara kulum'),
        '25': tr('Immateriaalsed põhivarad'),
        '25.1': tr('Firmaväärtus'),
        '25.1.1': tr('Firmaväärtus'),
        '25.2': tr('Muud immateriaalsed põhivarad'),
        '25.2.1': tr('Tarkvara'),
        '25.2.2': tr('Litsentsid'),
        '25.2.3': tr('Muud immateriaalsed põhivarad'),
        '25.3': tr('Ettemaksed immateriaalsete põhivarade eest'),
        '25.3.1': tr('Ettemaksed immateriaalsete põhivarade eest'),
        '25.4': tr('Akumuleeritud immateriaalse põhivara kulum'),
        '25.4.1': tr('Akumuleeritud immateriaalse põhivara kulum'),
        '40': tr('Laenukohustised'),
        '40.1': tr('Lühiajalised laenud'),
        '40.1.1': tr('Lühiajalised laenud'),
        '40.2': tr('Kapitalirendikohustised'),
        '40.2.1': tr('Kapitalirendikohustised'),
        '40.3': tr('Arvelduskrediit'),
        '40.3.1': tr('Arvelduskrediit'),
        '40.4': tr('Pikaajaliste laenukohustiste tagasimaksed järgmisel perioodil'),
        '40.4.1': tr('Pikaajaliste laenukohustiste tagasimaksed järgmisel perioodil'),
        '41': tr('Võlad ja saadud ettemaksed'),
        '41.1': tr('Võlad tarnijatele'),
        '41.1.1': tr('Võlad tarnijatele'),
        '41.1.2': tr('Võlad tarnijatele põhivara eest'),
        '41.2': tr('Võlad töövõtjatele'),
        '41.2.1': tr('Võlad töövõtjatele'),
        '41.3': tr('Maksuvõlad'),
        '41.3.1': tr('Maksuvõlad'),
        '41.3.2': tr('Palgamaksud'),
        '41.3.3': tr('Sotsiaalmaks'),
        '41.3.4': tr('Üksikisiku tulumaks'),
        '41.3.5': tr('Tööandja töötuskindlustusmakse'),
        '41.3.6': tr('Töötaja töötuskindlustusmakse'),
        '41.3.7': tr('Pensionikindlustusmakse'),
        '41.3.8': tr('Tasumisele kuuluv käibemaks'),
        '41.3.9': tr('Ettevõtte tulumaks'),
        '41.3.10': tr('Erisoodustuse sotsiaalmaks'),
        '41.3.11': tr('Erisoodustuse tulumaks'),
        '41.4': tr('Muud võlad'),
        '41.4.1': tr('Muud võlad'),
        '41.4.2': tr('Intressivõlad'),
        '41.4.3': tr('Dividendivõlad'),
        '41.5': tr('Saadud ettemaksed'),
        '41.5.1': tr('Saadud ettemaksed'),
        '41.6': tr('Müügiarvete käibemaks'),
        '41.6.1': tr('Müügiarvete käibemaks 20%'),
        '41.6.2': tr('Müügiarvete käibemaks 9%'),
        '41.6.3': tr('Müügiarvete käibemaks 22%'),
        '41.7': tr('Võlad ostjate ees'),
        '41.7.1': tr('Võlad ostjate ees'),
        '42': tr('Eraldised'),
        '42.1': tr('Eraldised'),
        '42.1.1': tr('Eraldised'),
        '43': tr('Sihtfinantseerimine'),
        '43.1': tr('Sihtfinantseerimine'),
        '43.1.1': tr('Sihtfinantseerimine'),
        '60': tr('Laenukohustised'),
        '60.1': tr('Pikaajalised laenud'),
        '60.1.1': tr('Pikaajalised laenud'),
        '60.2': tr('Kapitalirendikohustised'),
        '60.2.1': tr('Kapitalirendikohustised'),
        '61': tr('Võlad ja saadud ettemaksed'),
        '61.1': tr('Pikaajalised võlad'),
        '61.1.1': tr('Pikaajalised võlad'),
        '61.2': tr('Saadud ettemaksed'),
        '61.2.1': tr('Saadud ettemaksed'),
        '62': tr('Eraldised'),
        '62.1': tr('Eraldised'),
        '62.1.1': tr('Eraldised'),
        '63': tr('Sihtfinantseerimine'),
        '63.1': tr('Sihtfinantseerimine'),
        '63.1.1': tr('Sihtfinantseerimine'),
        '80': tr('Omakapital'),
        '80.1': tr('Aktsia- või osakapitali nimiväärtus'),
        '80.1.1': tr('Aktsia- või osakapitali nimiväärtus'),
        '80.2': tr('Registreerimata aktsia- või osakapital'),
        '80.2.1': tr('Registreerimata aktsia- või osakapital'),
        '80.3': tr('Sissemaksmata aktsia- või osakapital'),
        '80.3.1': tr('Sissemaksmata aktsia- või osakapital'),
        '80.4': tr('Ülekurss'),
        '80.4.1': tr('Ülekurss'),
        '80.5': tr('Oma aktsiad või osad'),
        '80.5.1': tr('Oma aktsiad või osad'),
        '80.6': tr('Kohustuslik reservkapital'),
        '80.6.1': tr('Kohustuslik reservkapital'),
        '80.7': tr('Muud reservid'),
        '80.7.1': tr('Muud reservid'),
        '80.8': tr('Eelmiste perioodide jaotamata kasum (kahjum)'),
        '80.8.1': tr('Eelmiste perioodide jaotamata kasum (kahjum)'),
        '80.99': tr('Aruandeaasta kasum (kahjum)'),
        '80.99.1': tr('Aruandeaasta kasum (kahjum)'),
        '100': tr('Müügitulu'),
        '100.1': tr('Müügitulu'),
        '100.1.1': tr('Müügitulu'),
        '100.1.2': tr('Kauba müük'),
        '100.1.3': tr('Teenuste müük'),
        '101': tr('Muud äritulud'),
        '101.1': tr('Muud äritulud'),
        '101.1.1': tr('Muud äritulud'),
        '101.1.2': tr('Kasum põhivara müügist'),
        '101.1.3': tr('Kasum kinnisvarainvesteeringute väärtuse muutusest'),
        '101.1.4': tr('Saadud trahvid ja viivised'),
        '101.1.5': tr('Kasum valuutakursi muutustest'),
        '102': tr('Kaubad, toore, materjal ja teenused'),
        '102.1': tr('Kaubakulu'),
        '102.1.1': tr('Kaubakulu'),
        '103': tr('Mitmesugused tegevuskulud'),
        '103.1': tr('Tegevuskulud'),
        '103.1.1': tr('Tegevuskulud'),
        '103.1.2': tr('Pangakulud'),
        '103.1.3': tr('Isikliku sõiduauto kompensatsioon'),
        '104': tr('Tööjõukulud'),
        '104.1': tr('Palgad'),
        '104.1.1': tr('Palgad'),
        '104.2': tr('Preemiad'),
        '104.2.1': tr('Preemiad'),
        '104.3': tr('Puhkusetasud'),
        '104.3.1': tr('Puhkusetasud'),
        '104.4': tr('Tööjõukuludelt arvestatud maksud'),
        '104.4.1': tr('Tööjõukuludelt arvestatud maksud'),
        '105': tr('Põhivarade kulum ja väärtuse langus'),
        '105.1': tr('Materiaalsete põhivarade kulum'),
        '105.1.1': tr('Materiaalsete põhivarade kulum'),
        '105.2': tr('Immateriaalsete põhivarade kulum'),
        '105.2.1': tr('Immateriaalsete põhivarade kulum'),
        '105.3': tr('Põhivarade allahindlus ja mahakandmine'),
        '105.3.1': tr('Põhivarade allahindlus ja mahakandmine'),
        '106': tr('Olulised käibevarade allahindlused'),
        '106.1': tr('Olulised käibevarade allahindlused'),
        '106.1.1': tr('Olulised käibevarade allahindlused'),
        '106.1.2': tr('Ebatõenäoliste nõuete allahindlus'),
        '106.1.3': tr('Varude allahindlus'),
        '107': tr('Muud ärikulud'),
        '107.1': tr('Muud ärikulud'),
        '107.1.1': tr('Muud ärikulud'),
        '107.1.2': tr('Kahjum materiaalsete ja immateriaalsete põhivarade müügist'),
        '107.1.3': tr('Trahvid ja viivised'),
        '107.1.4': tr('Kahjum valuutakursimuutustest'),
        '107.1.5': tr('MTA intressikulud'),
        '108': tr('Kasum (kahjum) tütar- ja sidusettevõtjatelt'),
        '108.1': tr('Kasum (kahjum) tütarettevõtjatelt'),
        '108.1.1': tr('Kasum (kahjum) tütarettevõtjatelt'),
        '108.2': tr('Kasum (kahjum) sidusettevõtjatelt'),
        '108.2.1': tr('Kasum (kahjum) sidusettevõtjatelt'),
        '109': tr('Kasum (kahjum) finantsinvesteeringutelt'),
        '109.1': tr('Kasum (kahjum) finantsinvesteeringutelt'),
        '109.1.1': tr('Kasum (kahjum) finantsinvesteeringutelt'),
        '110': tr('Intressitulud'),
        '110.1': tr('Intressitulud'),
        '110.1.1': tr('Intressitulud'),
        '111': tr('Intressikulud'),
        '111.1': tr('Intressikulud'),
        '111.1.1': tr('Intressikulud'),
        '112': tr('Muud finantstulud ja -kulud'),
        '112.1': tr('Muud finantstulud'),
        '112.1.1': tr('Muud finantstulud'),
        '112.1.2': tr('Kasum (kahjum) valuutakursside muutustest'),
        '112.2': tr('Muud finantskulud'),
        '112.2.1': tr('Muud finantskulud'),
        '113': tr('Tulumaks'),
        '113.1': tr('Tulumaks'),
        '113.1.1': tr('Tulumaks'),
        addInfo: tr('Uue konto lisamiseks trükkige selle nimi'),
        number: tr('nr'),
    },
    accounts: {
        balance: tr('Bilansikontod'),
        credit: tr('Bilanss (passiva)'),
        debit: tr('Bilanss (aktiva)'),
        incomeExpense: tr('Tulude ja kulude kontod'),
        incomeStatement: {
            ...tr('Kasumiaruanne'),
            credit: tr('Kasumiaruanne (tulud)'),
            debit: tr('Kasumiaruanne (kulud)'),
        },
    },
    actions: tr('Toimingud'),
    addCompany: tr('Lisa ettevõte'),
    addDate: tr('Lisa kuupäev'),
    addFile: tr('Lisa fail'),
    addNew: tr('Lisa uus'),
    amount: tr('Summa'),
    archive: {
        filterSummary: {
            ...tr('Aktiivsed filtrid'),
            none: tr('Pole'),
        },
        searchResults: tr('Otsingu tulemused'),
        term: {
            over: tr('Üle {} päeva'),
            upTo: tr('Kuni {} päeva'),
        },
    },
    asOf: {
        ...tr('seisuga {}'),
        suffix: tr('{} seisuga'),
    },
    asset: tr('Põhivara'),
    assets: {
        ...tr('Aktiva (varad)'),
        add: {
            ...tr('Lisa uus põhivara'),
            land: tr('Lisa maa'),
            building: tr('Lisa ehitis'),
            device: tr('Lisa seade'),
            otherMaterial: tr('Lisa muu materiaalne põhivara'),
            software: tr('Lisa tarkvara'),
            license: tr('Lisa litsents'),
            otherImmaterial: tr('Lisa muu immateriaalne põhivara'),
        },
        amortBegin: {
            ...tr('Amordi arvutamise algus'),
            underMin: tr('Ei tohi olla varasem kui arve kuupäev'),
        },
        amortRate: tr('Amordimäär'),
        current: tr('Käibevara'),
        date: tr('Soetuse kuupäev'),
        eol: tr('Eluea lõpp'),
        other: tr('Muud kulud ostuarvelt'),
        price: tr('Soetusmaksumus'),
        processed: tr('Varasid töödeldud'),
        residual: tr('Jääkväärtus'),
        valueChange: {
            ...tr('Põhivara väärtuse muutmine'),
            alreadyPending: tr(
                'Valitud põhivaral on juba kinnitamata muudatus ootel.\n' +
                    'Palun kinnitage/kustutage see või valige mõni teine põhivara.',
            ),
            beforeAmortBegin: tr(
                'Valitud põhivara amordi arvestus algab valitud jõustumise kuupäevast hiljem.\n' +
                    'Palun sulgege küljeriba ning valige uus kuupäev.',
            ),
            changed: tr('Muudetud põhivara väärtus'),
            choose: tr('Valige tabelist põhivara'),
            date: tr('Väärtuse muutmise jõustumise kuupäev'),
            eolUnderMin: tr('Ei tohi olla varasem jõustumise kuupäevast'),
            modes: {
                residual: tr('Muuda jääkväärtust'),
                eol: tr('Muuda eluiga'),
            },
            residual: {
                overMax: tr('Jääkväärtus ei tohi muutuda suuremaks'),
            },
            unconfirmed: tr('Kinnitamata väärtuse muudatused'),
        },
    },
    attention: tr('Tähelepanu'),
    automaticInvoice: {
        comment: tr('Arve tasutud krediitkaardimaksega {}'),
    },
    back: tr('Tagasi'),
    backToSearch: tr('Tagasi otsingusse'),
    balance: {
        credit: tr('Passiva'),
        debit: tr('Aktiva'),
        groupTotal: tr('{} kokku'),
        mismatch: tr('Aktiva ja passiva peavad olema võrdsed'),
        validation: {
            negativeAmount: tr('Ei tohi olla negatiivne'),
            negativeBalance: {
                ...tr(
                    'Sellise vahebilansi puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                ),
                bank: tr(
                    'Sellise vahebilansi puhul läheks {} seisuga pangakonto negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                ),
                cash: tr(
                    'Sellise vahebilansi puhul läheks {} seisuga kassa negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                ),
            },
        },
    },
    business: {
        ...tr('Ettevõte'),
        address: {
            ...tr('Aadress'),
            city: tr('Linn'),
            postcode: tr('Postiindeks'),
        },
        name: tr('Ettevõtte nimi'),
        noneFound: tr('Ühtegi ettevõtet ei leitud'),
        regCode: tr('Registrinumber', { en: 'Registry code' }),
        vatId: tr('KMKR nr', { en: 'VAT number' }),
    },
    businessLookup: {
        invoiceCount: tr('Arveid'),
        nonVatPayer: tr('Ettevõte ei ole äriregistri andmetel käibemaksukohustuslane'),
        search: tr('Ettevõtte otsing'),
        title: tr('Ettevõtted äriregistrist'),
    },
    calculateNew: tr('Arvuta uus'),
    cancel: tr('Tühista'),
    cancelSelection: tr('Tühista valik'),
    changeCompany: {
        ...tr('Vaheta ettevõtet'),
        password: tr('Palun sisestage uuesti oma parool, et vahetada ettevõtet'),
    },
    charactersLeft: tr('Tähemärke jäänud'),
    choose: tr('Vali'),
    chooseDate: tr('Vali kuupäev'),
    chosenBusiness: tr('Valitud ettevõte'),
    citizen: {
        ...tr('Eraisik', { en: 'Client' }),
        details: {
            placeholder: tr('Eraisik (kuni 100 tähemärki)'),
        },
    },
    companyIsNotVatPayer: tr('Ettevõte ei ole käibemaksukohustuslane'),
    companyIsVatPayer: {
        ...tr('Ettevõte on käibemaksukohuslane'),
        since: tr('Ettevõte on käibemaksukohustuslane alates {}'),
    },
    confirm: {
        ...tr('Kinnita'),
        confirmChange: tr('Kas olete kindel, et soovite seda muudatust kinnitada?'),
        confirmExpense: tr('Kas olete kindel, et soovite seda kulu kinnitada?'),
        confirmRevenue: tr('Kas olete kindel, et soovite seda arvet kinnitada?'),
        removeChange: tr('Kas olete kindel, et soovite seda muudatust kustutada?'),
        removeConfirmedInvoice: tr(
            'Kas olete kindel, et soovite seda kinnitatud arvet kustutada?\n' +
                'Kinnitatud arve kustutamisel muutuvad ka ettevõtte raamatupidamise aruanded.',
        ),
        removeConfirmedLabourCost: tr(
            'Kas olete kindel, et soovite seda kinnitatud tööjõukulu kustutada?\n' +
                'Kinnitatud kulu kustutamisel muutuvad ka ettevõtte raamatupidamise aruanded.',
        ),
        removeEntry: tr('Kas olete kindel, et soovite seda kannet kustutada?'),
        removeExpense: tr('Kas olete kindel, et soovite seda kulu kustutada?'),
        removeExpenseFile: tr(
            'Kas olete kindel, et soovite seda faili kustutada?\n' +
                'Seda pole võimalik hiljem taastada.',
        ),
        removeLabourCost: tr('Kas olete kindel, et soovite seda tööjõukulu kustutada?'),
        removeLogo: tr('Kas olete kindel, et soovite logo kustutada?'),
        removePayment: {
            incoming: tr(
                'Kas olete kindel, et soovite seda laekumist kustutada?\n' +
                    'Laekumise kustutamisel muutuvad ka ettevõtte raamatupidamise aruanded.',
            ),
            outgoing: tr(
                'Kas olete kindel, et soovite seda tasumist kustutada?\n' +
                    'Tasumise kustutamisel muutuvad ka ettevõtte raamatupidamise aruanded.',
            ),
        },
        removeRevenue: tr('Kas olete kindel, et soovite seda arvet kustutada?'),
    },
    confirmed: tr('Kinnitatud'),
    continue: tr('Jätka'),
    copy: tr('Kopeeri'),
    copyItems: {
        incoming: tr('Kopeeri arve sisu viimaselt samalt ettevõttelt saadud arvelt'),
        outgoing: tr('Kopeeri arve sisu viimaselt samale ettevõttele saadetud arvelt'),
    },
    customer: tr('Klient'),
    customerType: tr('Kliendi tüüp'),
    credit: tr('Kreedit'),
    creditCard: {
        ...tr('Krediitkaart'),
        expires: tr('Aegub'),
        holderName: tr('Nimi kaardil'),
        number: tr('Kaardi number'),
    },
    creditRevenues: {
        paid: tr('Kreeditarve tasutud'),
        percentUnpaid: tr('{}% tasumata'),
    },
    dashboard: {
        notes: tr('Minu märkmed'),
        revenue: tr('Müügitulu'),
        vatWarning: tr(
            'Käibe ületamisel 40\u00a0000€ kalendriaasta algusest tekib kohustus registreerida maksukohustuslaseks',
        ),
    },
    date: {
        ...tr('Kuupäev'),
        from: tr('Alates'),
        to: tr('Kuni'),
    },
    dateShortcut: {
        currentMonth: tr('Jooksev kuu'),
        previousMonth: tr('Eelmine kuu'),
        currentYear: tr('Jooksev aasta'),
        previousYear: tr('Eelmine aasta'),
    },
    day: tr('päev'),
    dayOfMonth: tr('Päeva number'),
    days: tr('päeva'),
    debit: tr('Deebet'),
    depreciation: tr('Kulum'),
    description: tr('Kirjeldus', { en: 'Description' }),
    discount: {
        ...tr('Soodustus', { en: 'Discount' }),
        short: tr('AH'),
        with: tr('Allahindlusega'),
        without: tr('Allahindluseta'),
    },
    download: {
        pdf: tr('Lae PDF alla'),
    },
    dueDate: {
        ...tr('Tähtaeg', { en: 'Due date' }),
        daysPast: tr('Päevi üle tähtaja'),
        daysUntil: tr('Päevi tähtajani'),
        future: tr('Tähtaeg saabumata'),
    },
    dueDateDiff: {
        past: tr('{} päeva üle tähtaja'),
        future: tr('{} päeva jäänud'),
        today: tr('Tähtaeg täna'),
    },
    edit: tr('Muuda'),
    email: tr('E-post'),
    emailConfirmation: {
        duplicate: tr('Keegi teine on vahepeal sama e-posti aadressiga konto loonud.'),
        error: tr('Vabandame, ilmnes tehniline viga.'),
        failed: tr(
            'Avasite vigase, aegunud või juba kasutatud kinnitamise lingi.\nPalun proovige uuesti registreeruda.',
        ),
        pending: tr(
            'Enne, kui saate oma kontot kasutada, peate oma e-posti aadressi kinnitama.\n' +
                'Saatsime teile selleks kirja aadressile {}, palun vaadake oma postkasti.',
        ),
        success: tr('E-posti aadressi kinnitamine õnnestus!'),
    },
    emailSubject: {
        confirm: tr('Booky - E-posti aadressi kinnitamine'),
        invite: tr('Booky - Ettevõtte kontoga liitumise kutse'),
        invoice: tr('Booky - Arve'),
        passwordReset: tr('Booky - Unustatud parooli muutmine'),
    },
    entries: {
        ...tr('Deebet-kreedit'),
        adding: tr('Kande lisamine'),
        addItem: tr('Lisa uus rida'),
        cashFlowMismatch: tr('Sellise kande lisamisel ei klapiks enam rahavoogude aruande summad'),
        date: tr('Tehingu kuupäev'),
        description: tr('Tehingu kirjeldus'),
        incomeStatementMismatch: {
            credit: tr('Kontol {} ei tohi olla deebetis nullist erinev summa'),
            debit: tr('Kontol {} ei tohi olla kreeditis nullist erinev summa'),
        },
        negativeBalance: tr(
            'Sellise kande lisamisel tekiks {} seisuga bilanssi negatiivne rida kontole {}',
        ),
        overMaxAmount: tr('Summa ei tohi ületada {} €'),
        presets: {
            bank: tr('Pangakulud'),
            bankIncome: tr('Pangatulud'),
            loan: tr('Omaniku laen ettevõttele'),
            repayment: tr('Omaniku laenu tagasimakse'),
            title: tr('Eeltäidetud kanded'),
            vehicle: tr('Autokompensatsioon alla piirmäära'),
        },
        remove: {
            // TODO cashFlowMismatch possible?
            negativeBalance: tr(
                'Selle kande kustutamisel tekiks {} seisuga bilanssi negatiivne rida kontole {}',
            ),
        },
        title: tr('Raamatupidamiskanded'),
    },
    enums: {
        assetTypes: {
            building: tr('Ehitis'),
            device: tr('Seade'),
            land: tr('Maa'),
            license: tr('Litsents'),
            otherImmaterial: tr('Muu immateriaalne põhivara'),
            otherMaterial: tr('Muu materiaalne põhivara'),
            software: tr('Tarkvara'),
        },
        incomeTypes: {
            goods: tr('Kaup'),
            service: tr('Teenus'),
        },
        units: {
            h: tr('h'),
            kg: tr('kg'),
            l: tr('l'),
            m: tr('m'),
            pieces: tr('tk', { en: 'pc' }),
        },
    },
    error: {
        ...tr('Veateade'),
        heading: tr('Vabandame, on ilmnenud süsteemiviga.'),
        recommendations: {
            ...tr(
                'Tihti piisab probleemi lahendamiseks lehe taasavamisest.\n' +
                    'Kui teil on salvestamata muudatusi, soovitame avada Booky uues aknas.\n' +
                    'Vastasel juhul saate värskendada käesolevat lehte. Enamikes brauserites saab seda teha näiteks F5 nupuga.\n' +
                    'Kui see ei aita, palume pöörduda klienditoe poole.',
                // TODO contact info
            ),
            heading: tr('Mida edasi teha?'),
        },
        reportInfo: tr('Täpsemad detailid selle kohta saadetakse automaatselt arendajale.'),
        showDetails: tr('Näita tehnilisi detaile'),
    },
    excel: {
        open: tr('Ava Excelis'),
    },
    expense: tr('Kulu'),
    expenseItemTypes: {
        general: tr('Tegevuskulu'),
        'goods-expense': {
            ...tr('Kaup kuludes'),
            short: tr('Kaubakulu'),
        },
        'goods-stock': tr('Kaup varudes'),
    },
    expenses: {
        ...tr('Kulud'),
        account: tr('Kulukonto'),
        add: {
            ...tr('Lisa uus ostuarve'),
            general: tr('Lisa tegevuskulu'),
            goods: {
                expense: tr('Lisa kaup kuludesse'),
                stock: tr('Lisa kaup varudesse'),
            },
        },
        archive: {
            ...tr('Kulude arhiiv'),
            clearFilter: {
                general: tr('Eemalda perioodi filtrid'),
                types: tr('Eemalda kululiigi filtrid'),
                vendor: tr('Eemalda tarnija filtrid'),
            },
            header: {
                general: tr('Periood'),
                types: tr('Kululiigid'),
                vendor: tr('Tarnija'),
            },
        },
        calculationMode: {
            ...tr('Arvutuse režiim'),
            automatic: tr('automaatne'),
            manual: tr('käsitsi'),
        },
        changed: {
            asset: tr('Sellel ostuarvel olevaid põhivarasid on korrigeeritud'),
            stock: tr('Sellel ostuarvel olevaid varusid on korrigeeritud'),
        },
        comment: {
            input: tr('Kommentaar (kuni 2000 tähemärki)'),
        },
        date: tr('Arve koostamise kuupäev'),
        general: {
            ...tr('Tegevuskulud'),
            sortOption: {
                // TODO use t.expenses.sortOption?
                account: tr('konto'),
                date: tr('kuupäeva'),
                vendor: tr('tarnija'),
                withoutVat: tr('summa'),
            },
        },
        goodType: tr('Kauba tüüp'),
        goods: tr('Kaubakulud'),
        invoice: {
            ...tr('Ostuarve'),
            asset: tr('Põhivara ostuarve'),
        },
        invoiceVendor: tr('Arve esitaja'),
        number: tr('Ostuarve number'),
        overdue: tr('Maksetähtaeg üle tähtaja'),
        paid: tr('Tasutud'),
        paidInvoices: tr('Tasutud arved'),
        percentUnpaid: tr('{}% tasumata'),
        processed: {
            ...tr('Kulusid töödeldud'),
            stock: tr('Varusid töödeldud'),
        },
        region: {
            ee: tr('Eesti sisene'),
            eu: tr('EL sisene'),
            nonEe: tr('Eesti väline'),
            other: tr('EL väline'),
        },
        register: tr('Väljaminekute register'),
        sortOption: {
            account: tr('konto'),
            amount: tr('summa'),
            date: tr('kuupäeva'),
            dueDate: tr('tähtaja'),
            type: tr('kululiigi'),
            vendor: tr('tarnija'),
        },
        stock: {
            ...tr('Varud'),
            alreadyPendingChange: tr('Sellel real on juba kinnitamata korrigeerimine ootel.'),
            change: tr('Varude korrigeerimine'),
            changeTotal: tr('Muudatuste summa'),
            hasLaterChanges: tr(
                'Seda rida on juba korrigeeritud. Uue korrigeerimise kuupäev peab olema hilisem kui {}.',
            ),
            noChanges: tr('Ühtegi rida pole korrigeeritud'),
            pendingChanges: tr('Varude kinnitamata korrigeerimised'),
            validation: {
                overOriginal: tr('Ei tohi olla suurem esialgsest'),
            },
        },
        totals: {
            mismatch: {
                nan: tr('Sisestatud numbrid pole korrektsed.'),
                automatic: {
                    nonVatPayer: tr(
                        'Ridadest kokku arvutatud kogusumma ({}) ei ühti sisestatud kogusummaga.\n' +
                            'Palun kontrollige sisestatud arvud üle.',
                    ),
                    vatPayer: tr(
                        'Ridadest kokku arvutatud käibemaksu summa ({}) ja kogusumma ({}) ei ühti sisestatud kogusummadega.\n' +
                            'Palun kontrollige sisestatud arvud üle.',
                    ),
                },
                manual: {
                    nonVatPayer: tr(
                        'Ridadest arvutatud kogusumma ({}) ei ühti sisestatud kogusummaga.\n' +
                            'Palun kontrollige sisestatud arvud üle.',
                    ),
                    vatPayer: tr(
                        'Ridadest kokku arvutatud summa käibemaksuta ({}) ei ühti sisestatud kogusummadest arvutatud käibemaksuta summaga.\n' +
                            'Palun kontrollige sisestatud arvud üle.',
                    ),
                },
            },
        },
        type: tr('Kululiik'),
        unpaid: tr('tasumata'),
        unpaidInvoices: tr('Tasumata ostuarved'),
        vendor: tr('Tarnija'),
    },
    explanation: tr('Selgitus'),
    files: {
        ...tr('Failid'),
        companyUsage: tr('Ettevõtte salvestusmahust on kasutatud {}% ({}/{} MB)'),
        duplicate: tr('Sama fail on juba lisatud'),
        singleFileLimit: tr('Ühe faili maksimaalne suurus: {} MB'),
    },
    financialHistory: tr('Finantsajalugu'),
    firstName: tr('Eesnimi'),
    fixedAssets: {
        // TODO move to t.assets?
        sortOption: {
            date: tr('kuupäeva'),
            eol: tr('eluea lõpu'),
            price: tr('soetusmaksumuse'),
            residual: tr('jääkväärtuse'),
        },
    },
    forward: tr('Edasi'),
    frontPage: tr('Avaleht'),
    incomeItemTypes: {
        goods: tr('Kaup'),
        service: tr('Teenus'),
    },
    incomes: tr('Tulud'),
    incomeTax: tr('Tulumaks'),
    interest: tr('Intress'),
    info: tr('Info'),
    initCompany: {
        date: {
            choose: tr(
                'Palun valige kuupäev, millest alates hakkate Booky rakendusse detailseid andmeid sisestama:',
            ),
            info: tr(
                'Booky rakendust on võimalik kasutada ka ilma varasemaid andmeid sisestamata.\n' +
                    '\n' +
                    'Kui aga soovite kasutada ka Booky aruannete funktsionaalsust, tuleb teil antud valiku puhul hiljem sisestada bilanss seisuga {}.\n' +
                    '\n' +
                    'Majandusaasta aruande koostamiseks võib vaja minna veel teisigi varasemaid aruandeid.',
            ),
            options: {
                today: tr('Tänasest'),
                startOfFiscalYear: tr('Majandusaasta algusest'),
                startOfMonth: tr('Kuu algusest'),
            },
        },
        generalHint: {
            link: tr('Äriregistrist'),
            text: tr('Ettevõtte detailsed andmed võite leida'),
        },
        invoiceDescription: tr('Raamatupidamisprogramm Booky kuutasu'),
        pages: {
            billing: tr('Arveldamine'),
            date: tr('Alustamise kuupäev'),
            general: tr('Üldinfo'),
        },
    },
    interimBalance: tr('Vahebilanss'),
    invoice: tr('Arve', { en: 'Invoice' }),
    invoices: {
        ...tr('Arved'),
        date: tr('Arve kuupäev', { en: 'Invoice date' }),
        dueDate: {
            beforeDate: tr('Ei tohi olla varasem arve kuupäevast'),
        },
        number: tr('Arve number', { en: 'Invoice number' }),
        remove: {
            negativeBalance: tr(
                'Selle arve kustutamisel tekiks {} seisuga bilanssi negatiivne rida kontole {}',
            ),
        },
        sum: tr('Arve summa'),
    },
    labourCost: tr('Tööjõukulu'),
    labourCosts: {
        ...tr('Tööjõukulud'),
        add: tr('Tööjõukulu lisamine'),
        addNew: tr('Lisa uus tööjõukulu'),
        amount: tr('Palgasumma'),
        gross: tr('Brutopalk'),
        links: {
            header: tr('Tutvuge lisaks'),
            pension: tr('Kogumispension'),
            taxFree: tr('Maksuvaba tulu'),
            taxRates: tr('Maksumäärad'),
            varyingSalary: tr('Muutuv töötasu'),
        },
        month: {
            overMax: tr('Peab olema varasem'),
            underMin: tr('Peab olema hilisem'),
        },
        net: {
            ...tr('Netopalk'),
            plural: tr('Netopalgad'),
        },
        socialTaxIncrease: {
            ...tr('Sotsiaalmaksu suurendus'),
            choice: tr('Suurenda sotsiaalmaksu summat'),
            info: tr(
                'Hetkel sisestatud andmete põhjal tuleb sotsiaalmaksu summaks {} €.\n' +
                    'Selleks, et töötajal oleks kehtiv tervisekindlustus, tuleb summat suurendada {} €-ni.\n' +
                    'Küsimuste tekkimisel võtke meiega ühendust.\n',
            ),
        },
        tax: tr('Palgamaksud'),
        taxFree: {
            ...tr('Maksuvaba tulu'),
            modes: {
                automatic: tr('Automaatne'),
                manual: tr('Käsitsi'),
            },
            warning: tr('Hoiatus! Valitud summa on soovitatust suurem'),
        },
        usePensionPayment: tr('Töötaja on liitunud kogumispensioniga'),
    },
    lastName: tr('Perenimi'),
    liabilitiesAndEquity: tr('Passiva (kohustused ja omakapital)'),
    liabilities: {
        current: tr('Lühiajalised kohustused'),
        equity: tr('Omakapital'),
        longTerm: tr('Pikaajalised kohustused'),
        obligations: tr('Kohustused'),
    },
    lifetime: tr('Eluiga'),
    log: {
        ...tr('Logi'),
        actions: {
            addFile: tr('Faili "{}" lisamine'),
            create: tr('Arve koostamine'),
            update: tr('Arve muutmine'),
            confirm: tr('Arve kinnitamine'),
            removeFile: tr('Faili "{}" kustutamine'),
            stockChangeCreate: tr('Varude korrigeerimise koostamine'),
            stockChangeRemove: tr('Varude korrigeerimise kustutamine'),
            stockChangeConfirm: tr('Varude korrigeerimise kinnitamine'),
            valueChangeCreate: tr('Põhivara "{}" väärtuse muutmise koostamine'),
            valueChangeRemove: tr('Põhivara "{}" väärtuse muutmise kustutamine'),
            valueChangeConfirm: tr('Põhivara "{}" väärtuse muutmise kinnitamine'),
        },
    },
    login: {
        ...tr('Logi sisse'),
        forgotPassword: tr('Unustasid parooli?'),
        invalidPassword: tr('Vale parool'),
        mode: {
            // TODO array instead of numeric keys?
            1: tr('Kasutan'),
            2: tr('arvutit'),
            explanation: [
                tr(
                    'Avaliku arvuti puhul logitakse teid automaatselt välja 30 minuti pärast, isikliku arvuti puhul 8 tunni pärast.',
                ),
                tr(
                    'Kui arvutile või seadmele, kus Bookyt kasutate, on ligipääs ka kõrvalistel isikutel, soovitame kasutada avaliku arvuti režiimi ning pärast lõpetamist ennast ise välja logida.',
                ),
                tr(
                    'Vastasel juhul võib keegi võõras lehekülje uuesti avada ning teie kasutajakonto alt edasi toimetada.',
                ),
            ],
            private: tr('isiklikku'),
            public: tr('avalikku'),
            why: tr('Mida see valik mõjutab?'),
        },
    },
    logout: tr('Logi välja'),
    maxSize: tr('maksimaalne suurus'),
    moneyForm: {
        from: {
            bank: tr('Pangakontolt'),
            cash: tr('Kassast'),
        },
        to: {
            bank: tr('Pangakontole'),
            cash: tr('Kassasse'),
        },
    },
    month: {
        ...tr('Kuu'),
        1: tr('Jaanuar'),
        2: tr('Veebruar'),
        3: tr('Märts'),
        4: tr('Aprill'),
        5: tr('Mai'),
        6: tr('Juuni'),
        7: tr('Juuli'),
        8: tr('August'),
        9: tr('September'),
        10: tr('Oktoober'),
        11: tr('November'),
        12: tr('Detsember'),
        short: {
            1: tr('Jaan'),
            2: tr('Veebr'),
            3: tr('Märts'),
            4: tr('Aprill'),
            5: tr('Mai'),
            6: tr('Juuni'),
            7: tr('Juuli'),
            8: tr('Aug'),
            9: tr('Sept'),
            10: tr('Okt'),
            11: tr('Nov'),
            12: tr('Dets'),
        },
        of: {
            1: tr('Jaanuari'),
            2: tr('Veebruari'),
            3: tr('Märtsi'),
            4: tr('Aprilli'),
            5: tr('Mai'),
            6: tr('Juuni'),
            7: tr('Juuli'),
            8: tr('Augusti'),
            9: tr('Septembri'),
            10: tr('Oktoobri'),
            11: tr('Novembri'),
            12: tr('Detsembri'),
        },
    },
    months: tr('kuud'),
    name: tr('Nimi'),
    no: tr('Ei'),
    noChange: tr('Ei muutu'),
    noData: tr('Tehingud puuduvad'),
    none: tr('Puudub'),
    notANumber: tr('Viga', { en: 'Error' }),
    revenues: {
        add: tr('Lisa uus müügiarve'),
        addNew: {
            goods: tr('Lisa uus kaup'),
            service: tr('Lisa uus teenus'),
        },
        archive: {
            ...tr('Müügiarvete arhiiv'),
            clearFilter: {
                contents: tr('Eemalda arve sisu filtrid'),
                customer: tr('Eemalda kliendi filtrid'),
                general: tr('Eemalda perioodi filtrid'),
            },
            header: {
                contents: tr('Arve sisu'),
                customer: tr('Kliendi info'),
                general: tr('Periood'),
            },
        },
        comment: {
            input: tr('Kommentaar (kuni 500 tähemärki)'),
        },
        creditRevenue: {
            ...tr('Kreeditarve', { en: 'Credit note' }),
            create: tr('Koosta kreeditarve'),
            forRevenue: tr('Kreeditarve müügiarvele number', {
                en: 'Credit note for invoice number',
            }),
        },
        disabled: {
            customerType: tr('Arvet ei saa veel salvestada, kuna saaja tüüp on valimata'),
        },
        hasCreditRevenue: tr('Kreeditarve koostatud'),
        invoice: tr('Müügiarve', { en: 'Invoice' }),
        overdueInvoices: tr('Üle tähtaja arved'),
        paid: tr('Arve laekunud'),
        paidInvoices: tr('Laekunud arved'),
        percentUnpaid: tr('{}% laekumata'),
        processed: tr('Arveid töödeldud'),
        recipient: tr('Arve saaja', { en: 'Bill to' }),
        register: tr('Müügiarvete register'),
        searchByNumber: {
            ...tr('Otsi müügiarve numbri järgi'),
            failed: tr('Arvet numbriga "{}" ei leidunud'),
        },
        sortOption: {
            amount: tr('summa'),
            customer: tr('kliendi'),
            date: tr('kuupäeva'),
            dueDate: tr('tähtaja'),
            number: tr('arve numbri'),
            paid: tr('laekumuse'),
        },
        unpaidInvoices: tr('Laekumata müügiarved'),
    },
    obligations: {
        date: {
            due: {
                beforeStart: tr('Ei tohi olla varasem saamise kuupäevast'),
            },
        },
        sortOption: {
            amount: tr('summa'),
            date: tr('kuupäeva'),
            dueDate: tr('tähtaja'),
            interest: tr('intressi'),
            lender: tr('laenuandja'),
            length: tr('pikkuse'),
            product: tr('seadme'),
            timeLeft: tr('lõpuni jäänud aja'),
        },
    },
    onPeriod: tr('perioodil {}'),
    openSchedule: tr('Ava graafik'),
    other: tr('Muu'),
    overdue: tr('Üle tähtaja'),
    paid: {
        incoming: tr('Laekunud'),
    },
    password: {
        ...tr('Parool'),
        recommendation: tr(
            'Soovitame kasutada paroolihaldurit, mis aitab luua ja kasutada iga teenuse jaoks unikaalset ja turvalist parooli.\n' +
                'Lihtsad ja levinud paroolid nagu "123456", "parool", "qwerty" jne on ülimalt ebaturvalised.',
        ),
        repeat: tr('Parool uuesti'),
        reset: {
            enterEmail: tr('Parooli muutmiseks palun sisestage oma e-posti aadress'),
            enterNew: tr('Palun valige endale uus parool'),
            pending: tr('Saatsime teie e-posti aadressile {} kirja parooli muutmise lingiga'),
            success: tr('Parool edukalt muudetud'),
        },
    },
    payableTotal: tr('Kokku tasuda', { en: 'Total with VAT' }),
    payments: {
        creditRevenue: {
            ...tr('Kreeditarve tasumine'),
            amount: tr('Kreeditarvest tasutud summa'),
            isFull: tr('Kas tasusite kreeditarve täielikult?'),
        },
        date: {
            incoming: tr('Arve laekumise kuupäev'),
            outgoing: tr('Tasumise kuupäev'),
        },
        earlier: {
            incoming: tr('Varasemad laekumised'),
            outgoing: tr('Varasemad tasumised'),
        },
        expense: {
            ...tr('Ostuarve tasumine'),
            amount: tr('Ostuarvest tasutud summa'),
            isFull: tr('Kas tasusite ostuarve täielikult?'),
        },
        fullyPaid: {
            incoming: tr('Täielikult laekunud'),
            outgoing: tr('Täielikult tasutud'),
        },
        hasCreditRevenue: tr('Kreeditarve koostatud, rohkem laekumisi märkida ei saa'),
        incoming: tr('Laekumine'),
        invoice: {
            ...tr('Müügiarve laekumine'),
            amount: tr('Müügiarvest laekunud summa'),
            isFull: tr('Kas müügiarve laekus täielikult?'),
        },
        isFull: {
            rest: {
                incoming: tr('Kas ülejäänud summa laekus täielikult?'),
                outgoing: tr('Kas tasusite ülejäänud summa täielikult?'),
            },
        },
        labourCost: {
            amount: {
                net: tr('Netopalkadest tasutud summa'),
                tax: tr('Palgamaksudest tasutud summa'),
            },
            isFull: {
                net: tr('Kas tasusite netopalgad täielikult?'),
            },
            main: {
                net: tr('Netopalkade tasumine'),
                tax: tr('Palgamaksude tasumine'),
            },
        },
        log: {
            incoming: tr('Laekumine ({} €) {}'),
            outgoing: tr('Tasumine ({} €) {}'),
            remove: {
                incoming: tr('Laekumise kustutamine ({} €, {})'),
                outgoing: tr('Tasumise kustutamine ({} €, {})'),
            },
        },
        negativeBalance: {
            onAdd: {
                incoming: tr(
                    'Sellise laekumise puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                ),
                outgoing: {
                    ...tr(
                        'Sellise tasumise puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                    ),
                    bank: tr(
                        'Sellise tasumise puhul läheks {} seisuga pangakonto negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                    ),
                    cash: tr(
                        'Sellise tasumise puhul läheks {} seisuga kassa negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                    ),
                },
            },
            onRemove: {
                incoming: tr(
                    'Selle laekumise kustutamise puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                ),
                outgoing: tr(
                    'Selle tasumise kustutamise puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                ),
            },
        },
        noNeed: tr('Ei vaja tasumist'),
        outgoing: tr('Tasumine'),
        overMax: {
            incoming: tr('Peab olema väiksem täieliku laekumise summast'),
            outgoing: tr('Peab olema väiksem täieliku tasumise summast'),
        },
        removeLast: {
            incoming: tr('Kustuta viimane laekumine'),
            outgoing: tr('Kustuta viimane tasumine'),
        },
        unpaid: {
            incoming: tr('Laekumata'),
            outgoing: tr('Tasumata'),
        },
    },
    payroll: tr('Palgafond'),
    preview: tr('Eelvaade'),
    profitLoss: {
        beforeIncomeTax: tr('Kasum (kahjum) enne tulumaksustamist'),
        net: tr('Aruandeaasta kasum (kahjum)'),
        operating: tr('Ärikasum (-kahjum)'),
    },
    quantity: {
        ...tr('Kogus', { en: 'Quantity' }),
        initial: tr('Algne kogus'),
        new: tr('Uus kogus'),
    },
    remove: tr('Kustuta'),
    renderError: tr(
        'Vabandame, ilmnes süsteemiviga. Palun avage Booky uuesti.\n' +
            'Kui see ei aita, palume pöörduda klienditoe poole.',
        // TODO contact info
    ),
    reports: {
        ...tr('Aruanded'),
        accounts: tr('Kontoplaan'),
        balance: tr('Bilanss'),
        cashFlow: {
            ...tr('Rahavoogude aruanne'),
            directMethod: tr('Otsene meetod'),
            financing: {
                interest: tr('Tasutud intressid'),
                loanRepayments: tr('Saadud laenude tagasimaksed'),
                loansObtained: tr('Saadud laenu'),
                sharesIssued: tr('Laekunud aktsiate või osade emiteerimisest'),
                dividendsPaid: tr('Makstud dividendid'),
                total: tr('Rahavoog finantseerimistegevusest'),
            },
            investing: {
                assets: tr('Tasutud materiaalse ja immateriaalse põhivara soetamisel'),
                loanRepayments: tr('Antud laenude tagasimaksed'),
                loansGranted: tr('Antud laenu'),
                realEstate: tr('Tasutud kinnisvarainvesteeringute soetamisel'),
                shortTerm: tr('Tasutud lühiajaliste investeeringute soetamisel'),
                subsidiaries: tr('Tasutud tütarettevõtete aktsiate või osade soetamisel'),
                total: tr('Rahavoog investeerimistegevusest'),
            },
            operating: {
                employees: tr('Väljamaksed töötajatele'),
                expenseInvoices: tr('Ostuarvete tasumine'),
                interest: tr('Tasutud intressid'),
                sales: tr('Laekunud kaupade müügist ja teenuste osutamisest'),
                taxes: tr('Tasutud maksud'),
                total: tr('Rahavoog äritegevusest'),
            },
            total: tr('Rahavoog kokku'),
            totals: {
                change: tr('Raha ja pangakontode muutus'),
                final: tr('Raha ja pangakontod perioodi lõpus'),
                initial: tr('Raha ja pangakontod perioodi alguses'),
            },
        },
        dates: {
            choose: tr('Vali kuupäev(ad)'),
            modes: {
                dates: tr('Kuupäeva järgi'),
                months: tr('Kuude lõikes'),
                years: tr('Majandusaastate lõikes'),
            },
            noDates: tr('Sellesse vahemikku ei jää ühtegi kuupäeva'),
            tooManyDates: tr('Sellesse vahemikku jääb liiga palju kuupäevi'),
        },
        income: tr('Kasumiaruanne'),
        interimDateToday: tr(
            'Kuna täna on vahebilansi kuupäev, saate perioodidega aruandeid vaadata alles alates homsest.',
        ),
        mode: {
            long: tr('Pikk versioon'),
            short: tr('Lühike versioon'),
        },
        noData: {
            ...tr('Finantsajalugu on sisestamata'),
            enter: tr('Sisesta'),
        },
        periods: {
            choose: {
                ...tr('Vali periood(id)'),
                singular: tr('Vali periood'),
            },
            invalidDayOfMonth: tr('Vigane päeva number'),
            modes: {
                dates: tr('Kuupäeva järgi'),
                months: tr('Kuude lõikes'),
                years: tr('Majandusaastate lõikes'),
                days: tr('Päevade lõikes'),
            },
            noPeriods: tr('Sellesse vahemikku ei jää ühtegi perioodi'),
            tooManyPeriods: tr('Sellesse vahemikku jääb liiga palju perioode'),
            toUnderMin: tr('Ei tohi olla varasem alguskuupäevast'),
        },
        turnover: {
            ...tr('Käibeandmik'),
            current: tr('Käibed perioodil'),
            final: tr('Lõppsaldo'),
            opening: tr('Algsaldo'),
        },
    },
    role: tr('Roll'),
    roles: {
        base: tr('Tavakasutaja'),
        limited: tr('Andmesisestaja'),
        manager: tr('Peakasutaja'),
        test: tr('Testija'),
    },
    rows: {
        processed: tr('Ridu töödeldud'),
    },
    save: tr('Salvesta'),
    saveDraft: tr('Salvesta mustandina'),
    schedule: {
        ...tr('Graafik'),
        interestPayment: tr('Intressi tasumine'),
        notFullMonths: tr('Graafikut ei saa näidata kuna laenuperioodi pikkus kuudes pole täisarv'),
        principalAfter: tr('Põhiosa lõppjääk'),
        principalBefore: tr('Põhiosa algjääk'),
        principalPayment: tr('Põhiosa tagastamine'),
        totalPayment: tr('Kokku teenindus'),
    },
    search: tr('Otsi'),
    searchByName: tr('Otsi nime järgi'),
    selectCompany: {
        ...tr('Valige ettevõte'),
        instructions: tr('Palun valige ülevalt paremalt ettevõte'),
    },
    serverError: {
        'already-assigned': tr('Programmiviga (already-assigned)'),
        'already-confirmed': tr('See arve on juba kinnitatud'),
        'already-paid': tr('See arve on juba täielikult laekunud'),
        'already-paid-by-card': tr('Selle ettevõtte eest on juba tasutud'),
        'already-selected': tr('See ettevõte on juba valitud'),
        'auth-failed': tr('Kasutaja tuvastamine ebaõnnestus, sessioon võib olla aegunud'),
        'cash-flow-mismatch': tr('Rahavoogude aruanne ei klapi'),
        'company-changed': tr(
            'Olete ettevõtet vahetanud, tõenäoliselt teises aknas.\n' +
                'Soovitame tööd jätkata teises aknas ning käesolev sulgeda.',
        ),
        'company-mismatch': tr('Programmiviga (company-mismatch)'),
        'company-not-selected': tr('Ettevõte on valimata'),
        'confirmed-expense': tr('Kinnitatud kulu ei saa enam muuta'),
        'confirmed-invoice': tr('Kinnitatud arvet ei saa enam muuta'),
        'database-connection-lost': tr('Andmebaasiga ühenduse loomine ebaõnnestus'),
        duplicate: tr('Programmiviga (duplicate)'),
        'extraneous-args': tr('Programmiviga (extraneous-args)'),
        'extraneous-fields': tr('Programmiviga (extraneous-fields)'),
        'failed-to-fetch': tr(
            'Serveriga ühendumine ebaõnnestus. Palun kontrollige oma internetiühendust.',
        ),
        'has-credit-revenue': tr('Sellele arvele on koostatud kreeditarve'),
        'has-pending-asset-changes': tr(
            'Sellel põhivaral on kinnitamata väärtuse muudatusi.\n' +
                'Enne põhivara kustutamist tuleb need ära kustutada.',
        ),
        'has-pending-stock-changes': tr(
            'Sellel ostuarvel on kinnitamata varude korrigeerimisi.\n' +
                'Enne arve kustutamist tuleb need ära kustutada.',
        ),
        'file-too-large': tr('Fail on liiga suur'),
        'id-generation-failed': tr('Unikaalse ID genereerimine ebaõnnestus'),
        'income-statement-mismatch': tr('Kasumiaruanne on vigane'),
        'invalid-card-payment-mode': tr('Programmiviga (invalid-card-payment-mode)'),
        'invalid-command': tr('Programmiviga (invalid-command)'),
        'invalid-company-status': tr('Programmiviga (invalid-company-status)'),
        'invalid-component': tr('Kasutajaliidese komponenti ei leitud'),
        'invalid-field': tr('Programmiviga (invalid-field)'),
        'invalid-image': tr('Tundmatu pildifaili tüüp. Lubatud on PNG ja JPG tüüpi pildifailid.'),
        'invalid-path': tr('Programmiviga (invalid-path)'),
        'invalid-parent': tr('Programmiviga (invalid-parent)'),
        'invalid-reset-code': tr('Vigane või aegunud parooli taastamise link'),
        'invalid-region': tr('Programmiviga (invalid-region)'),
        'invalid-resolver': tr('Programmiviga (invalid-resolver)'),
        'missing-fields': tr('Programmiviga (missing-fields)'),
        'negative-balance': tr('Bilanssi ei tohi tekkida negatiivne rida'),
        'no-permissions': tr('Teil puuduvad vajalikud õigused'),
        'no-report-data': tr('Ettevõtte finantsajalugu on sisestamata'),
        'non-200': tr('Programmiviga või puuduv internetiühendus'),
        'not-asset': tr('Programmiviga (not-asset)'),
        'not-confirmed': tr('Arve on kinnitamata'),
        'not-enough-storage': tr('Ettevõttel pole piisavalt salvestusmahtu'),
        'not-found': tr('Objekti ei leitud'),
        'not-paid-by-card': tr('Selle ettevõtte eest pole veel tasutud'),
        'not-regular-expense': tr('Programmiviga (not-regular-expense)'),
        'not-stock': tr('Programmiviga (not-stock)'),
        'report-data-entered': tr('Finantsajalugu on juba sisestatud.'),
        'rev-mismatch': tr(
            'Keegi on vahepeal samu andmeid muutnud. Teie muudatusi ei salvestatud.',
        ),

        // Also used directly, not only through server-error.*
        'session-expired': tr('Teie sessioon on aegunud. Peate uuesti sisse logima.'),

        'session-not-renewable': tr('Seda sessiooni ei saa enam pikendada'),
        'unexpected-email-confirmation-status': tr(
            'Programmiviga (unexpected-email-confirmation-status)',
        ),
        unknown: tr('Programmiviga (unknown)'),
        'users-still-linked': tr('Mõni teine kasutaja on veel selle ettevõttega seotud'),
        'validation-failed': tr('Sisestatud andmed ei vasta reeglitele'),
        'validation-failure-not-rendered': tr('Sisestatud andmed ei vasta reeglitele'),
        'vat-mismatch': tr('Programmiviga (vat-mismatch)'),
        'vat-month-assigned': tr('Arve on valitud KMD esitamiseks'),
        'vat-period-mismatch': tr('Programmiviga (vat-period-mismatch)'),
    },
    session: {
        canRenew: tr('Teil on võimalik sessiooni {} minuti võrra pikendada.'),
        canNotRenew: tr('Sessiooni pole enam võimalik pikendada.'),
        dontRenew: tr('Ära pikenda'),
        expiresAt: tr('Teie sessioon aegub kell {}.'),
        needLogin: tr('Pärast seda peate uuesti sisse logima, et rakendust edasi kasutada.'),
        renew: tr('Pikenda'),
    },
    settings: {
        ...tr('Seaded'),
        bankAccount: tr('Arvelduskonto number'),
        bankAccounts: tr('Pangakontod'),
        bankName: tr('Panga nimi'),
        billing: {
            ...tr('Arveldamine'),
            updateCard: {
                ...tr('Uuenda kaardi andmeid'),
                failed: tr('Krediitkaardi andmete uuendus ebaõnnestus'),
                inProgress: tr('Krediitkaardi andmete uuendus on veel pooleli'),
                paymentNotFound: tr('Süsteemiviga: tehingut ei leidunud'),
                success: tr('Krediitkaardi andmed on edukalt uuendatud'),
            },
        },
        bookyInfo: {
            ...tr('Booky info'),
            firstPayment: tr('{} ettevõttekonto loodud {}'),
            interimDate: tr('{} vahebilanss määratud seisuga {}'),
        },
        closeAccount: {
            ...tr('Ettevõtte konto sulgemine'),
            close: tr('Sulge konto'),
            confirm: tr('KINNITAN'),
            confirmInstructions: tr(
                'Selleks, et konto sulgemise soovi kinnitada, palun kirjutage siia lahtrisse "{}"',
            ),
            usersStillLinked: tr(
                'Ettevõtte kontot ei saa hetkel sulgeda, kuna sellega on seotud ka teisi kasutajaid peale teie.\n' +
                    'Kõigepealt on vaja nemad ettevõttest eemaldada.',
            ),
            warning: tr(
                'Pärast ettevõtte konto sulgemist kaotate sellele ligipääsu ning kasutamata päevade eest ettemaksu ei tagastata.',
            ),
        },
        company: tr('Ettevõtte üldinfo'),
        contact: tr('Kontaktandmed'),
        defaultPaymentTerm: tr('Vaikimisi maksetingimus'),
        email: tr('E-posti aadress'),
        logo: {
            ...tr('Logo'),
            current: tr('Praegune logo'),
            notSet: tr('Logo puudub'),
            pickNew: tr('Vali uus logo'),
            unsaved: tr('Uus logo'),
        },
        phone: tr('Telefoninumber'),
        requisites: tr('Rekvisiidid'),
        revenue: {
            ...tr('Müügiarve info'),
            accountNumber: tr('Kontonumber'),
            addBankAccount: tr('Lisa pangakonto'),
            bankName: tr('Pank'),
            enterBankName: tr('Palun sisestage panga nimi'),
            enterAccountNumber: tr('Palun sisestage kontonumber'),
        },
        revenueRecipient: tr('Booky arve saaja'),
        users: {
            addUser: tr('Lisa kasutaja'),
            addUserInfo: tr(
                'Kui kasutajal on juba Booky konto, sisestage kontoga seotud e-posti aadress.\n' +
                    'Kui sisestatud aadressiga kontot ei leidu, saadetakse sellele kutse Booky konto loomiseks.',
            ),
            confirmRemoveUser: tr('Kas olete kindel, et soovite kasutaja eemaldada?'),
            duplicate: {
                alreadyLinked: tr('Sellise e-posti aadressiga kasutaja on juba ettevõttega seotud'),
                alreadySent: tr('Sellele e-posti aadressile on kutse juba saadetud'),
            },
            editRole: tr('Muuda rolli'),
            inviteSent: tr(
                'Kutse saadetud. Kasutaja lisandub ettevõttesse, kui ta kutse vastu võtab.',
            ),
            removeUser: tr('Eemalda kasutaja'),
            sendInvite: tr('Saada kutse'),
        },
        website: tr('Veebiaadress'),
    },
    show: tr('Näita'),
    showCurrentMonth: tr('Näita jooksvat kuud'),
    showCurrentYear: tr('Näita jooksvat aastat'),
    showMore: tr('Näita rohkem'),
    signup: {
        ...tr('Hakka kasutajaks'),
        duplicateEmail: tr('Sellise e-posti aadressiga kasutaja on juba olemas'),
        passwordTooShort: tr('Minimaalne pikkus on {}'),
    },
    size: tr('Suurus'),
    sortBy: [tr('Sorteeri'), tr('järgi')],
    status: tr('Seisund'),
    sum: tr('Summa'),
    sumBeforeDiscount: tr('Summa enne soodustust', { en: 'Total before discount' }),
    sumWithVat: {
        short: tr('Summa KM-ga'),
    },
    sumWithoutVat: {
        ...tr('Summa käibemaksuta', { en: 'Subtotal' }),
        short: tr('Summa KM-ta', { en: 'Amount' }),
    },
    taxes: {
        ...tr('Maksud'),
        pages: {
            summary: tr('Kokkuvõte'),
            vat: tr('Käibemaks'),
        },
        summary: {
            labourTaxes: tr('Palgamaksud'),
            purchaseVat: tr('Ostuarvete KM'),
            salesVat: tr('Müügiarvete KM'),
        },
        vat: {
            amountFromBank: tr('Summa pangakontolt'),
            amountFromPrepaid: tr('Summa ettemaksukontolt'),
            declaration: tr('Käibedeklaratsioon (KMD)'),
            hideDeclarationInfo: tr('Sulge MTA KMD info'),
            interest: tr('MTA intressid'),
            invoices: {
                plural: tr('{} arvet'),
                singular: tr('1 arve'),
            },
            monthTotal: tr('{} {} kokku'),
            negativeBalance: {
                ...tr(
                    'Sellise tasumise puhul tekiks {} seisuga bilanssi negatiivne rida kontole {}',
                ),
                bank: tr(
                    'Sellise tasumise puhul läheks {} seisuga pangakonto negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                ),
                cash: tr(
                    'Sellise tasumise puhul läheks {} seisuga kassa negatiivseks. Kontrollige tulude laekumiste ning kulude tasumiste märkimist.',
                ),
            },
            noneSelected: tr('Ühtegi arvet pole valitud'),
            noOverLimitInvoices: tr('Pole ühtegi üle {}€ arvet'),
            overpaid: tr('Enammakstud käibemaks'),
            paid: tr('Tasutud {}'),
            partner: tr('Tehingu partner'),
            paymentTitle: tr('Käibemaksu tasumine'),
            prepaidAmount: tr('MTA ettemaksukonto saldo Booky programmis seisuga {} {}€'),
            prepaidUnavailable: tr(
                'MTA ettemaksukonto saldo nägemiseks sisestage palun ettevõtte finantsajalugu',
            ),
            purchaseInvoices: tr('Ostuarved'),
            salesInvoices: tr('Müügiarved'),
            saveInvoiceSelection: tr('Salvesta arvete valik'),
            selectInvoices: tr('Vali arved'),
            showAllInvoices: tr('Näita kõiki arveid'),
            showDeclarationInfo: tr('Ava MTA KMD info'),
            showOverLimitInvoices: tr('Näita ainult üle {}€ arveid'),
            showPreviousMonth: tr('Näita eelmist kuud'),
            toBePaid: tr('Tasumisele kuuluv käibemaks'),
            total: tr('Arve summa'),
            totalToPay: tr('Kokku tasuda'),
            transferTitle: tr('Ettemaksukontole kandmine'),
            transferToPrepaid: tr('Kanna ettemaksukontole'),
            transferredToPrepaid: tr('Kantud ettemaksukontole {}'),
            vatAmount: tr('KM'),
            withoutVat: tr('KM-ta'),
            withVat: tr('KM-ga'),
        },
    },
    term: tr('Maksetingimus'),
    terms: {
        agree: tr('Olen lugenud ja nõustun {}'),
        with: tr('kasutustingimustega ja privaatsustingimustega'),
    },
    time: tr('Aeg'),
    total: {
        ...tr('Kokku', { en: 'Total' }),
        initial: tr('Algne kokku'),
        new: tr('Uus kokku'),
    },
    totalWithoutVat: {
        ...tr('Kokku käibemaksuta'),
        short: tr('Kokku km-ta'),
    },
    totals: {
        creditRevenue: tr('Kreeditarve summa'),
        expense: tr('Ostuarve summa'),
        revenue: tr('Müügiarve summa'),
        labourCost: {
            net: tr('Netopalkade summa'),
            tax: tr('Palgamaksude summa'),
        },
    },
    type: tr('Tüüp'),
    unconfirmed: tr('Kinnitamata'),
    unit: tr('Ühik'),
    unitPrice: {
        ...tr('Ühiku hind', { en: 'Unit price' }),
        initial: tr('Algne ühiku hind'),
        new: tr('Uus ühiku hind'),
    },
    unpaid: {
        incoming: tr('Laekumata'),
    },
    update: tr('Uuenda'),
    user: {
        ...tr('Kasutaja'),
        emailNotFound: tr('Sellise e-mailiga kasutajat ei leidunud'),
    },
    users: tr('Kasutajad'),
    userSettings: {
        ...tr('Kasutaja seaded'),
        companies: {
            active: tr('Aktiivsed ettevõtted'),
            init: tr('Aktiveerimata ettevõtted'),
            invites: tr('Ootel kutsed'),
            noCompanies: tr('Teie kasutajakonto pole seotud ühegi ettevõtte kontoga.'),
        },
        menu: {
            companies: tr('Ettevõtete ülevaade'),
            general: tr('Üldinfo'),
        },
    },
    validation: {
        amount: tr('Peab olema positiivne arv kuni kahe komakohaga'), // TODO not always positive
        'date-ymd': tr('Peab olema kuupäev'),
        'debit-credit-mismatch': tr('Deebet- ja kreeditridade summad ei võrdu'),
        email: tr('Peab olema e-posti aadress'),
        empty: tr('Ei tohi olla tühi'),
        'file-image': tr('Peab olema PNG või JPG tüüpi pildifail'),
        'file-too-big': tr('Valitud fail on liiga suur'),
        integer: tr('Peab olema täisarv'),
        interest: tr('Peab olema positiivne arv kuni kolme komakohaga'),
        invalid: tr('Ei vasta nõuetele'),
        'negative-balance': tr('Sellise väärtuse puhul tekiks bilanssi negatiivne rida'),
        'no-rows': tr('Palun lisage vähemalt üks rida'),
        'non-zero': tr('Peab olema nullist erinev'),
        'over-max': tr('Peab olema vähem'),
        required: tr('Kohustuslik väli'),
        'too-long': tr('Peab olema lühem'),
        'too-short': tr('Peab olema pikem'),
        'under-min': tr('Peab olema rohkem'),
        unique: tr('Peab olema unikaalne'),
    },
    vat: {
        ...tr('Käibemaks', { en: 'VAT' }),
        short: tr('Km'),
        with: tr('Käibemaksuga'),
        without: tr('Käibemaksuta'),
    },
    vatDeclared: tr('KMDs'),
    versionMismatch: tr(
        'Rakendus on vahepeal uuenenud. Palun laadige leht uuesti, et kasutada uusimat versiooni.',
    ),
    view: tr('Vaata'),
    year: tr('aasta'),
    years: tr('aastat'),
    yes: tr('Jah'),
}
