import React, { FC } from 'react'

import { LoadingIcon } from '../loading-icon'
import { TaxesSidebar } from './sidebar'
import { TaxesVatMain, TaxesVatMainProps } from './vat-main'
import { VatPaymentSidebar, VatPaymentSidebarProps } from './vat-payment-sidebar'

type VatSidebarProps =
    | { type: 'loading' }
    | { type: 'taxes' }
    | { type: 'payment'; props: VatPaymentSidebarProps }

export interface TaxesVatProps {
    sidebar: VatSidebarProps
    title: string
    subtitle: string
    main?: TaxesVatMainProps
}

const VatSidebar: FC<VatSidebarProps> = (sidebar) => {
    if (sidebar.type === 'loading') {
        return (
            <div className="sidebar">
                <LoadingIcon color="white" />
            </div>
        )
    } else if (sidebar.type === 'taxes') {
        return <TaxesSidebar selected="vat" />
    } else {
        return <VatPaymentSidebar {...sidebar.props} />
    }
}

export const TaxesVat: FC<TaxesVatProps> = ({ sidebar, title, subtitle, main: content }) => {
    return (
        <div className="content-area">
            <VatSidebar {...sidebar} />
            <div className="content taxes">
                <h1 className="title">
                    {title}
                    <span className="title__sub-title">{subtitle}</span>
                </h1>
                <div>{content ? <TaxesVatMain {...content} /> : <LoadingIcon color="black" />}</div>
            </div>
        </div>
    )
}
