import React from 'react'

import { NET_PROFIT } from '../../common/accounts'
import { EntryItemType } from '../../common/enums'
import { AllAccounts } from '../../common/types/account'
import { EntryItemInputs } from '../../common/types/entry'
import { ValidationError } from '../../common/types/errors'
import { Input as InputType, InputValues } from '../../common/types/inputs'
import { Column } from '../../common/types/table'
import { getDefaultAccountName } from '../account-utils'
import { getAccountPrefix } from '../entry-utils'
import { emitFocusInput } from '../event-bus'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { removeItem } from '../state/entry-actions'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { renderChoice } from './choice'
import { DeleteIcon } from './delete-icon'
import { Dropdown } from './dropdown'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'
import { renderTable } from './table'

interface Props {
    itemIds: string[]
    inputValues: InputValues
    accounts: AllAccounts | null
    valErrors: ValidationError[] | undefined
}

interface Row {
    reactKey: string
    id: string
    inputs: EntryItemInputs
}

const errorPrefix = 'entry.items'

const getLeftAligned = () => ({ className: 'text-left' })

const getAccountOptions = (accounts: AllAccounts) => {
    const opts = new Map<string, string>()

    accounts.forEach((accountOrNumber, number) => {
        if (number !== NET_PROFIT) {
            const prefix = getAccountPrefix(number)

            const name =
                typeof accountOrNumber === 'string'
                    ? getDefaultAccountName(accountOrNumber)
                    : accountOrNumber.name

            const label = prefix + ': ' + number + ' ' + name
            opts.set(number, label)
        }
    })

    return opts
}

const renderAccountInput = (
    rowId: string,
    input: InputType<string>,
    inputValues: InputValues,
    accounts: AllAccounts | null,
    valErrors: ValidationError[] | undefined,
) => {
    if (!accounts) {
        return <LoadingIcon color="black" />
    }

    const options = getAccountOptions(accounts)

    return (
        <div>
            <Dropdown
                input={input}
                inputValues={inputValues}
                options={options}
                searchSize={50}
                onSelect={() => {
                    emitFocusInput('item-amount-' + rowId)
                }}
            />
            {valErr(valErrors, errorPrefix + '.' + rowId + '.accountNumber')}
        </div>
    )
}

const renderTypeToggle = (input: InputType<EntryItemType>, inputValues: InputValues) =>
    renderChoice<EntryItemType>({
        input,
        inputValues,
        type: 'buttons',
        options: [
            {
                id: EntryItemType.debit,
                label: t.debit.get(),
            },
            {
                id: EntryItemType.credit,
                label: t.credit.get(),
            },
        ],
        buttonClassName: 'button--primary button--min-width',
        selectedButtonClassName: 'button--primary-selected button--min-width',
    })

const getItemColumns = (
    inputValues: InputValues,
    accounts: AllAccounts | null,
    valErrors: ValidationError[] | undefined,
): Column<Row>[] => [
    {
        header: { content: t.account.get(), getProps: getLeftAligned },
        render: browserOnly((row) =>
            renderAccountInput(row.id, row.inputs.accountNumber, inputValues, accounts, valErrors),
        ),
    },
    {
        header: { content: t.amount.get(), getProps: getLeftAligned },
        render: browserOnly((row) => (
            <div>
                <Input
                    className="amount-input"
                    input={row.inputs.amount}
                    inputValues={inputValues}
                    focusEventId={'item-amount-' + row.id}
                />
                {valErr(valErrors, errorPrefix + '.' + row.id + '.amount')}
            </div>
        )),
    },
    {
        header: { content: t.type.get(), getProps: getLeftAligned },
        render: browserOnly((row) => (
            <>
                {renderTypeToggle(row.inputs.type, inputValues)}
                {valErr(valErrors, errorPrefix + '.' + row.id + '.type')}
            </>
        )),
    },
    {
        render: browserOnly((row) => {
            return <DeleteIcon onClick={() => removeItem(row.id)} />
        }),
    },
]

const getRow = (id: string): Row => ({
    reactKey: id,
    id,
    inputs: inputs.entry.item(id),
})

export const EntryItemTable = ({ itemIds, inputValues, accounts, valErrors }: Props) => {
    const columns = getItemColumns(inputValues, accounts, valErrors)
    const rows = itemIds.map(getRow)

    return renderTable({
        columns,
        rows,
        tableClassName: 'table table--bottom-border entries__item-table align-top',
    })
}
