import React from 'react'
import ReactDOM from 'react-dom'

import { schedule } from '../common/clock'
import { Time } from '../common/time'
import { BannerRenderer } from '../common/types/banner'
import { Scheduled } from '../common/types/clock'
import { ApiSession } from '../common/types/session'
import { Banners } from './components/banners'
import {
    onCloseFakeBanner,
    onCloseSessionExpiringBanner,
    onFirstVersionMismatch,
    onOpenFakeBanner,
    onUpdatedSession,
    runAfterStoreReset,
} from './event-bus'

let cleanupPrevious: (() => void) | null = null

export const cleanup = () => {
    if (cleanupPrevious) {
        cleanupPrevious()
        cleanupPrevious = null
    }
}

const reset = (renderer: BannerRenderer) => {
    cleanup()

    let scheduled: Scheduled | null = null
    let expires: Time | null = null
    let renewable = false
    let sessionExpirationVisible = false
    let versionMismatch = false
    let fakeVisible = false

    const clearScheduled = () => {
        if (scheduled) {
            scheduled.clear()
            scheduled = null
        }
    }

    const render = () => {
        const props = { sessionExpirationVisible, expires, renewable, versionMismatch, fakeVisible }
        const element = <Banners {...props} />
        renderer.render(element)
    }

    const showExpiring = () => {
        sessionExpirationVisible = true
        render()
    }

    const closeExpiring = () => {
        sessionExpirationVisible = false
        render()
    }

    const subscriptions = [
        onUpdatedSession((session: ApiSession) => {
            clearScheduled()
            expires = Time.fromIso(session.expires)
            renewable = session.renewable
            const warnTime = expires.addMinutes(-5)
            const msLeft = warnTime.diff(Time.now())

            if (msLeft > 0) {
                scheduled = schedule(msLeft, showExpiring, 'show-expiring')

                if (sessionExpirationVisible) {
                    closeExpiring()
                }
            } else {
                showExpiring()
            }
        }),
        onCloseSessionExpiringBanner(closeExpiring),
        onFirstVersionMismatch(() => {
            versionMismatch = true

            // Delay the render a bit in case we're auto-reloading after login.
            // Otherwise the bar will appear for a split second before disappearing.
            setTimeout(render, 500)
        }),
        onOpenFakeBanner(() => {
            fakeVisible = true
            render()
        }),
        onCloseFakeBanner(() => {
            fakeVisible = false
            render()
        }),
    ]

    cleanupPrevious = () => {
        clearScheduled()

        for (const subscription of subscriptions) {
            subscription.remove()
        }
    }
}

export const init = (renderer: BannerRenderer) => {
    reset(renderer)
    const subscription = runAfterStoreReset(() => reset(renderer))
    return subscription
}

export const initForBrowser = () => {
    const container = document.querySelector<HTMLElement>('#banners')!

    const renderer: BannerRenderer = {
        render(element) {
            ReactDOM.render(element, container)
        },
    }

    init(renderer)
}
