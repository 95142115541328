import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { Column } from '../../../common/types/table'
import { t } from '../../i18n'
import { renderAmount, renderAmountOrDash } from '../../render-amount'
import { renderExcelMoney } from '../../render-excel-money'
import { wrapExcelButton } from '../../standard-page-utils'
import { browserOnly } from '../../table-utils'
import { Button, ButtonProps } from '../button'
import { DeleteIcon, DeleteIconProps } from '../delete-icon'
import { ExcelButtonProps } from '../excel-button'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { MonthNav, MonthNavProps } from '../month-nav'
import { NoData, NoDataProps } from '../no-data'
import { Payment, PaymentProps } from '../payment'
import { renderSortOptions, SortOptionsProps } from '../sort-options'
import { StandardPage } from '../standard-page'
import { renderTable } from '../table'
import { ViewIcon, ViewIconProps } from '../view-icon'

export type RevenueRegisterSortId = 'number' | 'date' | 'dueDate' | 'paid'

export interface RevenueRegisterRow {
    className?: string
    number: string
    date: Day
    customer: string
    withVat: number
    dueDate: string
    sortKeys: {
        confirmed: boolean
        createTime: string
        daysOverdue: number
        paid: boolean
    }
    viewIcon: ViewIconProps
    actions: LinkProps[]
    removeLink?: DeleteIconProps
    vatNote?: LinkProps
}

export interface RevenueRegisterTotals {
    withVat: number
}

type RevenueRegisterColumn = Column<RevenueRegisterRow, RevenueRegisterTotals>

interface RevenueRegisterOkProps {
    status: 'ok'
    sidebar?: PaymentProps
    title: string
    subtitle: string
    sortOptions?: SortOptionsProps<RevenueRegisterSortId>
    monthNav: MonthNavProps
    excelButton?: ExcelButtonProps
    rows: RevenueRegisterRow[]
    totals: RevenueRegisterTotals
    showAllButton?: ButtonProps
}

export type RevenueRegisterProps =
    | { status: 'loading' }
    | { status: 'no-data'; noData: NoDataProps }
    | RevenueRegisterOkProps

export const getRevenueRegisterColumns = (): RevenueRegisterColumn[] => [
    {
        header: { content: t.invoices.number.get() },
        render: (row) => row.number,
        excelWidth: 13,
    },
    {
        header: { content: t.invoices.date.get() },
        render: (row) => ({ browser: row.date.dmy(), excel: row.date }),
        excelWidth: 14,
    },
    {
        header: { content: t.customer.get() },
        render: (row) => row.customer,
        excelWidth: 30,
    },
    {
        header: {
            content: t.invoices.sum.get(),
            getProps: () => ({ className: 'text-right' }),
        },
        getProps: () => ({ className: 'amount' }),
        render: (row) => ({
            browser: renderAmountOrDash(row.withVat),
            excel: renderExcelMoney(row.withVat, false),
        }),
        getTotal: (totals) => renderAmount(totals.withVat),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.withVat }),
        getTotalProps: () => ({ className: 'amount' }),
        excelWidth: 15,
    },
    {
        header: {
            content: t.dueDate.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'text-center' }),
        render: (row) => row.dueDate,
        excelWidth: 20,
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) => (
            <span>
                <ViewIcon {...row.viewIcon} />
                {row.actions.map((linkProps, index) => (
                    <Link key={index} {...linkProps} />
                ))}
            </span>
        )),
        hideInExcel: true,
    },
    {
        getProps: () => ({ className: 'text-right' }),
        render: browserOnly((row) => (
            <span>
                {row.vatNote && <Link {...row.vatNote} className="vat-declaration-link" />}
                {row.removeLink && <DeleteIcon {...row.removeLink} />}
            </span>
        )),
        hideInExcel: true,
    },
]

export const RevenueRegister: FC<RevenueRegisterProps> = (props) => {
    if (props.status === 'loading') {
        return <LoadingPage />
    }

    if (props.status === 'no-data') {
        return <NoData {...props.noData} />
    }

    const {
        sidebar,
        title,
        subtitle,
        sortOptions,
        monthNav,
        excelButton,
        rows,
        totals,
        showAllButton,
    } = props

    const columns = getRevenueRegisterColumns()

    return (
        <StandardPage
            className="invoice-register"
            sidebar={sidebar && <Payment {...sidebar} />}
            title={title}
            subtitle={subtitle}
            sortOptions={sortOptions && renderSortOptions(sortOptions)}
            buttons={
                <>
                    <MonthNav {...monthNav} />
                    {excelButton && wrapExcelButton(excelButton)}
                </>
            }
            table={React.createElement(
                'div',
                null,
                renderTable({
                    columns,
                    rows,
                    totals,
                    stickyHeader: true,
                    tableClassName: 'main-table',
                    wrapperClassName: 'main-table-wrapper',
                }),
                showAllButton && <Button {...showAllButton} />,
            )}
        />
    )
}
