import classnames from 'classnames'
import React, { FC } from 'react'

import { canUpdateBillingDetails } from '../../../common/access'
import { ApiCompany } from '../../../common/types/company'
import { t } from '../../i18n'

type PageId = 'general' | 'date' | 'billing'

interface Props {
    activeItem?: PageId
    company: ApiCompany | null
}

const renderItemText = (isEnabled: boolean, text: string, href: string) => {
    if (isEnabled) {
        return (
            <a href={href} className="init-company__nav-link">
                {text}
            </a>
        )
    } else {
        return text
    }
}

const renderItem = (pageId: PageId, activePage: PageId | undefined, isEnabled: boolean) => {
    const isActive = pageId === activePage
    const baseClass = 'init-company__nav-item'

    const className = classnames(baseClass, {
        [baseClass + '--active']: isActive,
        [baseClass + '--disabled']: !isEnabled,
    })

    const text = t.initCompany.pages[pageId].get()

    return (
        <div className={className}>
            {renderItemText(isEnabled && !isActive, text, '#/init-company/' + pageId)}
        </div>
    )
}

const canOpenBilling = (company: ApiCompany | null) =>
    company !== null && canUpdateBillingDetails(company)

export const InitCompanyNav: FC<Props> = ({ activeItem, company }) => (
    <div className="init-company__nav">
        {renderItem('general', activeItem, true)}
        {renderItem('date', activeItem, company !== null)}
        {renderItem('billing', activeItem, canOpenBilling(company))}
    </div>
)
