import React from 'react'

import { Warning } from '../../common/types/errors'
import { t } from '../i18n'

type CloseWarning = (id: string) => void
interface Props {
    warnings: Warning[]
    closeWarning: CloseWarning
}

const renderWarning = ({ id, errorCode, customMessage }: Warning, closeWarning: CloseWarning) => {
    const onClick = () => closeWarning(id)
    const text = customMessage || t.serverError[errorCode].get()

    return (
        <div key={id} className="warning">
            {text}
            <div className="close-button" onClick={onClick}>
                ×
            </div>
        </div>
    )
}

export const WarningPanel = ({ warnings, closeWarning }: Props) => (
    <div className="warning-panel">
        {warnings.map((warning) => renderWarning(warning, closeWarning))}
    </div>
)
