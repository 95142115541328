import React, { FC } from 'react'

import { formatAmount } from '../format-amount'
import { renderValidationErrors } from '../val-err'
import { Input, InputProps } from './input'
import { ValidationErrorProps } from './validation-error'

export interface TotalsRightCell {
    amount?: number
    input?: InputProps
    text?: string
    euroSign: boolean
    errors?: ValidationErrorProps[]
}

export interface TotalsRow {
    className?: string
    left: string
    right: TotalsRightCell
}

export interface TotalsTableProps {
    rows: TotalsRow[]
}

const renderRight = ({ amount, input, text, euroSign, errors }: TotalsRightCell) => (
    <td className="text-right numeric">
        {typeof amount === 'number' && formatAmount(amount)}
        {input && <Input {...input} />}
        {text}
        {euroSign && ' €'}
        {renderValidationErrors(errors)}
    </td>
)

const renderRow = ({ className, left, right }: TotalsRow, index: number) => (
    <tr key={index} className={className}>
        <td>{left}</td>
        {renderRight(right)}
    </tr>
)

export const TotalsTable: FC<TotalsTableProps> = ({ rows }) => (
    <table id="totals">
        <tbody>{rows.map(renderRow)}</tbody>
    </table>
)
