import classnames from 'classnames'
import React, { FC } from 'react'

import { BALANCE, INCOME_STATEMENT, Level1Accounts, NET_PROFIT_L2 } from '../../../common/accounts'
import { reportModes } from '../../../common/enums'
import { ApiAccount } from '../../../common/types/account'
import { Column, WrappedExcelValue } from '../../../common/types/table'
import { getDefaultAccountName } from '../../account-utils'
import { getExcelButtonProps } from '../../excel-utils'
import { ExcelFont, ExcelSpec, ExcelStyle } from '../../excel/types'
import { t } from '../../i18n'
import { addAccountRows, getAccountNodes, Row } from '../../report-utils'
import { RootData } from '../../state/root-data'
import { combineHorizontally } from '../../table-utils'
import { ExcelButton } from '../excel-button'
import { LoadingIcon } from '../loading-icon'
import { renderTable } from '../table'

const renderExcelText = (
    value: string | undefined,
    row: Row<void>,
    indent: boolean,
): WrappedExcelValue => {
    const style: ExcelStyle = { font: row.level === 1 ? ExcelFont.bold : ExcelFont.regular }

    if (indent && row.level) {
        style.alignment = { indent: row.level - 1 }
    }

    return { value: value || '', style }
}

const getColumns = (): Column<Row<void>>[] => [
    {
        getProps: (row) => ({
            className: classnames('table__body-cell', 'table__body-cell--no-left-pad', {
                'text-bold': row.level === 1,
            }),
        }),
        render: (row) => ({
            browser: row.number,
            excel: renderExcelText(row.number, row, false),
        }),
        excelWidth: 12,
    },
    {
        getProps: (row) => {
            const className = classnames('chart-of-accounts__account-name', {
                'chart-of-accounts__account-name--level1': row.level === 1,
                'chart-of-accounts__account-name--level2': row.level === 2,
                'chart-of-accounts__account-name--level3': row.level === 3,
                'chart-of-accounts__account-name--level4': row.level === 4,
            })

            return { className }
        },
        render: (row) => {
            const value = row.label || getDefaultAccountName(row.number!)
            return { browser: value, excel: renderExcelText(value, row, true) }
        },
        excelWidth: 55,
    },
]

const getRows = (level1Accounts: Level1Accounts, accounts: ApiAccount[]) => {
    const getAmounts = () => new Map<void, number>()

    const nodes = getAccountNodes(
        level1Accounts,
        getAmounts,
        (_amounts, number) => number !== NET_PROFIT_L2 && !number.startsWith(NET_PROFIT_L2 + '.'),
        accounts,
    )

    const rows: Row<void>[] = []
    addAccountRows(nodes, rows, reportModes.long, 1)
    return rows
}

const renderContent = ({ accountData: { accounts }, processes, progress }: RootData) => {
    if (!accounts) {
        return <LoadingIcon color="black" />
    }

    const columns = getColumns()
    const balanceRows = getRows(BALANCE, accounts)
    const incomeRows = getRows(INCOME_STATEMENT, accounts)

    const { columns: combinedColumns, rows: combinedRows } = combineHorizontally(
        { columns, rows: balanceRows },
        { columns: [{ render: () => '', excelWidth: 5 }], rows: [] },
        { columns, rows: incomeRows },
    )

    const title = t.reports.accounts.get()

    const spec: ExcelSpec<any> = {
        // TODO avoid 'any'
        columns: combinedColumns,
        rows: combinedRows,
        outputName: title,
        noHeader: true,
    }

    const excelButton = getExcelButtonProps(
        spec,
        '',
        processes,
        progress,
        'button--wide button--primary',
    )

    return (
        <>
            <div className="align-right relative">
                <ExcelButton {...excelButton} />
            </div>
            <h1 className="title">{title}</h1>
            <div className="inline-top" style={{ marginRight: '6em' }}>
                <h3 className="section-title">{t.accounts.balance.get()}</h3>
                {renderTable({ columns, rows: balanceRows, noHeader: true, noWrapper: true })}
            </div>
            <div className="inline-top">
                <h3 className="section-title">{t.accounts.incomeExpense.get()}</h3>
                {renderTable({ columns, rows: incomeRows, noHeader: true, noWrapper: true })}
            </div>
        </>
    )
}

export const ChartOfAccounts: FC<RootData> = (rootData) => (
    <div className="content-area">
        <div className="content report chart-of-accounts">{renderContent(rootData)}</div>
    </div>
)
