import React, { FC } from 'react'

import { Day } from '../../common/time'
import { Input } from '../../common/types/inputs'
import { t } from '../i18n'
import { ArrowIcon } from './arrow-icon'
import { Button, ButtonProps } from './button'

export interface MonthNavProps {
    day: Day
    input: Input<string>
    getDefault?: () => Day
    defaultLabel?: string

    /** Must be first day of month */
    maxMonth?: Day
    minMonth?: Day

    afterChange?: (newMonth: string) => void
}

const getArrow = (modifierClass: string) => <ArrowIcon modifierClass={modifierClass} />

const setMonth = ({ input, afterChange }: MonthNavProps, newMonth: string) => {
    input.set(newMonth)

    if (afterChange) {
        afterChange(newMonth)
    }
}

const renderPreviousButton = (props: MonthNavProps) => {
    const { day, minMonth } = props

    const buttonProps: ButtonProps = {
        text: getArrow('arrow--left'),
        className: 'button--primary',
    }

    const lastOfPrev = day.lastOfPreviousMonth()

    if (minMonth && lastOfPrev.isBefore(minMonth)) {
        buttonProps.disabled = true
    } else {
        buttonProps.onClick = () => setMonth(props, lastOfPrev.ym())
    }

    return <Button {...buttonProps} />
}

const renderNextButton = (props: MonthNavProps) => {
    const { day, maxMonth } = props

    const buttonProps: ButtonProps = {
        text: getArrow('arrow--right'),
        className: 'button--primary',
    }

    const firstOfNext = day.firstOfNextMonth()

    if (maxMonth && firstOfNext.isAfter(maxMonth)) {
        buttonProps.disabled = true
    } else {
        buttonProps.onClick = () => setMonth(props, firstOfNext.ym())
    }

    return <Button {...buttonProps} />
}

const renderDefaultButton = (props: MonthNavProps) => {
    const { getDefault = () => Day.today(), defaultLabel = t.showCurrentMonth.get() } = props

    return (
        <Button
            onClick={() => setMonth(props, getDefault().ym())}
            text={defaultLabel}
            className="button--primary button--wide top-margin"
        />
    )
}

export const MonthNav: FC<MonthNavProps> = (props) => {
    const { day } = props

    return (
        <>
            <div className="month-navigation">
                {renderPreviousButton(props)}
                {day.monthName().toUpperCase()}
                {renderNextButton(props)}
            </div>
            {renderDefaultButton(props)}
        </>
    )
}
