import React, { Component, ReactNode } from 'react'

import { calculationModes, unitsArray } from '../../common/enums'
import { CalculationMode } from '../../common/types/enums'
import { ValidationError } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { ItemInputs, ItemType } from '../../common/types/item'
import { Column } from '../../common/types/table'
import { ExpenseVatPercentage } from '../../common/types/vat'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { renderInputOrValue } from '../input-utils'
import { calculateItemFromInputs, calculateUnitPriceFromItemInputs } from '../item-utils'
import { toComma } from '../number-utils'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { getExpenseVatInput } from '../vat-utils'
import { AccountInputProps, renderAccountInput } from './account-input'
import { renderChoice } from './choice'
import { DeleteIcon } from './delete-icon'
import { Input } from './input'
import { type BaseRow, renderTable } from './table'
import { VatChoice } from './vat-choice'

export interface ItemTableRow<T extends ItemType> extends BaseRow {
    id: string
    itemType: T
    inputs: ItemInputs<T>
    totalWithoutVat: number
    payableWithoutVat: number
    payableWithVat: number
    vatPercentage: ExpenseVatPercentage
    vatAmount: number
    unitPrice?: number
    accountInputProps: AccountInputProps<T>
}

export interface ItemTableProps<T extends ItemType> {
    itemIds: string[]
    removeItem: (id: string) => void
    getItemInputs: (id: string) => ItemInputs<T>
    getItemType: (itemInputs: ItemInputs<T>) => T
    renderItemType: (itemType: T) => string
    getAccountInputProps: (id: string, itemInputs: ItemInputs<T>) => AccountInputProps<T>
    inputValues: InputValues
    editMode: boolean
    valErrors: ValidationError[] | undefined
    errorPrefix: string
    vatPayer: boolean
    hasDiscount: boolean
    calculationMode: CalculationMode
    isRevenue?: boolean
}

const getLeftAligned = () => ({ className: 'text-left' })
const getCenterAligned = () => ({ className: 'center' })
const getRightAligned = () => ({ className: 'text-right' })
const getRightAlignedNumeric = () => ({ className: 'text-right numeric' })

const renderReadOnlyAmount = (value: number): ReactNode => (
    <span className="read-only">{formatAmount(value)}</span>
)

export class ItemTable<T extends ItemType> extends Component<ItemTableProps<T>> {
    getRow(itemId: string): ItemTableRow<T> {
        const {
            getItemInputs,
            getItemType,
            getAccountInputProps,
            inputValues,
            calculationMode,
            vatPayer,
        } = this.props

        const itemInputs = getItemInputs(itemId)
        const itemTotals = calculateItemFromInputs(
            calculationMode,
            itemInputs,
            inputValues,
            vatPayer,
        )
        const { totalWithoutVat, payableWithoutVat, payableWithVat, vatAmount } = itemTotals
        const vatPercentage = vatPayer
            ? getExpenseVatInput(itemInputs.vatPercentage, inputValues)
            : null

        const row: ItemTableRow<T> = {
            id: itemId,
            inputs: itemInputs,
            itemType: getItemType(itemInputs),
            totalWithoutVat,
            payableWithoutVat,
            payableWithVat,
            vatPercentage,
            vatAmount,
            accountInputProps: getAccountInputProps(itemId, itemInputs),
        }

        if (calculationMode === calculationModes.manual) {
            row.unitPrice = calculateUnitPriceFromItemInputs(row.inputs, inputValues)
        }

        return row
    }

    getRows(): ItemTableRow<T>[] {
        return this.props.itemIds.map((itemId) => this.getRow(itemId))
    }

    getUnitPriceColumn(): Column<ItemTableRow<T>> {
        const { calculationMode, editMode, inputValues, valErrors, errorPrefix } = this.props

        let getHeaderProps = getRightAligned
        let getProps = getRightAlignedNumeric

        if (editMode && calculationMode === calculationModes.automatic) {
            getHeaderProps = getCenterAligned
            getProps = getCenterAligned
        }

        return {
            header: { content: t.unitPrice.get() + ' €', getProps: getHeaderProps },
            getProps,
            render: browserOnly((row) => {
                if (calculationMode === calculationModes.manual) {
                    return renderReadOnlyAmount(row.unitPrice!)
                } else {
                    return (
                        <div>
                            {renderInputOrValue(
                                editMode,
                                {
                                    input: row.inputs.unitPrice,
                                    inputValues,
                                    className: 'unit-price',
                                },
                                (value) => renderReadOnlyAmount(Number(value)),
                            )}
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.unitPrice')}
                        </div>
                    )
                }
            }),
        }
    }

    getPayableWithoutVatColumn(): Column<ItemTableRow<T>> {
        const { calculationMode, editMode, inputValues, valErrors, errorPrefix } = this.props

        let getHeaderProps = getRightAligned
        let getProps = getRightAlignedNumeric

        if (editMode && calculationMode === calculationModes.manual) {
            getHeaderProps = getCenterAligned
            getProps = getCenterAligned
        }

        return {
            header: { content: t.totalWithoutVat.short.get() + ' €', getProps: getHeaderProps },
            getProps,
            render: browserOnly((row) => {
                if (editMode && calculationMode === calculationModes.manual) {
                    return (
                        <div>
                            <Input
                                input={row.inputs.payableWithoutVat}
                                inputValues={inputValues}
                                className="payable-without-vat"
                            />
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.payableWithoutVat')}
                        </div>
                    )
                } else {
                    return renderReadOnlyAmount(row.payableWithoutVat)
                }
            }),
        }
    }

    getDiscountColumn(): Column<ItemTableRow<T>> {
        const { editMode, inputValues, valErrors, errorPrefix } = this.props

        return {
            header: { content: t.discount.short.get() + (editMode ? ' %' : '') },
            getProps: getCenterAligned,
            render: browserOnly((row) => (
                <div>
                    {renderInputOrValue(
                        editMode,
                        {
                            input: row.inputs.discount,
                            inputValues,
                            className: 'discount',
                            afterChange(value) {
                                // Remove extraneous leading zeroes
                                if (/^0[0-9]/.test(value)) {
                                    row.inputs.discount.set(value.substr(1))
                                }
                            },
                        },
                        // Not using formatAmount to get integers without ',00'
                        (value) => {
                            const unitPrice = parseFloat(row.inputs.unitPrice.get(inputValues))
                            const discountAmt = (parseFloat(value) / 100) * unitPrice
                            return '(' + toComma(value) + '%) ' + discountAmt + ' €'
                        },
                    )}
                    {valErr(valErrors, errorPrefix + '.' + row.id + '.discount')}
                </div>
            )),
        }
    }

    getPayableWithVatColumn(): Column<ItemTableRow<T>> {
        const { vatPayer, calculationMode, editMode, inputValues, valErrors, errorPrefix } =
            this.props

        return {
            header: { content: t.total.get() + ' €', getProps: getRightAligned },
            getProps: getRightAlignedNumeric,
            render: browserOnly((row) => {
                if (editMode && !vatPayer && calculationMode === calculationModes.manual) {
                    return (
                        <div>
                            <Input
                                input={row.inputs.payableWithoutVat}
                                inputValues={inputValues}
                                className="payable-without-vat"
                            />
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.payableWithoutVat')}
                        </div>
                    )
                } else {
                    return renderReadOnlyAmount(row.payableWithVat)
                }
            }),
        }
    }

    getColumns(rows: ItemTableRow<T>[]) {
        const {
            removeItem,
            renderItemType,
            inputValues,
            editMode,
            valErrors,
            errorPrefix,
            vatPayer,
            hasDiscount,
        } = this.props

        const unitOptions = unitsArray.map((unit) => ({
            id: unit,
            label: t.enums.units[unit].get(),
        }))

        const columns: Column<ItemTableRow<T>>[] = [
            {
                header: { content: t.type.get(), getProps: getLeftAligned },
                render: browserOnly((row) => {
                    return <span className="read-only">{renderItemType(row.itemType)}</span>
                }),
            },
            {
                header: { content: t.account.get(), getProps: getLeftAligned },
                render: browserOnly((row) => renderAccountInput(row.accountInputProps)),
            },
            {
                header: { content: t.description.get(), getProps: getLeftAligned },
                render: browserOnly((row) => (
                    <div>
                        {renderInputOrValue(editMode, {
                            input: row.inputs.description,
                            inputValues,
                            type: 'multiline',
                            autoResize: true,
                            focusEventId: 'item-description-' + row.id,
                        })}
                        {valErr(valErrors, errorPrefix + '.' + row.id + '.description')}
                    </div>
                )),
            },
        ]

        if (editMode) {
            // In edit mode, use separate columns for quantity and unit
            columns.push(
                {
                    header: { content: t.quantity.get() },
                    getProps: getCenterAligned,
                    render: browserOnly((row) => (
                        <div>
                            <Input
                                input={row.inputs.quantity}
                                inputValues={inputValues}
                                className="quantity"
                            />
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.quantity')}
                        </div>
                    )),
                },
                {
                    header: { content: t.unit.get() },
                    getProps: getCenterAligned,
                    render: browserOnly((row) => (
                        <div>
                            {renderChoice({
                                type: 'dropdown',
                                input: row.inputs.unit,
                                inputValues,
                                options: unitOptions,
                            })}
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.unit')}
                        </div>
                    )),
                },
            )
        } else {
            // In view mode, use a single column for both quantity and unit
            columns.push({
                header: { content: t.quantity.get() },
                getProps: getCenterAligned,
                render: browserOnly((row) => {
                    // Not using formatAmount to get integers without ',00'
                    const quantity = toComma(row.inputs.quantity.get(inputValues))
                    const unit = row.inputs.unit.get(inputValues)
                    return (
                        <div>
                            {quantity} {unit ? t.enums.units[unit].get() : ''}
                        </div>
                    )
                }),
            })
        }

        const unitPriceColumn = this.getUnitPriceColumn()
        columns.push(unitPriceColumn)

        if (vatPayer) {
            const payableWithoutVatColumn = this.getPayableWithoutVatColumn()
            columns.push(payableWithoutVatColumn)
        }

        if (hasDiscount) {
            const discountColumn = this.getDiscountColumn()

            if (editMode) {
                columns.push(discountColumn)
            } else {
                const isDiscountUsed = rows.some((row) => {
                    if (row.inputs) {
                        return row.inputs.discount.get(inputValues) !== '0'
                    } else {
                        return false
                    }
                })

                if (isDiscountUsed) {
                    columns.push(discountColumn)
                }
            }
        }

        if (vatPayer) {
            if (editMode) {
                columns.push({
                    header: { content: t.vat.short.get() },
                    getProps: getCenterAligned,
                    render: browserOnly((row) => (
                        <div>
                            <VatChoice
                                editMode={true}
                                input={row.inputs.vatPercentage}
                                inputValues={inputValues}
                                shouldIncludeNone={!this.props.isRevenue}
                            />
                            {valErr(valErrors, errorPrefix + '.' + row.id + '.vatPercentage')}
                        </div>
                    )),
                })
            } else {
                columns.push({
                    header: { content: t.vat.short.get() + ' €', getProps: getRightAligned },
                    getProps: getRightAlignedNumeric,
                    render: browserOnly((row) => {
                        if (row.vatPercentage === null) {
                            return t.none.get()
                        }

                        return (
                            <span className="read-only">
                                ({row.vatPercentage}
                                {'%) '}
                                {formatAmount(row.vatAmount)}
                            </span>
                        )
                    }),
                })
            }
        }

        const payablewithVatColumn = this.getPayableWithVatColumn()
        columns.push(payablewithVatColumn)

        if (editMode && rows.length) {
            columns.push({
                getProps: () => ({ className: 'text-center' }),
                render: browserOnly((row) => <DeleteIcon onClick={() => removeItem(row.id)} />),
            })
        }

        return columns
    }

    override render() {
        const { valErrors, errorPrefix } = this.props
        const rows = this.getRows()

        return (
            <div>
                {renderTable({
                    columns: this.getColumns(rows),
                    rows,
                    domId: 'items',
                    tableClassName: 'table table--bottom-border align-top',
                    wrapperClassName: 'item-table-wrapper',
                    stickyHeader: true,
                })}
                {valErr(valErrors, errorPrefix, { empty: t.validation['no-rows'].get() })}
            </div>
        )
    }
}
