import React, { ReactNode } from 'react'

import { t as baseT, Language, Translation } from '../common/i18n'

export interface TranslationWithDefault extends Translation {
    getFormat(): string
    get(...args: Array<string | number>): string
}

type ObjWithDefault<T> = { [K in keyof T]: WithDefault<T[K]> }

type WithDefault<T> = T extends Translation
    ? TranslationWithDefault & (Translation extends T ? unknown : ObjWithDefault<T>)
    : ObjWithDefault<T>

let currentLanguage: Language | null = null

export const setCurrentLanguage = (lang: Language) => {
    currentLanguage = lang
}

export const getCurrentLanguage = (): Language => {
    if (currentLanguage) {
        return currentLanguage
    } else {
        throw new Error('Current language not set')
    }
}

const addDefault = <T,>(value: T): WithDefault<T> => {
    if (typeof value !== 'object') {
        throw new Error('Expected an object')
    }

    if (Array.isArray(value)) {
        return value.map(addDefault) as WithDefault<T>
    }

    const withDefault: any = { ...value }
    const hasGetFormat = 'getFormatForLanguage' in withDefault
    const hasGet = 'getForLanguage' in withDefault

    if (hasGetFormat !== hasGet) {
        throw new Error(
            'A translation must have both "getForLanguage" and "getFormatForLanguage" methods or neither',
        )
    }

    for (const key of Object.keys(withDefault)) {
        if (key !== 'getFormatForLanguage' && key !== 'getForLanguage') {
            withDefault[key] = addDefault(withDefault[key])
        }
    }

    if (hasGet) {
        if (typeof withDefault.getFormatForLanguage !== 'function') {
            throw new Error('getFormatForLanguage on a translation must be a function')
        }

        if (typeof withDefault.getForLanguage !== 'function') {
            throw new Error('getForLanguage on a translation must be a function')
        }

        if (withDefault.getFormat || withDefault.get) {
            throw new Error('A base translation should not have members named "getFormat" or "get"')
        }

        const translation = withDefault as TranslationWithDefault
        translation.getFormat = () => translation.getFormatForLanguage(getCurrentLanguage())

        translation.get = (...args: Array<string | number>) => {
            return translation.getForLanguage(getCurrentLanguage(), ...args)
        }
    }

    return withDefault
}

export const t: WithDefault<typeof baseT> = addDefault(baseT)

// Similar to the formatting in i18n.t, but accepts React renderable nodes
// as args instead of just strings and returns a span element instead of a string.
// The format may only contain '{}' placeholders.
// Placeholders with indexes, fieldnames, etc are not supported (yet).
export const withElements = (
    translation: TranslationWithDefault,
    ...args: ReactNode[]
): ReactNode => {
    const format = translation.getFormat()
    const parts = format.split('{}')
    const expectedCount = parts.length - 1

    if (args.length !== expectedCount) {
        throw new Error('Expected ' + expectedCount + ' arguments, but got ' + args.length)
    }

    const result = []

    for (let i = 0; i < args.length; i += 1) {
        result.push(parts[i], args[i])
    }

    result.push(parts[expectedCount])
    return <span>{result}</span>
}
