import React from 'react'

import { Input, InputValues } from '../../common/types/inputs'
import { t } from '../i18n'
import { renderChoice } from './choice'

interface Props {
    editMode: boolean
    input: Input<string>
    inputValues: InputValues
    shouldIncludeNone?: boolean
}

const getOptions = (shouldIncludeNone?: boolean) => {
    const options = [
        { id: '22', label: '22%' },
        { id: '20', label: '20%' },
        { id: '9', label: '9%' },
        { id: '0', label: '0%' },
    ]

    if (shouldIncludeNone) {
        options.push({ id: 'null', label: t.none.get() })
    }

    return options
}

export const VatChoice = ({ editMode, input, inputValues, shouldIncludeNone }: Props) => {
    if (!editMode) {
        const strVatPercentage = input.get(inputValues)
        return <>{strVatPercentage === 'null' ? t.none.get() : strVatPercentage + '%'}</>
    }

    return renderChoice({
        type: 'dropdown',
        input,
        inputValues,
        options: getOptions(shouldIncludeNone),
        forceSelection: true,
    })
}
