import React from 'react'

import { ChoiceOption, Input, InputValues } from '../../common/types/inputs'
import { t } from '../i18n'
import { renderChoice } from './choice'

export interface SortOptionsProps<T extends string> {
    input: Input<T>
    inputValues: InputValues
    options: ChoiceOption<T>[]
}

const SortOptions = <T extends string>({ input, inputValues, options }: SortOptionsProps<T>) => {
    return (
        <div className="sort-options">
            {t.sortBy[0].get()}{' '}
            {renderChoice<T>({
                input,
                inputValues,
                type: 'buttons',
                options,
                forceSelection: true,
                buttonClassName: 'button--secondary',
                selectedButtonClassName: 'button--secondary-selected',
            })}{' '}
            {t.sortBy[1].get()}
        </div>
    )
}

export const renderSortOptions = <T extends string>(props: SortOptionsProps<T>) => (
    <SortOptions {...props} />
)
