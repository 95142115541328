import React, { FC, Fragment, ImgHTMLAttributes } from 'react'

import { Button, ButtonProps } from '../button'
import { LoadingIcon } from '../loading-icon'

export interface RevenuePreviewProps {
    isReady: boolean
    buttons: Omit<ButtonProps, 'className'>[]
    languageButtons: ImgHTMLAttributes<HTMLImageElement>[]
    url: string
}

const renderMain = (isReady: boolean, url: string) => {
    if (isReady) {
        return <object className="revenue-preview__pdf" data={url} type="application/pdf" />
    } else {
        return <LoadingIcon color="white" />
    }
}

export const RevenuePreview: FC<RevenuePreviewProps> = (props) => {
    const { isReady, buttons, languageButtons, url } = props

    return (
        <div className="revenue-preview">
            <div className="revenue-preview__buttons">
                {buttons.map((button, index) => (
                    <Fragment key={index}>
                        <Button
                            {...button}
                            className="revenue-preview__button button--transparent"
                        />{' '}
                    </Fragment>
                ))}
                {languageButtons.map((button, index) => (
                    <Fragment key={index}>
                        <img {...button} className="revenue-preview__flag" />{' '}
                    </Fragment>
                ))}
            </div>
            {renderMain(isReady, url)}
        </div>
    )
}
