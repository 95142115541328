import React, { FC } from 'react'

import { calculationModes } from '../../../common/enums'
import { CalculationMode } from '../../../common/types/enums'
import {
    AutomaticTotalsMismatch,
    TotalsMismatch as TTotalsMismatch,
} from '../../../common/types/expense'
import { t } from '../../i18n'
import { renderAmount } from '../../render-amount'

interface Props {
    vatPayer: boolean
    mismatch: TTotalsMismatch | null
    calculationMode: CalculationMode
}

const isAutomaticMismatch = (
    _mismatch: TTotalsMismatch,
    calculationMode: CalculationMode,
): _mismatch is AutomaticTotalsMismatch => calculationMode === calculationModes.automatic

const getAutomaticTotalsMismatchText = (vatPayer: boolean, vat: number, total: number) => {
    if (isNaN(vat) || isNaN(total)) {
        return t.expenses.totals.mismatch.nan.get()
    } else if (vatPayer) {
        return t.expenses.totals.mismatch.automatic.vatPayer.get(
            renderAmount(vat),
            renderAmount(total),
        )
    } else {
        return t.expenses.totals.mismatch.automatic.nonVatPayer.get(renderAmount(total))
    }
}

const getManualTotalsMismatchText = (vatPayer: boolean, payableWithoutVat: number) => {
    if (isNaN(payableWithoutVat)) {
        return t.expenses.totals.mismatch.nan.get()
    } else {
        const amount = renderAmount(payableWithoutVat)

        if (vatPayer) {
            return t.expenses.totals.mismatch.manual.vatPayer.get(amount)
        } else {
            return t.expenses.totals.mismatch.manual.nonVatPayer.get(amount)
        }
    }
}

const getTotalsMismatchText = (
    vatPayer: boolean,
    mismatch: TTotalsMismatch,
    calculationMode: CalculationMode,
) => {
    if (isAutomaticMismatch(mismatch, calculationMode)) {
        return getAutomaticTotalsMismatchText(vatPayer, mismatch.vat, mismatch.total)
    } else {
        return getManualTotalsMismatchText(vatPayer, mismatch.payableWithoutVat)
    }
}

export const TotalsMismatch: FC<Props> = ({ vatPayer, mismatch, calculationMode }) => {
    if (mismatch) {
        return (
            <div className="text-warning text-multiline bottom-margin">
                {getTotalsMismatchText(vatPayer, mismatch, calculationMode)}
            </div>
        )
    } else {
        return null
    }
}
