import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

interface Props {
    className?: string
    sidebar?: ReactNode
    title: string
    subtitle?: ReactNode
    sortOptions?: ReactNode
    buttons?: ReactNode
    table: ReactNode
}

export const StandardPage: FC<Props> = (props) => {
    const { className, sidebar, title, subtitle, sortOptions, buttons, table } = props

    return (
        <div key="main" className={classnames('content-area', className)}>
            {sidebar}
            <div className="content">
                <h1 className="title">
                    {title}
                    {
                        // TODO omit elem if empty
                        <span className="title__sub-title">{subtitle}</span>
                    }
                </h1>
                <div className="flex space-between flex-end">
                    {
                        // Always render at least something so the flexbox would have 2 children
                        sortOptions || <span />
                    }
                    <div className="standard-buttons flex-vertical flex-end">{buttons}</div>
                </div>
                {table}
            </div>
        </div>
    )
}
