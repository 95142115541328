import React, { FC } from 'react'

import { SESSION_RENEW_MINUTES } from '../../common/constants'
import { Time } from '../../common/time'
import { Props } from '../../common/types/banner'
import { emitCloseFakeBanner, emitCloseSessionExpiringBanner } from '../event-bus'
import { t } from '../i18n'
import { renew } from '../state/session-actions'
import { Button } from './button'

const renderRenewalLine = (renewable: boolean) =>
    renewable ? t.session.canRenew.get(SESSION_RENEW_MINUTES) : t.session.canNotRenew.get()

const renderButtons = (renewable: boolean) => {
    if (renewable) {
        const renewButton = (
            <Button
                text={t.session.renew.get()}
                onClick={renew}
                domId="renew"
                className="button--white"
            />
        )

        const dontRenewButton = (
            <Button
                text={t.session.dontRenew.get()}
                onClick={emitCloseSessionExpiringBanner}
                domId="dont-renew"
                className="button--white"
            />
        )

        return (
            <div className="banner__button-section">
                {renewButton} {dontRenewButton}
            </div>
        )
    } else {
        const okButton = (
            <Button
                text="OK"
                onClick={emitCloseSessionExpiringBanner}
                domId="ok"
                className="button--white"
            />
        )

        return <div className="banner__button-section">{okButton}</div>
    }
}

const renderSessionExpiringBanner = (
    sessionExpirationVisible: boolean,
    expires: Time | null,
    renewable: boolean,
) => {
    if (sessionExpirationVisible) {
        return (
            <div className="banner">
                <div className="banner__session-expires">
                    {t.session.expiresAt.get(expires!.asLocal().format('HH:mm'))}
                </div>
                <div>
                    {t.session.needLogin.get()} {renderRenewalLine(renewable)}
                </div>
                {renderButtons(renewable)}
            </div>
        )
    } else {
        return null
    }
}

const renderVersionMismatchBanner = (versionMismatch: boolean) => {
    if (versionMismatch) {
        return <div className="banner">{t.versionMismatch.get()}</div>
    } else {
        return null
    }
}

const renderFakeBanner = (visible: boolean) => {
    if (visible) {
        return (
            <div className="banner">
                <div className="banner__session-expires">{t.session.expiresAt.get('XX:XX')}</div>
                <div>
                    {t.session.needLogin.get()} {renderRenewalLine(false)}
                </div>
                <div className="banner__button-section">
                    <Button
                        text="OK"
                        onClick={emitCloseFakeBanner}
                        domId="ok"
                        className="button--white"
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
}

export const Banners: FC<Props> = (props) => {
    const { sessionExpirationVisible, expires, renewable, versionMismatch, fakeVisible } = props

    return (
        <div>
            {renderSessionExpiringBanner(sessionExpirationVisible, expires, renewable)}
            {renderVersionMismatchBanner(versionMismatch)}
            {renderFakeBanner(fakeVisible)}
        </div>
    )
}
