import React, { FC } from 'react'

import { InitDateType, initDateTypes } from '../../../common/enums'
import { ApiCompany } from '../../../common/types/company'
import { ChoiceOption } from '../../../common/types/inputs'
import { t, withElements } from '../../i18n'
import { inputs } from '../../inputs'
import {
    getCompany,
    getInitDate,
    SAVE_DATE_PROCESS,
    updateInitDate,
} from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

const dateTypeInput = inputs.initCompany.dateType

const getOptions = (company: ApiCompany) =>
    initDateTypes.map((type): ChoiceOption<InitDateType> => {
        const date = getInitDate(type, company)
        const label = t.initCompany.date.options[type].get() + ': ' + date.longDate()
        const disabled = date.ymd() <= company.registrationDate
        return { id: type, label, disabled }
    })

const renderContent = (rootData: RootData) => {
    const { companyData, inputValues, processes, session } = rootData

    if (!companyData.companies) {
        return <LoadingPage />
    }

    const company = getCompany(companyData, session)
    const dateType = dateTypeInput.get(inputValues)
    const date = getInitDate(dateType, company)
    const previousDay = date.addDays(-1)
    const options = getOptions(company)

    return (
        <div className="init-company__date-page">
            <div>{t.initCompany.date.choose.get()}</div>
            {renderChoice({
                type: 'buttons',
                input: dateTypeInput,
                inputValues,
                options,
                groupClassName: 'vertical init-company__date-type-buttons',
                forceSelection: true,
                buttonClassName:
                    'init-company__date-type-button button--transparent button--numeric',
                selectedButtonClassName:
                    'init-company__date-type-button button--transparent-selected button--numeric',
            })}
            <div className="text-multiline">
                {withElements(
                    t.initCompany.date.info,
                    <span className="init-company__text init-company__text--bold">
                        {previousDay.longDate()}
                    </span>,
                )}
            </div>
            <div className="init-company__main-inner init-company__main-inner--bottom">
                <Button
                    text="Edasi"
                    onClick={updateInitDate}
                    processes={processes}
                    processName={SAVE_DATE_PROCESS}
                    className="button--white init-company__button init-company__button--wide"
                    loadingColor="white"
                />
            </div>
        </div>
    )
}

export const InitCompanyDate: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    if (!companyData.companies) {
        return <LoadingPage />
    }

    const company = getCompany(companyData, session)

    return (
        <div className="init-company">
            <InitCompanyNav activeItem="date" company={company} />
            <div className="init-company__main">{renderContent(rootData)}</div>
        </div>
    )
}
