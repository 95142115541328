import React, { ReactNode } from 'react'

export const renderJson = (value: any, key: string = 'root'): ReactNode[] => {
    if (Array.isArray(value)) {
        return [
            <span key={key + '.type'} className="json-literal">
                arr
            </span>,
            <div key={key + '.elems'} className="indented">
                {value.reduce(arrayReducer, [])}
            </div>,
        ]
    } else if (value && typeof value === 'object') {
        const obj = value as Record<string, unknown>

        const lines = Object.keys(value).map((propKey) => {
            const shownKey = propKey === '' ? "''" : propKey

            return (
                <div key={propKey}>
                    <span className="json-attr">{shownKey}</span>{' '}
                    {renderJson(obj[propKey], propKey)}
                </div>
            )
        })

        return [
            <span key={key + '.type'} className="json-literal">
                obj
            </span>,
            <div key={key + '.props'} className="indented">
                {lines}
            </div>,
        ]
    } else if (typeof value === 'string') {
        if (value === '') {
            return [
                <span key={key} className="json-literal">
                    ''
                </span>,
            ]
        } else {
            return [
                <span key={key} className="json-string">
                    {value}
                </span>,
            ]
        }
    } else if (
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        typeof value === 'undefined' ||
        value === null
    ) {
        return [
            <span key={key} className="json-literal">
                {String(value)}
            </span>,
        ]
    } else {
        throw new Error('Unexpected value: ' + String(value))
    }
}

const arrayReducer = (previous: any[], current: any, index: number): any[] => {
    const key = String(index)
    return previous.concat(<div key={key}>{renderJson(current, key)}</div>)
}
