import classnames from 'classnames'
import React, { FC } from 'react'

import { MIN_PASSWORD_LENGTH } from '../../common/constants'
import { InputValues } from '../../common/types/inputs'
import { Terms as TTerms } from '../../common/types/terms'
import { t, withElements } from '../i18n'
import { inputs } from '../inputs'
import { RootData } from '../state/root-data'
import { signUp, SIGNUP_PROCESS, toggleTerms } from '../state/signup-actions'
import { valErr } from '../val-err'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { Input } from './input'
import { Terms } from './terms'

const renderTerms = (inputValues: InputValues, terms: TTerms | null) => {
    const isVisible = inputs.signup.showTerms.get(inputValues)

    if (!isVisible) {
        return null
    }

    return (
        <div className="signup__section">
            <Terms terms={terms} />
        </div>
    )
}

export const SignUp: FC<RootData> = ({ inputValues, processes, terms, validationErrors }) => {
    const password = inputs.signup.password.get(inputValues)
    const repeatPassword = inputs.signup.repeatPassword.get(inputValues)
    const passwordsMatch = password === repeatPassword

    const agreed = inputs.signup.agree.get(inputValues)

    const valErrors = validationErrors[SIGNUP_PROCESS]

    return (
        <div className="signup">
            <div className="signup__input-row">
                <div className="signup__input-row-element">
                    <Input
                        input={inputs.signup.firstName}
                        inputValues={inputValues}
                        placeholder={t.firstName.get()}
                        className="signup__input"
                    />
                    {valErr(valErrors, 'user.firstName')}
                </div>
                <div className="signup__input-row-element">
                    <Input
                        input={inputs.signup.lastName}
                        inputValues={inputValues}
                        placeholder={t.lastName.get()}
                        className="signup__input"
                    />
                    {valErr(valErrors, 'user.lastName')}
                </div>
            </div>
            <div className="signup__input-row">
                <div className="signup__input-row-element">
                    <Input
                        input={inputs.signup.email}
                        inputValues={inputValues}
                        placeholder={t.email.get()}
                        className="signup__input"
                    />
                    {valErr(valErrors, 'user.email', { unique: t.signup.duplicateEmail.get() })}
                </div>
            </div>
            <div className="signup__input-row">
                <div className="signup__input-row-element">
                    <Input
                        input={inputs.signup.password}
                        inputValues={inputValues}
                        type="password"
                        placeholder={t.password.get()}
                        className="signup__input"
                    />
                    {valErr(valErrors, 'user.password', {
                        'too-short': t.signup.passwordTooShort.get(MIN_PASSWORD_LENGTH),
                    })}
                </div>
                <div className="signup__input-row-element">
                    <Input
                        input={inputs.signup.repeatPassword}
                        inputValues={inputValues}
                        type="password"
                        placeholder={t.password.repeat.get()}
                        className={classnames('signup__input', {
                            'signup__input--invalid': !passwordsMatch,
                        })}
                    />
                </div>
            </div>
            <div className="signup__password-recommendation">{t.password.recommendation.get()}</div>
            {renderTerms(inputValues, terms)}
            <div className="signup__section">
                <Checkbox input={inputs.signup.agree} inputValues={inputValues} />{' '}
                {withElements(
                    t.terms.agree,
                    <a onClick={toggleTerms} className="signup__link">
                        {t.terms.with.get()}
                    </a>,
                )}
            </div>
            <div className="signup__section">
                <Button
                    text={t.signup.get()}
                    disabled={!passwordsMatch || !agreed}
                    onClick={signUp}
                    processes={processes}
                    processName={SIGNUP_PROCESS}
                    className="signup__main-button"
                    loadingColor="white"
                />
            </div>
        </div>
    )
}
