import React from 'react'

import { reportModes } from '../../../common/enums'
import { ReportMode } from '../../../common/types/enums'
import { Input, InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'

interface Props {
    input: Input<ReportMode>
    inputValues: InputValues
}

export const ReportModeButton = ({ input, inputValues }: Props) => {
    const mode = input.get(inputValues)
    const otherMode = mode === reportModes.short ? reportModes.long : reportModes.short

    return (
        <button
            className="button button--wide button--primary"
            onClick={() => input.set(otherMode)}
        >
            {t.reports.mode[otherMode].get()}
        </button>
    )
}
