import classnames from 'classnames'
import React, { FC } from 'react'

export interface ExpandToggleProps {
    expanded: boolean
}

export const ExpandToggle: FC<ExpandToggleProps> = ({ expanded }) => (
    <img
        src="/icons/triangle.svg"
        className={classnames('expand-collapse', { 'expand-collapse--expand': !expanded })}
    />
)
