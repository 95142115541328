import React, { FC } from 'react'

import { assetTypes, expenseItemTypes, expenseTypes } from '../../../common/enums'
import { ExpenseType } from '../../../common/types/enums'
import { InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { addAsset, addItem } from '../../state/expense-actions'
import { Button } from '../button'
import { CharactersLeft } from '../characters-left'

interface Props {
    editMode: boolean
    inputValues: InputValues
    type: ExpenseType
}

const addGoodsExpense = async () => addItem(expenseItemTypes.goodsExpense)
const addGoodsStock = async () => addItem(expenseItemTypes.goodsStock)
const addGeneral = async () => addItem(expenseItemTypes.general)

const addLand = async () => addAsset(assetTypes.land)
const addBuilding = async () => addAsset(assetTypes.building)
const addDevice = async () => addAsset(assetTypes.device)
const addOtherMaterial = async () => addAsset(assetTypes.otherMaterial)
const addSoftware = async () => addAsset(assetTypes.software)
const addLicense = async () => addAsset(assetTypes.license)
const addOtherImmaterial = async () => addAsset(assetTypes.otherImmaterial)

const renderAddItemButtons = (editMode: boolean, type: ExpenseType) => {
    if (!editMode || type !== expenseTypes.regular) {
        return null
    }

    return (
        <div className="expense__add-buttons">
            <Button
                text={t.expenses.add.goods.expense.get()}
                onClick={addGoodsExpense}
                className="button--secondary"
            />{' '}
            <Button
                text={t.expenses.add.goods.stock.get()}
                onClick={addGoodsStock}
                className="button--secondary"
            />{' '}
            <Button
                text={t.expenses.add.general.get()}
                onClick={addGeneral}
                className="button--secondary"
            />
        </div>
    )
}

const renderAddAssetButtons = (editMode: boolean, type: ExpenseType) => {
    if (!editMode || type !== expenseTypes.asset) {
        return null
    }

    return (
        <div className="expense__add-buttons">
            <div className="expense__add-button-line">
                <Button
                    text={t.assets.add.land.get()}
                    onClick={addLand}
                    className="button--secondary"
                />{' '}
                <Button
                    text={t.assets.add.building.get()}
                    onClick={addBuilding}
                    className="button--secondary"
                />{' '}
                <Button
                    text={t.assets.add.device.get()}
                    onClick={addDevice}
                    className="button--secondary"
                />{' '}
                <Button
                    text={t.assets.add.otherMaterial.get()}
                    onClick={addOtherMaterial}
                    className="button--secondary"
                />
            </div>
            <div className="expense__add-button-line">
                <Button
                    text={t.assets.add.software.get()}
                    onClick={addSoftware}
                    className="button--secondary"
                />{' '}
                <Button
                    text={t.assets.add.license.get()}
                    onClick={addLicense}
                    className="button--secondary"
                />{' '}
                <Button
                    text={t.assets.add.otherImmaterial.get()}
                    onClick={addOtherImmaterial}
                    className="button--secondary"
                />
            </div>
        </div>
    )
}

const renderComment = (editMode: boolean, inputValues: InputValues) => (
    <div className="expense__comment-area">
        {renderInputOrValue(editMode, {
            type: 'multiline',
            input: inputs.expense.comment,
            inputValues,
            placeholder: t.expenses.comment.input.get(),
            className: 'expense__comment',
            disabled: !editMode,
            maxLength: 2000,
        })}
        {editMode && (
            <CharactersLeft current={inputs.expense.comment.get(inputValues).length} max={2000} />
        )}
    </div>
)

export const ExpenseBottomLeft: FC<Props> = ({ editMode, inputValues, type }) => (
    <div>
        {renderAddItemButtons(editMode, type)}
        {renderAddAssetButtons(editMode, type)}
        {renderComment(editMode, inputValues)}
    </div>
)
