import React, { FC } from 'react'

import { getExcelButtonProps } from '../../excel-utils'
import { ExcelSpec } from '../../excel/types'
import { getColumns, getRows, renderFilterSummary, Row } from '../../expense-archive-utils'
import { t } from '../../i18n'
import { RootData } from '../../state/root-data'
import { ExcelButton } from '../excel-button'
import { LoadingPage } from '../loading-page'
import { renderTable } from '../table'
import { ExpenseArchiveSidebar } from './archive-sidebar'

export const ExpenseArchiveResults: FC<RootData> = (rootData) => {
    const {
        expenseData: { expenses },
        inputValues,
        processes,
        progress,
    } = rootData

    if (expenses) {
        const columns = getColumns(inputValues)
        const rows = getRows(expenses, inputValues)
        const excelSpec: ExcelSpec<Row> = {
            columns,
            rows,
            outputName: t.expenses.get().toLowerCase(),
        }

        const excelButton = rows.length
            ? getExcelButtonProps(
                  excelSpec,
                  t.expenses.processed.get(),
                  processes,
                  progress,
                  'button--primary',
              )
            : undefined

        return (
            <div className="content-area">
                <ExpenseArchiveSidebar />
                <div className="content archive">
                    <div>
                        <h1 className="title archive__title">{t.archive.searchResults.get()}</h1>
                        {renderFilterSummary(inputValues)}
                        <div className="top-margin">
                            {excelButton && <ExcelButton {...excelButton} />}
                        </div>
                        {renderTable({
                            columns,
                            rows,
                            stickyHeader: true,
                            wrapperClassName: 'top-margin',
                            tableClassName: 'table table--bottom-border archive__result-table',
                        })}
                    </div>
                    <div className="top-margin">
                        <a className="button button--primary" href="#/expenses/archive/general">
                            {t.backToSearch.get()}
                        </a>
                    </div>
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }
}
