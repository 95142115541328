import React from 'react'

const bankNames = ['Bigbank', 'Coop Pank', 'Inbank', 'LHV', 'Luminor', 'SEB', 'Swedbank']

export const BanksList = () => (
    <datalist id="bank-names">
        {bankNames.map((name) => (
            <option key={name} value={name} />
        ))}
    </datalist>
)
