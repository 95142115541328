import React, { FC } from 'react'

import { InputValues } from '../../../common/types/inputs'
import { getTypeOptions, renderClearFiltersButton } from '../../expense-archive-utils'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { RootData } from '../../state/root-data'
import { renderChoice } from '../choice'
import { ExpenseArchiveSidebar } from './archive-sidebar'

const archiveInputs = inputs.expense.archive

const renderTypeChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.type,
        inputValues,
        options: getTypeOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

export const ExpenseArchiveTypes: FC<RootData> = ({ inputValues }) => (
    <div className="content-area">
        <ExpenseArchiveSidebar selected="types" />
        <div className="content archive">
            <div>
                <h1 className="title archive__title">{t.expenses.type.get()}</h1>
                {renderTypeChoice(inputValues)}
            </div>
            <div className="top-margin">
                <a className="button button--primary" href="#/expenses/archive/results">
                    {t.show.get()}
                </a>{' '}
                {renderClearFiltersButton('types', inputValues)}
            </div>
        </div>
    </div>
)
