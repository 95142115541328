import React from 'react'

import { t } from '../i18n'

export interface NoDataProps {
    addRoute: string
    addButtonText: string
}

export const NoData = ({ addRoute, addButtonText }: NoDataProps) => (
    <div className="no-data">
        <div className="no-data__text">{t.noData.get()}</div>
        <div>
            <a href={addRoute} className="button no-data__button">
                {addButtonText}
            </a>
        </div>
    </div>
)
