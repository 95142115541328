import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { t } from '../../i18n'
import { InputOrValue, InputOrValueProps } from '../../input-utils'
import { renderValidationErrors } from '../../val-err'
import { DateInput, DateInputProps } from '../date-input'
import { ValidationErrorProps } from '../validation-error'

export interface RevenueGeneralProps {
    number: string
    dateInput?: DateInputProps
    date: string
    dateErrors?: ValidationErrorProps[]
    term: InputOrValueProps
    termErrors?: ValidationErrorProps[]
    dueDate?: Day
}

export const RevenueGeneral: FC<RevenueGeneralProps> = (general) => (
    <table className="table">
        <tbody>
            <tr>
                <td className="table__body-cell table__body-cell--no-left-pad">
                    {t.invoices.number.get()}
                </td>
                <td className="numeric">{general.number}</td>
            </tr>
            <tr>
                <td className="table__body-cell table__body-cell--no-left-pad">
                    {t.invoices.date.get()}
                </td>
                <td>
                    {general.dateInput ? (
                        <DateInput
                            {...general.dateInput}
                            className="date-button invoice__date-button"
                        />
                    ) : (
                        Day.fromYmd(general.date).longDate()
                    )}
                    {renderValidationErrors(general.dateErrors)}
                </td>
            </tr>
            <tr>
                <td className="table__body-cell table__body-cell--no-left-pad">{t.term.get()}</td>
                <td>
                    <InputOrValue {...general.term} /> {t.days.get()}
                    {renderValidationErrors(general.termErrors)}
                </td>
            </tr>
            <tr>
                <td className="table__body-cell table__body-cell--no-left-pad">
                    {t.dueDate.get()}
                </td>
                <td className="numeric">{general.dueDate ? general.dueDate.longDate() : '-'}</td>
            </tr>
        </tbody>
    </table>
)
