import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { SAVE_VAT_PROCESS, saveVat } from '../../state/settings-actions'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { VatChoice } from '../vat-choice'
import { InputRow } from './input-row'

const renderBackLink = (editMode: boolean) => (editMode ? <BackLink /> : null)

const renderVatPayerStatus = (
    editMode: boolean,
    company: ApiCompany,
    inputValues: InputValues,
    valErrors?: ValidationError[],
) => {
    const { vatPeriods } = company

    if (vatPeriods.length) {
        const startDate = Day.fromYmd(vatPeriods[0].from)
        const { vatId, vatPercentage } = inputs.settings.vat

        return (
            <div>
                <div className="text-bold">{t.companyIsVatPayer.since.get(startDate.dmy())}</div>
                <InputRow
                    editMode={editMode}
                    label={t.business.vatId.get()}
                    input={vatId}
                    inputValues={inputValues}
                />
                {valErr(valErrors, 'update.vatId')}
                <div className="settings__input-row">
                    <span className="settings__label">
                        {t.vat.get()}
                        {': '}
                    </span>
                    <VatChoice
                        editMode={editMode}
                        input={vatPercentage}
                        inputValues={inputValues}
                    />
                </div>
                {valErr(valErrors, 'update.vatPercentage')}
            </div>
        )
    } else {
        return <div>{t.companyIsNotVatPayer.get()}</div>
    }
}

const renderButton = (vatPayer: boolean, editMode: boolean, processes: Processes) => {
    if (!vatPayer) {
        return null
    }

    if (editMode) {
        return (
            <Button
                className="button--primary"
                text={t.save.get()}
                onClick={saveVat}
                processes={processes}
                processName={SAVE_VAT_PROCESS}
            />
        )
    } else {
        return (
            <a href="#/settings/vat/edit" className="button button--primary">
                {t.edit.get()}
            </a>
        )
    }
}

export const VatSettings: FC<RootData> = (rootData) => {
    const { companyData, inputValues, processes, session, settingsData, validationErrors, view } =
        rootData

    if (!companyData.companies || !settingsData.settings) {
        return <LoadingIcon color="black" />
    } else {
        const { pageParams } = assertViewName(view, 'Settings')
        const editMode = pageParams[0] === 'edit'
        const company = getCompany(companyData, session)
        const valErrors = validationErrors[SAVE_VAT_PROCESS]

        return (
            <>
                <div>
                    {renderBackLink(editMode)}
                    <h1 className="title">{t.vat.get()}</h1>
                    {renderVatPayerStatus(editMode, company, inputValues, valErrors)}
                </div>
                <div>{renderButton(company.vatPeriods.length > 0, editMode, processes)}</div>
            </>
        )
    }
}
