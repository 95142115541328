import classnames from 'classnames'
import React from 'react'

import { t } from '../../i18n'
import { Link } from '../link'

type Page = 'general' | 'companies'

interface Props {
    selected: Page
}

const pages: Page[] = ['general', 'companies']

const renderLink = (page: Page, isSelected: boolean) => (
    <Link
        key={page}
        to={'#/user-settings/' + page}
        text={t.userSettings.menu[page].get()}
        className={classnames('user-settings__menu-link', {
            'user-settings__menu-link--selected': isSelected,
        })}
    />
)

export const UserSettingsMenu = ({ selected }: Props) => (
    <div className="user-settings__menu">
        {pages.map((page) => renderLink(page, page === selected))}
    </div>
)
