import { Set as ImmutableSet } from 'immutable'
import React, { FC } from 'react'

import {
    canCreateEntry,
    canUseTestUtils,
    canViewAdminSection,
    canViewAllExpenses,
    canViewAllRevenues,
    canViewLogs,
    canViewReports,
    canViewSettings,
    canViewTaxes,
} from '../../common/access'
import { CompanyRole, CompanyStatus } from '../../common/enums'
import { ApiCompany } from '../../common/types/company'
import { MenuSection, MenuState } from '../../common/types/menu'
import { ApiSession } from '../../common/types/session'
import { t } from '../i18n'
import { toggleMenu, toggleSection } from '../state/menu-actions'
import { Link } from './link'
import { LoadingIcon } from './loading-icon'
import { MenuToggle } from './menu-toggle'

interface Props {
    session: ApiSession
    company?: ApiCompany
    menuState: MenuState
}

const renderLink = (route: string, text: string) => (
    <div>
        <Link to={'#' + route} text={text} className="menu__link" />
    </div>
)

const renderLinkIf = (visible: boolean, route: string, text: string) =>
    visible ? renderLink(route, text) : null

const renderSectionLink = (text: string, section: MenuSection) => (
    <a onClick={() => toggleSection(section)} className="menu__link">
        <img src={'/menu/' + section + '.svg'} className="menu__icon" />
        <span className="menu__item-text">{text}</span>
    </a>
)

const renderTaxesLink = (
    companyRole: CompanyRole | null,
    companyStatus: CompanyStatus,
    anyVatPeriods: boolean,
) => {
    if (anyVatPeriods && canViewTaxes(companyRole, companyStatus)) {
        return (
            <h1 className="menu__item">
                <a href="#/taxes/summary" className="menu__link">
                    <img src="/menu/taxes.svg" className="menu__icon" />
                    <span className="menu__item-text">{t.taxes.get()}</span>
                </a>
            </h1>
        )
    } else {
        return null
    }
}

const renderReportsSection = (
    companyRole: CompanyRole | null,
    visibleSections: ImmutableSet<string>,
    companyStatus: CompanyStatus,
) => {
    if (!canViewReports(companyRole, companyStatus)) {
        return null
    }

    return (
        <>
            <h1 className="menu__item">{renderSectionLink(t.reports.get(), 'reports')}</h1>
            {visibleSections.has('reports') && (
                <div className="menu__section">
                    {renderLink('/reports/balance', t.reports.balance.get())}
                    {renderLink('/reports/income', t.reports.income.get())}
                    {renderLink('/reports/cash-flow', t.reports.cashFlow.get())}
                    {renderLink('/reports/turnover', t.reports.turnover.get())}
                    {renderLink('/reports/accounts', t.reports.accounts.get())}
                    {renderLink('/financial-history/balance/view', t.financialHistory.get())}
                </div>
            )}
        </>
    )
}

const renderEntriesSection = (companyRole: CompanyRole | null, companyStatus: CompanyStatus) => {
    if (!canCreateEntry(companyRole, companyStatus)) {
        return null
    }

    return (
        <h1 className="menu__item">
            <a href="#/entries/list" className="menu__link">
                <img src="/menu/debit-credit.svg" className="menu__icon" />
                <span className="menu__item-text">{t.entries.get()}</span>
            </a>
        </h1>
    )
}

const renderSettingsSection = (companyRole: CompanyRole | null, companyStatus: CompanyStatus) => {
    if (!canViewSettings(companyRole, companyStatus)) {
        return null
    }

    return (
        <h1 className="menu__item">
            <a href="#/settings" className="menu__link">
                <img src="/menu/settings.svg" className="menu__icon" />
                <span className="menu__item-text">{t.settings.get()}</span>
            </a>
        </h1>
    )
}

const renderAdminSection = (session: ApiSession, visibleSections: ImmutableSet<string>) => {
    if (canViewAdminSection(session.companyRole, session.globalRole)) {
        const logVisible = canViewLogs(session.globalRole)
        const testVisible = canUseTestUtils(session.companyRole)

        return (
            <div>
                <h1 className="menu__item">{renderSectionLink('Admin', 'admin')}</h1>
                {visibleSections.has('admin') ? (
                    <div className="menu__section">
                        {renderLinkIf(logVisible, '/command-log', 'Log')}
                        {renderLinkIf(testVisible, '/texts', 'Tekstid')}
                        {renderLinkIf(testVisible, '/test', 'Testimine')}
                    </div>
                ) : null}
            </div>
        )
    } else {
        return null
    }
}

export const Menu: FC<Props> = ({ session, company, menuState: { visibleSections } }) => {
    if (!company) {
        return <LoadingIcon color="white" />
    }

    const { companyRole } = session
    const allExpensesVisible = canViewAllExpenses(companyRole, company.status)
    const allRevenuesVisible = canViewAllRevenues(companyRole, company.status)

    return (
        <div>
            <MenuToggle onClick={toggleMenu} />
            <h1 className="menu__item">
                <a href="#/" className="menu__link">
                    <img src="/menu/front-page.svg" className="menu__icon" />
                    <span className="menu__item-text">{t.frontPage.get()}</span>
                </a>
            </h1>
            <h1 className="menu__item">{renderSectionLink(t.incomes.get(), 'incomes')}</h1>
            {visibleSections.has('incomes') ? (
                <div className="menu__section">
                    {renderLink('/invoices/add', t.revenues.add.get())}
                    {renderLink('/invoices/register', t.revenues.register.get())}
                    {renderLinkIf(
                        allRevenuesVisible,
                        '/invoices/unpaid',
                        t.revenues.unpaidInvoices.get(),
                    )}
                    {renderLinkIf(
                        allRevenuesVisible,
                        '/invoices/archive/general',
                        t.revenues.archive.get(),
                    )}
                    {renderLinkIf(
                        allRevenuesVisible,
                        '/invoices/search-by-number',
                        t.revenues.searchByNumber.get(),
                    )}
                </div>
            ) : null}
            <h1 className="menu__item">{renderSectionLink(t.expenses.get(), 'expenses')}</h1>
            {visibleSections.has('expenses') ? (
                <div className="menu__section">
                    {renderLink('/expenses/add/regular', t.expenses.add.get())}
                    {renderLink('/expenses/register', t.expenses.register.get())}
                    {renderLinkIf(
                        allExpensesVisible,
                        '/expenses/unpaid',
                        t.expenses.unpaidInvoices.get(),
                    )}
                    {renderLinkIf(allExpensesVisible, '/expenses/assets', t.asset.get())}
                    {renderLinkIf(allExpensesVisible, '/expenses/stock', t.expenses.stock.get())}
                    {renderLinkIf(allExpensesVisible, '/expenses/goods', t.expenses.goods.get())}
                    {renderLinkIf(
                        allExpensesVisible,
                        '/expenses/general',
                        t.expenses.general.get(),
                    )}
                    {renderLink('/labour-costs', t.labourCosts.get())}
                    {renderLinkIf(
                        allExpensesVisible,
                        '/expenses/archive/general',
                        t.expenses.archive.get(),
                    )}
                </div>
            ) : null}
            {renderTaxesLink(companyRole, company.status, company.vatPeriods.length > 0)}
            {renderReportsSection(companyRole, visibleSections, company.status)}
            {renderEntriesSection(companyRole, company.status)}
            {renderSettingsSection(companyRole, company.status)}
            {renderAdminSection(session, visibleSections)}
        </div>
    )
}
