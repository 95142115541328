import React, { Component, CSSProperties } from 'react'
import shortid from 'shortid'

interface Props {
    size?: number
    color: string
    style?: CSSProperties
}

export class LoadingIcon extends Component<Props> {
    static defaultProps = { size: 20 }

    // SVG defs are global, so we generate a random id suffix for each loading icon
    override state = { idSuffix: shortid() }

    override componentDidMount() {
        if (this.icon && 'animate' in this.icon) {
            // Not supported in IE11, for example
            this.icon.animate([{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }], {
                duration: 1200,
                iterations: Infinity,
            })
        }
    }

    icon: SVGElement | null

    override render() {
        // The gradient does not actually follow the curve.
        // We use two half circles with linear gradient fills that give a close enough result,
        // especially when the icon is small.

        const { size, color, style } = this.props
        const { idSuffix } = this.state

        return (
            <svg
                ref={(c) => {
                    this.icon = c
                }}
                width={size}
                height={size}
                viewBox="0 0 120 120"
                style={style}
            >
                <defs>
                    <linearGradient id={'gradient1-' + idSuffix}>
                        <stop offset="0%" stopOpacity={0.5} stopColor={color} />
                        <stop offset="100%" stopOpacity={0} stopColor={color} />
                    </linearGradient>
                    <linearGradient id={'gradient2-' + idSuffix}>
                        <stop offset="0%" stopOpacity={0.5} stopColor={color} />
                        <stop offset="100%" stopOpacity={1} stopColor={color} />
                    </linearGradient>
                </defs>
                <path
                    d="M 10 60 A 50 50 0 1 0 110 60"
                    strokeWidth={15}
                    stroke={'url(#gradient1-' + idSuffix + ')'}
                    fill="none"
                />
                <path
                    d="M 110 60 A 50 50 0 1 0 10 60"
                    strokeWidth={15}
                    stroke={'url(#gradient2-' + idSuffix + ')'}
                    fill="none"
                />
            </svg>
        )
    }
}
