import React, { FC } from 'react'

import { t } from '../i18n'
import { RootData } from '../state/root-data'
import { LoadingIcon } from './loading-icon'

export const SelectCompany: FC<RootData> = ({ companyData: { companies } }) => {
    if (!companies) {
        return (
            <div className="company-selection">
                <LoadingIcon color="white" />
            </div>
        )
    }

    if (!companies.length) {
        return (
            <div className="company-selection">
                <a href="#/add-company" className="button company-selection__button">
                    {t.addCompany.get()}
                </a>
            </div>
        )
    }

    return <div className="company-selection">{t.selectCompany.instructions.get()}</div>
}
