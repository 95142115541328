import React, { FC } from 'react'

import { getColumns, Row } from '../../revenue-archive-utils'
import { ExcelButton, ExcelButtonProps } from '../excel-button'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { renderTable } from '../table'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface FilterItem {
    id: string
    label: string
    value: string
}

export interface RevenueArchiveResultsOkProps {
    loading: false
    title: string
    summaryTitle: string
    filters?: FilterItem[]
    noFiltersText?: string
    showTermColumn: boolean
    showDueDateColumn: boolean
    excelButton?: ExcelButtonProps
    rows: Row[]
    backLink: LinkProps
}

export type RevenueArchiveResultsProps = { loading: true } | RevenueArchiveResultsOkProps

export const RevenueArchiveResults: FC<RevenueArchiveResultsProps> = (props) => {
    if (props.loading) {
        return <LoadingPage />
    }

    const {
        title,
        summaryTitle,
        filters,
        noFiltersText,
        showTermColumn,
        showDueDateColumn,
        excelButton,
        rows,
        backLink,
    } = props

    const columns = getColumns(showTermColumn, showDueDateColumn)

    return (
        <div className="content-area">
            <RevenueArchiveSidebar />
            <div className="content archive">
                <div>
                    <h1 className="title archive__title">{title}</h1>
                    <div>
                        <div className="archive__filter-summary-title">{summaryTitle}:</div>
                        <div className="archive__filter-summary">
                            {filters?.map(({ id, label, value }) => (
                                <div key={id}>
                                    <b>{label}:</b> {value}
                                </div>
                            ))}
                            {noFiltersText}
                        </div>
                    </div>
                    <div className="top-margin">
                        {excelButton && <ExcelButton {...excelButton} />}
                    </div>
                    {renderTable({
                        columns,
                        rows,
                        stickyHeader: true,
                        wrapperClassName: 'top-margin',
                        tableClassName: 'table table--bottom-border archive__result-table',
                    })}
                </div>
                <div className="top-margin">
                    <Link {...backLink} className="button button--primary" />
                </div>
            </div>
        </div>
    )
}
