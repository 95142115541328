import classnames from 'classnames'
import React, { FC } from 'react'

import { FilterSection } from '../../../common/types/expense'
import { t } from '../../i18n'
import { Link } from '../link'

interface Props {
    selected?: FilterSection
}

const pages: FilterSection[] = ['general', 'vendor', 'types']

const renderButton = (page: FilterSection, isSelected: boolean) => (
    <Link
        to={'#/expenses/archive/' + page}
        text={t.expenses.archive.header[page].get()}
        className={classnames(
            'button',
            isSelected ? 'button--transparent-selected' : 'button--transparent',
            'archive__section-button',
        )}
    />
)

export const ExpenseArchiveSidebar: FC<Props> = ({ selected }) => (
    <div className="sidebar">
        <h1 className="sidebar__title-with-icon title">
            <img src="/menu/expenses.svg" className="sidebar__title-icon" />
            <div>{t.expenses.archive.get()}</div>
        </h1>
        {pages.map((page) => renderButton(page, page === selected))}
    </div>
)
