import React, { FC } from 'react'

import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { DateInput, DateInputProps } from '../date-input'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { RevenueArchiveSidebar } from './archive-sidebar'

interface ArchiveChoiceProps {
    title: string
    choice: ChoiceProps<string>
}

interface ArchiveDateProps {
    label: string
    input: DateInputProps
}

const renderTitle = (text: string) => <h1 className="title archive__title">{text}</h1>

const ArchiveChoice: FC<ArchiveChoiceProps> = ({ title, choice }) => (
    <>
        {renderTitle(title)}
        <div>
            {renderChoice({
                ...choice,
                buttonClassName: 'button--secondary',
                selectedButtonClassName: 'button--primary',
            })}
        </div>
    </>
)

const ArchiveDate: FC<ArchiveDateProps> = ({ label, input }) => (
    <div className="archive__date">
        <span className="archive__date-label">{label}</span>
        <DateInput {...input} className="date-button archive__date-input" />
    </div>
)

const renderShortcut = (props: Omit<ButtonProps, 'className'>) => (
    <Button {...props} className="button--secondary archive__date-shortcut" />
)

export interface RevenueArchiveGeneralOkProps {
    loading: false
    status: ArchiveChoiceProps
    dateTitle: string
    dateFrom: ArchiveDateProps
    dateTo: ArchiveDateProps
    monthShortcuts: Omit<ButtonProps, 'className'>[]
    yearShortcuts: Omit<ButtonProps, 'className'>[]
    paid: ArchiveChoiceProps
    dueDate: ArchiveChoiceProps
    term: ArchiveChoiceProps
    forwardLink: LinkProps
    showLink: LinkProps
    clearFiltersButton?: ButtonProps
}

export type RevenueArchiveGeneralProps = { loading: true } | RevenueArchiveGeneralOkProps

export const RevenueArchiveGeneral: FC<RevenueArchiveGeneralProps> = (props) => {
    if (props.loading) {
        return <LoadingPage />
    }

    const {
        status,
        dateTitle,
        dateFrom,
        dateTo,
        monthShortcuts,
        yearShortcuts,
        paid,
        dueDate,
        term,
        forwardLink,
        showLink,
        clearFiltersButton,
    } = props

    return (
        <div className="content-area">
            <RevenueArchiveSidebar selected="general" />
            <div className="content archive">
                <div>
                    <ArchiveChoice {...status} />
                    {renderTitle(dateTitle)}
                    <ArchiveDate {...dateFrom} />
                    <ArchiveDate {...dateTo} />
                    <div>
                        <div>{monthShortcuts.map(renderShortcut)}</div>
                        <div>{yearShortcuts.map(renderShortcut)}</div>
                    </div>
                    <ArchiveChoice {...paid} />
                    <ArchiveChoice {...dueDate} />
                    <ArchiveChoice {...term} />
                </div>
                <div>
                    <Link {...forwardLink} className="button button--primary" />{' '}
                    <Link {...showLink} className="button button--secondary" />{' '}
                    {clearFiltersButton && <Button {...clearFiltersButton} />}
                </div>
            </div>
        </div>
    )
}
