import React, { FC } from 'react'

import { highlight } from '../../business-utils'
import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { Input, InputProps } from '../input'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface ChosenBusinessProps {
    text: string
    button: Omit<ButtonProps, 'className'>
}

export interface FoundBusinessProps {
    onClick: () => void
    businessName: string
    searchText: string
    note: string
}

interface BusinessSearchProps {
    input: InputProps
    foundBusinesses?: FoundBusinessProps[]
    noneFoundText?: string
}

interface BusinessProps {
    title: string
    chosenBusiness?: ChosenBusinessProps
    search?: BusinessSearchProps
}

export interface RevenueArchiveCustomerOkProps {
    loading: false
    title: string
    customerType: ChoiceProps<string>
    business?: BusinessProps
    forwardLink: LinkProps
    showLink: LinkProps
    clearFiltersButton?: ButtonProps
}

export type RevenueArchiveCustomerProps = { loading: true } | RevenueArchiveCustomerOkProps

const ChosenBusiness: FC<ChosenBusinessProps> = ({ text, button }) => (
    <span>
        {text} <Button {...button} className="button--primary" />
    </span>
)

const FoundBusiness: FC<FoundBusinessProps> = ({ onClick, businessName, searchText, note }) => (
    <div onClick={onClick} className="business-lookup__match">
        {highlight(businessName, searchText)}
        <div className="business-lookup__match-note">{note}</div>
    </div>
)

const BusinessSearch: FC<BusinessSearchProps> = ({ input, foundBusinesses, noneFoundText }) => (
    <div>
        <Input {...input} />
        {foundBusinesses && (
            <div className="business-lookup__matches top-margin">
                {foundBusinesses.map((business, index) => (
                    <FoundBusiness key={index} {...business} />
                ))}
            </div>
        )}
        {noneFoundText && <div className="top-margin">{noneFoundText}</div>}
    </div>
)

const Business: FC<BusinessProps> = ({ title, chosenBusiness, search }) => (
    <>
        <h1 className="title archive__title">{title}</h1>
        {chosenBusiness && <ChosenBusiness {...chosenBusiness} />}
        {search && <BusinessSearch {...search} />}
    </>
)

export const RevenueArchiveCustomer: FC<RevenueArchiveCustomerProps> = (props) => {
    if (props.loading) {
        return <LoadingPage />
    }

    const { title, customerType, business, forwardLink, showLink, clearFiltersButton } = props

    return (
        <div className="content-area">
            <RevenueArchiveSidebar selected="customer" />
            <div className="content archive">
                <div>
                    <h1 className="title archive__title">{title}</h1>
                    <div>
                        {renderChoice({
                            ...customerType,
                            buttonClassName: 'button--secondary',
                            selectedButtonClassName: 'button--primary',
                        })}
                    </div>
                    {business && <Business {...business} />}
                </div>
                <div className="top-margin">
                    <Link {...forwardLink} className="button button--primary" />{' '}
                    <Link {...showLink} className="button button--secondary" />{' '}
                    {clearFiltersButton && <Button {...clearFiltersButton} />}
                </div>
            </div>
        </div>
    )
}
