import React, { FC } from 'react'

import { Column } from '../../../common/types/table'
import { formatAmount, formatAmountOrDash } from '../../format-amount'
import { t } from '../../i18n'
import { renderExcelMoney } from '../../render-excel-money'
import { wrapExcelButton } from '../../standard-page-utils'
import { browserOnly } from '../../table-utils'
import { DateInput, DateInputProps } from '../date-input'
import { ExcelButtonProps } from '../excel-button'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { NoData, NoDataProps } from '../no-data'
import { Payment, PaymentProps } from '../payment'
import { renderSortOptions, SortOptionsProps } from '../sort-options'
import { StandardPage } from '../standard-page'
import { renderTable } from '../table'
import { ViewIcon, ViewIconProps } from '../view-icon'

export interface UnpaidRevenuesRow {
    className?: string
    number: string
    customer: string
    overdueGroup: string
    future: number
    unpaid: number
    total: number
    viewIcon: ViewIconProps
    actions: LinkProps[]
}

export type UnpaidRevenuesGroup =
    | 'future'
    | '1...30'
    | '31...60'
    | '61...90'
    | '>90'
    | 'overdue'
    | 'unpaid'
    | 'full'

export type UnpaidRevenuesTotals = Record<UnpaidRevenuesGroup, number>

type UnpaidRevenuesColumn = Column<UnpaidRevenuesRow, UnpaidRevenuesTotals>

export type SortId = 'amount' | 'customer' | 'number'

interface UnpaidRevenuesOkProps {
    status: 'ok'
    paymentSidebar?: PaymentProps
    title: string
    subtitle: string
    sortOptions: SortOptionsProps<SortId>
    dateInput: DateInputProps
    excelButton?: ExcelButtonProps
    rows: UnpaidRevenuesRow[]
    totals: UnpaidRevenuesTotals
}

export type UnpaidRevenuesProps =
    | { status: 'loading' }
    | { status: 'no-data'; noData: NoDataProps }
    | UnpaidRevenuesOkProps

const getBordered = () => ({ className: 'table__body-cell table__body-cell--bordered' })
const getAmountClass = () => ({ className: 'table__body-cell table__body-cell--bordered amount' })
const getRightAligned = () => ({ className: 'text-right' })

const getOverdueCol = (group: UnpaidRevenuesGroup): UnpaidRevenuesColumn => ({
    secondHeader: {
        content: group,
        getProps: () => ({ className: 'overdue-second' }),
    },
    getProps: getAmountClass,
    render: (row) => {
        const value = row.overdueGroup === group ? row.unpaid : 0

        return {
            browser: formatAmountOrDash(value),
            excel: renderExcelMoney(value, false),
        }
    },
    getTotalProps: () => ({ className: 'amount' }),
    getTotal: (totals) => formatAmount(totals[group]),
    getExcelTotal: (totals) => ({ type: 'sum-money', value: totals[group] }),
    excelWidth: 12,
})

export const getUnpaidRevenuesColumns = (): UnpaidRevenuesColumn[] => [
    {
        header: { content: t.customer.get() },
        getProps: getBordered,
        render: (row) => row.customer,
        excelWidth: 30,
    },
    {
        header: {
            content: t.invoices.number.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'table__body-cell table__body-cell--bordered text-center' }),
        render: (row) => row.number,
        excelWidth: 13,
    },
    {
        header: { content: t.invoices.sum.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: formatAmountOrDash(row.total),
            excel: renderExcelMoney(row.total, false),
        }),
        getTotalProps: () => ({ className: 'amount' }),
        getTotal: (totals) => formatAmount(totals.full),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.full }),
        excelWidth: 12,
    },
    {
        header: { content: t.dueDate.future.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: formatAmountOrDash(row.future),
            excel: renderExcelMoney(row.future, false),
        }),
        getTotalProps: () => ({ className: 'amount' }),
        getTotal: (totals) => formatAmount(totals['future']),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals['future'] }),
        excelWidth: 20,
    },
    {
        // Padding before
        getProps: () => ({ className: 'pad-before' }),
        render: () => '',
        hideInExcel: true,
    },
    {
        ...getOverdueCol('1...30'),
        header: {
            content: t.dueDate.daysPast.get(),
            span: 4,
            getProps: () => ({ className: 'overdue-first' }),
        },
    },
    getOverdueCol('31...60'),
    getOverdueCol('61...90'),
    getOverdueCol('>90'),
    {
        // Padding after
        hideInExcel: true,
        render: () => '',
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) => (
            <span>
                <ViewIcon {...row.viewIcon} />
                {row.actions.map((linkProps, index) => (
                    <Link key={index} {...linkProps} />
                ))}
            </span>
        )),
        hideInExcel: true,
    },
]

export const UnpaidRevenues: FC<UnpaidRevenuesProps> = (props) => {
    if (props.status === 'loading') {
        return <LoadingPage />
    }

    if (props.status === 'no-data') {
        return <NoData {...props.noData} />
    }

    const { paymentSidebar, title, subtitle, dateInput, excelButton, sortOptions, rows, totals } =
        props

    const columns = getUnpaidRevenuesColumns()

    return (
        <StandardPage
            sidebar={paymentSidebar && <Payment {...paymentSidebar} />}
            title={title}
            subtitle={subtitle}
            sortOptions={renderSortOptions(sortOptions)}
            buttons={
                <>
                    <DateInput {...dateInput} className="button--wide button--primary" />
                    {excelButton && wrapExcelButton(excelButton)}
                </>
            }
            // TODO showAll button?
            table={renderTable({
                columns,
                rows,
                totals,
                stickyHeader: true,
                hasSecondHeader: true,
                tableClassName: 'table main-table unpaid-invoices',
                wrapperClassName: 'main-table-wrapper',
            })}
        />
    )
}
