import React, { FC } from 'react'

import { loginModes } from '../../common/enums'
import { InputValues } from '../../common/types/inputs'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { RootData } from '../state/root-data'
import { login, LOGIN_PROCESS } from '../state/session-actions'
import { valErr } from '../val-err'
import { Button } from './button'
import { renderChoice } from './choice'
import { Input } from './input'

const renderModeInfo = (inputValues: InputValues) => {
    const input = inputs.login.modeInfoExpanded

    if (input.get(inputValues)) {
        const onClick = () => input.set(false)

        return (
            <div className="login__mode-explanation">
                <a onClick={onClick} className="login__mode-explanation-close">
                    ✖
                </a>
                <div>{t.login.mode.explanation[0].get()}</div>
                <div className="top-margin">{t.login.mode.explanation[1].get()}</div>
                <div className="top-margin">{t.login.mode.explanation[2].get()}</div>
            </div>
        )
    } else {
        const onClick = () => input.set(true)

        return (
            <div className="top-margin">
                <a id="login-mode-why" onClick={onClick} tabIndex={0} className="login__link">
                    {t.login.mode.why.get()}
                </a>
            </div>
        )
    }
}

export const Login: FC<RootData> = ({ inputValues, processes, validationErrors }) => {
    const valErrors = validationErrors[LOGIN_PROCESS]

    return (
        <div className="login">
            <div>
                <Input
                    input={inputs.login.email}
                    inputValues={inputValues}
                    placeholder={t.email.get()}
                    onEnter={login}
                    focusOnMount={true}
                    className="login__input"
                />
                {valErr(valErrors, 'email', { invalid: t.user.emailNotFound.get() })}
            </div>
            <div className="top-margin">
                <Input
                    type="password"
                    input={inputs.login.password}
                    inputValues={inputValues}
                    placeholder={t.password.get()}
                    onEnter={login}
                    className="login__input"
                />
                {valErr(valErrors, 'password', { invalid: t.login.invalidPassword.get() })}
            </div>
            <div className="top-margin">
                <a href="#/password-reset/init" className="login__link">
                    {t.login.forgotPassword.get()}
                </a>
            </div>
            <div className="login__section">
                <Button
                    text={t.login.get()}
                    onClick={login}
                    processes={processes}
                    processName={LOGIN_PROCESS}
                    domId="btn-login"
                    className="login__main-button"
                    loadingColor="white"
                    loadingStyle={{ marginTop: '0.8em', width: 25, height: 25 }}
                />
            </div>
            <div className="login__section">
                {t.login.mode[1].get()}{' '}
                {renderChoice({
                    type: 'buttons',
                    input: inputs.login.mode,
                    inputValues,
                    options: [
                        { id: loginModes.public, label: t.login.mode.public.get() },
                        { id: loginModes.private, label: t.login.mode.private.get() },
                    ],
                    forceSelection: true,
                    buttonClassName: 'login__mode-button',
                    selectedButtonClassName: 'login__mode-button login__mode-button--selected',
                })}{' '}
                {t.login.mode[2].get()}
                {renderModeInfo(inputValues)}
            </div>
        </div>
    )
}
