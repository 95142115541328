import React, { FC, ReactNode } from 'react'

import { findById } from '../common/find-by-id'
import { ChoiceOption, InputGetSet, InputValues, Input as TInput } from '../common/types/inputs'
import { ChoiceProps, renderChoice } from './components/choice'
import { Input, InputProps } from './components/input'
import { setRoute } from './route-utils'
import { dispatch } from './state/store'

type Getter<V> = (inputValues: InputValues, key: string, input: TInput<V>) => V

export const KEY = Symbol('KEY')

// The placeholders must have the same type as the transformed input objects,
// this makes type annotations a lot easier.

export const getPlaceholder = <T,>(inputType: string, defaultValue?: T): TInput<T> => {
    return {
        inputType,
        get: () => {
            throw new Error('Input placeholder')
        },
        set: () => {
            throw new Error('Input placeholder')
        },
        getRaw: () => {
            throw new Error('Input placeholder')
        },
        hasValue: () => {
            throw new Error('Input placeholder')
        },
        setDefaultValue: () => {
            throw new Error('Input placeholder')
        },
        getDefaultValue: () => defaultValue,
        clear: () => {
            throw new Error('Input placeholder')
        },
    }
}

export const STR: TInput<string> = getPlaceholder('string')
export const BOOL: TInput<boolean> = getPlaceholder('boolean')
export const NUM: TInput<number> = getPlaceholder('number')

const getStr: Getter<string> = (inputValues, key, input) => {
    const value = inputValues[key]

    if (value === '' || value === null || typeof value === 'undefined') {
        const defaultValue = input.getDefaultValue(inputValues)
        return defaultValue === undefined || defaultValue === null ? '' : defaultValue
    } else if (typeof value === 'string') {
        return value.trim()
    } else {
        throw new Error('Expected ' + key + ' to be a string, but was a ' + typeof value)
    }
}

const getBool: Getter<boolean> = (inputValues, key, input) => {
    const value = inputValues[key]

    if (typeof value === 'boolean') {
        return value
    } else if (value === null || typeof value === 'undefined') {
        const defaultValue = input.getDefaultValue(inputValues)
        return defaultValue === undefined || defaultValue === null ? false : defaultValue
    } else {
        throw new Error('Expected ' + key + ' to be a boolean, but was a ' + typeof value)
    }
}

const getNum: Getter<number> = (inputValues, key, input) => {
    const value = inputValues[key]

    if (typeof value === 'number') {
        return value
    } else if (value === null || typeof value === 'undefined') {
        const defaultValue = input.getDefaultValue(inputValues)
        return defaultValue === undefined || defaultValue === null ? 0 : defaultValue
    } else {
        throw new Error('Expected ' + key + ' to be a number, but was a ' + typeof value)
    }
}

const getObj: Getter<Record<string, unknown>> = (inputValues, key, input) => {
    const value = inputValues[key]

    if (value && typeof value === 'object') {
        return value as Record<string, unknown>
    } else {
        const defaultValue = input.getDefaultValue(inputValues)

        if (defaultValue) {
            return defaultValue
        } else {
            throw new Error('Expected ' + key + ' to be an object, but was a ' + typeof value)
        }
    }
}

const createInput = <V,>(fieldDef: TInput<V>, fullKey: string, getter: Getter<V>): TInput<V> => {
    const defKey = 'def:' + fullKey

    const input: TInput<V> = {
        inputType: fieldDef.inputType,
        get: (inputValues) => getter(inputValues, fullKey, input),
        set: (value) => {
            dispatch(({ inputValues }) => (inputValues[fullKey] = value))
        },
        getRaw: (inputValues) => inputValues[fullKey],
        hasValue: (inputValues) => fullKey in inputValues,
        setDefaultValue: (value) => {
            dispatch(({ inputValues }) => (inputValues[defKey] = value))
        },
        getDefaultValue: (inputValues) =>
            (inputValues[defKey] as V) || fieldDef.getDefaultValue({}),
        clear: () => dispatch(({ inputValues }) => delete inputValues[fullKey]),
    }

    return input
}

export const transform = <T extends Record<string, unknown> | (() => void)>(
    definition: T,
    key: string | null = null,
): T => {
    const defAny: any = definition
    const { inputType } = defAny as { inputType: string }

    const createInputFrom = <V,>(getter: Getter<V>) => {
        return createInput(defAny, key!, getter) as unknown as T
    }

    if (inputType === 'string') {
        return createInputFrom(getStr)
    } else if (inputType === 'boolean') {
        return createInputFrom(getBool)
    } else if (inputType === 'number') {
        return createInputFrom(getNum)
    } else if (inputType === 'object') {
        return createInputFrom(getObj)
    } else if (typeof definition === 'object') {
        const result: any = {}

        for (const objKey of Object.keys(definition)) {
            result[objKey] = transform(defAny[objKey], key ? key + '/' + objKey : objKey) as unknown
        }

        result[KEY] = key // This is needed for clearInputs()
        return result
    } else if (typeof definition === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const newDefinition = defAny()
        const func = (param: string) => transform(newDefinition, key ? key + '/' + param : param)

        const funcAny: any = func
        funcAny[KEY] = key // This is needed for clearInputs()
        return funcAny
    } else {
        throw new Error('Unexpected field definition type: ' + typeof definition)
    }
}

export const setIfEmpty = <T,>(input: TInput<T>, inputValues: InputValues, value: T) => {
    if (!input.hasValue(inputValues)) {
        input.set(value)
    }
}

export const setUnlessDefault = <T,>(input: TInput<T>, inputValues: InputValues, value: T) => {
    const defaultValue = input.getDefaultValue(inputValues)

    if (value === defaultValue) {
        input.clear()
    } else {
        input.set(value)
    }
}

export interface InputOrValueProps {
    editMode: boolean
    inputProps: InputProps
    modify?: (value: string) => ReactNode
}

export const InputOrValue: FC<InputOrValueProps> = ({ editMode, inputProps, modify }) => {
    if (editMode) {
        return <Input {...inputProps} />
    } else {
        const { input, inputValues, type } = inputProps
        const initialValue = input.get(inputValues)
        const value: ReactNode = modify ? modify(initialValue) : initialValue

        if (type === 'multiline') {
            return <div className="text-multiline">{value}</div>
        } else {
            return <>{value}</>
        }
    }
}

// TODO remove?
export const renderInputOrValue = (
    editMode: boolean,
    inputProps: InputProps,
    modify?: (value: string) => ReactNode,
): ReactNode => <InputOrValue editMode={editMode} inputProps={inputProps} modify={modify} />

export const renderChoiceOrValue = <T extends string>(
    editMode: boolean,
    choiceProps: ChoiceProps<T>,
) => {
    if (editMode) {
        return renderChoice(choiceProps)
    } else {
        const { input, inputValues } = choiceProps
        const value = input.get(inputValues)

        if (!value) {
            return ''
        }

        const option = findById<T, ChoiceOption<T>>(choiceProps.options, value)
        return option!.label
    }
}

export const createCustomInput = <T,>({ inputType, get, set }: InputGetSet<T>): TInput<T> => ({
    inputType,
    get,
    set,
    getRaw: get,
    hasValue: () => true,
    setDefaultValue: () => {},
    getDefaultValue: () => undefined,
    clear: () => {},
})

export const createYearUrlInput = (urlPrefix: string, shownYear: number) =>
    createCustomInput({
        inputType: 'string',
        get: () => shownYear,
        set: async (year) => setRoute(urlPrefix + year),
    })

export const createMonthUrlInput = (urlPrefix: string, shownMonth: string) =>
    createCustomInput({
        inputType: 'string',
        get: () => shownMonth,
        set: async (month) => setRoute(urlPrefix + month),
    })

export const getIfNotDefault = <T,>(input: TInput<T>, inputValues: InputValues) => {
    const defaultValue = input.getDefaultValue(inputValues)
    const value = input.get(inputValues)
    return value === defaultValue ? undefined : value
}

export const wrapAsStringInput = <T,>(
    input: TInput<T>,
    toString: (value: T) => string,
    fromString: (value: string) => T,
): TInput<string> => {
    return {
        inputType: input.inputType,
        get: (inputValues) => toString(input.get(inputValues)),
        set: (value) => input.set(fromString(value)),
        getRaw: (inputValues) => toString(input.getRaw(inputValues)),
        hasValue: (inputValues) => input.hasValue(inputValues),
        setDefaultValue: (value) => input.setDefaultValue(fromString(value)),
        getDefaultValue: (inputValues) => {
            const value = input.getDefaultValue(inputValues)
            return typeof value === 'undefined' ? value : toString(value)
        },
        clear: () => input.clear(),
    }
}
