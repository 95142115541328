import React, { FC } from 'react'

import { Day } from '../../common/time'
import { MoneyForm } from '../../common/types/enums'
import { Totals } from '../../common/types/item'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { renderValidationErrors } from '../val-err'
import { Button, ButtonProps } from './button'
import { ChoiceProps, renderChoice } from './choice'
import { DateInput, DateInputProps } from './date-input'
import { Input, InputProps } from './input'
import { Link, LinkProps } from './link'
import { LoadingIcon } from './loading-icon'
import { MenuToggle } from './menu-toggle'
import { ValidationErrorProps } from './validation-error'

export interface PaymentLabels {
    main: string
    amountSection: string
    isFull: string
    amount: string
}

interface PreviousPayment {
    date: Day
    description: string
}

export interface PaymentProps {
    onClose: () => void
    labels: PaymentLabels
    totals: Totals<number>
    disabledText?: string
    previous?: {
        title: string
        payments: PreviousPayment[]
        removeLastLink?: LinkProps
        isRemoving?: boolean
    }
    remaining?: {
        label: string
        amount: number
    }
    isFull?: {
        label: string
        choice: ChoiceProps<'yes' | 'no'>
    }
    amount?: {
        label: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    moneyForm?: {
        label: string
        choice: ChoiceProps<MoneyForm>
    }
    date?: {
        label: string
        dateInput: DateInputProps
    }
    errors: ValidationErrorProps[]
    saveButton?: Omit<ButtonProps, 'className'>
    isCredit?: boolean
    totalToPay: number
}

const renderVatRows = (payableWithoutVat: number, vatAmount: number, isCredit?: boolean) => {
    if (!isCredit && vatAmount > 0) {
        return (
            <>
                <div>
                    {t.vat.without.get()}
                    {': '}
                    {formatAmount(payableWithoutVat)}
                    {' €'}
                </div>
                <div className="top-margin">
                    {t.vat.get()}
                    {': '}
                    {formatAmount(vatAmount)}
                    {' €'}
                </div>
            </>
        )
    } else {
        return null
    }
}

const renderPrevious = (props: NonNullable<PaymentProps['previous']>) => {
    const { title, payments, removeLastLink, isRemoving } = props

    return (
        <div>
            <h3 className="sidebar__section-title">{title}</h3>
            <ul className="payment__previous-list">
                {payments.map((payment, index) => (
                    <li key={index} className="payment__previous-item">
                        <b>{payment.date.longDate()}</b>
                        <br />
                        {payment.description}
                    </li>
                ))}
                {(removeLastLink || isRemoving) && (
                    <li className="payment__previous-item">
                        {removeLastLink && <Link {...removeLastLink} className="sidebar__link" />}
                        {isRemoving && <LoadingIcon color="white" />}
                    </li>
                )}
            </ul>
        </div>
    )
}

export const Payment: FC<PaymentProps> = (props) => {
    const {
        onClose,
        labels,
        totals: { payableWithoutVat, vatAmount, payableWithVat },
        previous,
        disabledText,
        remaining,
        isFull: isFullProps,
        amount: amountProps,
        moneyForm: moneyFormProps,
        date: dateProps,
        errors,
        saveButton,
        isCredit,
        totalToPay,
    } = props

    const payableTotal = isCredit ? totalToPay : formatAmount(payableWithVat)

    return (
        <div className="sidebar sidebar--flex open">
            <div>
                <MenuToggle onClick={onClose} />
                <h1 className="title sidebar__title">{labels.main}</h1>
                <div>
                    <h3 className="sidebar__section-title">{labels.amountSection}</h3>
                    {renderVatRows(payableWithoutVat, vatAmount, isCredit)}
                    <div className="top-margin">
                        {t.payableTotal.get()}
                        {': '}
                        <b>
                            {payableTotal}
                            {' €'}
                        </b>
                    </div>
                </div>
                {previous && renderPrevious(previous)}
                {disabledText && <div>{disabledText}</div>}
                {remaining && (
                    <div>
                        {remaining.label}
                        {': '}
                        <b>
                            {formatAmount(remaining.amount)}
                            {' €'}
                        </b>
                    </div>
                )}
                {isFullProps && (
                    <div>
                        <h3 className="sidebar__section-title">{isFullProps.label}</h3>
                        {renderChoice({
                            ...isFullProps.choice,
                            groupClassName: 'payment__full-buttons',
                            buttonClassName: 'sidebar__wide-button button--transparent',
                            selectedButtonClassName:
                                'sidebar__wide-button button--transparent-selected',
                        })}
                    </div>
                )}
                {amountProps && (
                    <div>
                        <h3 className="sidebar__section-title">{amountProps.label}</h3>
                        <div>
                            <Input
                                {...amountProps.input}
                                className="input input--white sidebar__input amount"
                            />
                            {' €'}
                            {renderValidationErrors(amountProps.errors)}
                        </div>
                    </div>
                )}
                {moneyFormProps && (
                    <div>
                        <h3 className="sidebar__section-title">{moneyFormProps.label}</h3>
                        {renderChoice({
                            ...moneyFormProps.choice,
                            groupClassName: 'payment__money-form-buttons vertical',
                            buttonClassName: 'sidebar__wide-button button--transparent',
                            selectedButtonClassName:
                                'sidebar__wide-button button--transparent-selected',
                        })}
                    </div>
                )}
                {dateProps && (
                    <div>
                        <h3 className="sidebar__section-title">{dateProps.label}</h3>
                        <DateInput
                            {...dateProps.dateInput}
                            className="date-button date-button--white sidebar__wide-button"
                            renderAsBlock={true}
                        />
                    </div>
                )}
                <div className="top-margin bottom-margin">{renderValidationErrors(errors)}</div>
            </div>
            {saveButton && (
                <Button
                    {...saveButton}
                    className="payment__save-button button--white"
                    loadingColor="white"
                />
            )}
        </div>
    )
}
