import React, { Component } from 'react'

import { ServerError } from '../../common/server-error'
import * as api from '../api'
import { fromErrorCode, processWarning } from '../error-manager'
import { emitOpenFakeBanner } from '../event-bus'
import { invalidateCache as invalidateAccountCache } from '../state/account-actions'
import {
    invalidateAssetChangeCache,
    invalidateCache as invalidateExpenseCache,
    invalidateStockChangeCache,
} from '../state/expense-actions'
import { invalidateCache as invalidateLabourCostCache } from '../state/labour-cost-actions'
import { run } from '../state/process-actions'
import { invalidateCache as invalidateRevenueCache } from '../state/revenue-actions'
import { RootData } from '../state/root-data'
import { Button } from './button'
import { LoadingIcon } from './loading-icon'

interface State {
    done: boolean
}

const PROCESS_NAME = 'reset'

const throwError = () => {
    throw new ServerError({ success: false, errorCode: 'database-connection-lost' })
}

const showWarning = () => processWarning(fromErrorCode('already-confirmed').dontReport())

// Stateful component because it's a secondary feature
export class Test extends Component<RootData, State> {
    override state = { done: false }

    renderDeleteButton() {
        const { processes } = this.props

        if (processes.has(PROCESS_NAME)) {
            return <LoadingIcon color="black" />
        }

        const onClick = async () =>
            run(PROCESS_NAME, async () => {
                const message =
                    'Kas soovid kustutada kõik käesoleva ettevõtte tulud, kulud, laenud ja kontod?'

                if (confirm(message)) {
                    await api.clearData()
                    invalidateAccountCache()
                    invalidateExpenseCache()
                    invalidateRevenueCache()
                    invalidateLabourCostCache()
                    invalidateAssetChangeCache()
                    invalidateStockChangeCache()
                    this.setState({ done: true })
                }
            })

        return (
            <button onClick={onClick} className="button button--primary">
                Andmete reset
            </button>
        )
    }

    override render() {
        if (this.state.done) {
            return <div>Andmed kustutatud.</div>
        }

        return (
            <div className="content-area">
                <div className="content">
                    <h1 className="title">Testimine</h1>
                    <div>
                        <Button
                            text="Tekita süsteemiviga"
                            onClick={throwError}
                            className="button--secondary"
                        />
                    </div>
                    <div className="top-margin">
                        <Button
                            text="Tekita hoiatus"
                            onClick={showWarning}
                            className="button--secondary"
                        />
                    </div>
                    <div className="top-margin">
                        <Button
                            text="Tekita sessiooni aegumise teade"
                            onClick={emitOpenFakeBanner}
                            className="button--secondary"
                        />
                    </div>
                    <div className="top-margin">{this.renderDeleteButton()}</div>
                </div>
            </div>
        )
    }
}
