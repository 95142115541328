import React, { FC } from 'react'

import { t } from '../i18n'

export interface ViewIconProps {
    href: string
}

export const ViewIcon: FC<ViewIconProps> = ({ href }) => (
    <a href={href} title={t.view.get()}>
        <img src="/icons/view.svg" className="view-icon" />
    </a>
)
