import React, { FC } from 'react'

import { canChangeAssetValues, canConfirm, canRemoveAllExpenses } from '../../common/access'
import { getAssetAccountNumber } from '../../common/accounts'
import { MAX_ROWS } from '../../common/constants'
import {
    assetChangeModes,
    assetTypes,
    CompanyStatus,
    expenseTypes,
    logActions,
} from '../../common/enums'
import { addValueChange } from '../../common/expense-utils'
import { findByDbId } from '../../common/find-by-db-id'
import { findById } from '../../common/find-by-id'
import { invariant } from '../../common/invariant'
import {
    calculateAssetCurrent,
    calculateAssetCurrentFromValues,
    calculateAssetInitial,
} from '../../common/item-utils'
import { keys } from '../../common/keys'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { ApiExpense, AssetChangeParams, PendingAssetChange } from '../../common/types/expense'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { ApiSession } from '../../common/types/session'
import { getDefaultAccountName } from '../account-utils'
import { assertViewName } from '../assert-view-name'
import { getExcelButtonProps } from '../excel-utils'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { getShowAllButtonProps, wrapExcelButton } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import {
    CONFIRM_ASSET_CHANGE_PROCESS,
    confirmAssetChange,
    confirmExpense,
    parseAssetRouteParams,
    remove,
    REMOVE_ASSET_CHANGE_PROCESS,
    removeAssetChange,
} from '../state/expense-actions'
import { RootData } from '../state/root-data'
import { AssetTable, getColumns, Row } from './asset-table'
import { Button } from './button'
import { DateInput } from './date-input'
import { ExcelButtonProps } from './excel-button'
import { AssetChange } from './expense/asset-change'
import { LoadingIcon } from './loading-icon'
import { NoData } from './no-data'
import { renderSortOptions } from './sort-options'

export type SortId = 'date' | 'price' | 'residual' | 'eol'

const tableInputs = inputs.expense.assetList

const sortOptions: { [S in SortId]: SortOption<Row> } = {
    date: [{ getKey: (row) => row.date.toTimestamp(), reverse: true }],
    price: [{ getKey: (row) => row.withoutVat, reverse: true }],
    residual: [{ getKey: (row) => row.residual, reverse: true }],
    eol: [{ getKey: (row) => (row.eolDate ? row.eolDate.toTimestamp() : Number.MAX_VALUE) }],
}

const renderSidebar = (rootData: RootData, routeParams: string[]) => {
    const {
        expenseData: { expenses, pendingAssetChanges },
        inputValues,
        processes,
        validationErrors,
    } = rootData

    if (!pendingAssetChanges) {
        return null
    }

    const changeParams = parseAssetRouteParams(routeParams, pendingAssetChanges)

    if (!changeParams) {
        return null
    }

    return (
        <AssetChange
            changeParams={changeParams}
            expenses={expenses}
            inputValues={inputValues}
            processes={processes}
            validationErrors={validationErrors}
        />
    )
}

const canRemove = (expense: ApiExpense, session: ApiSession, companyStatus: CompanyStatus) => {
    const canRemoveAll = canRemoveAllExpenses(session.companyRole, companyStatus)

    if (expense.confirmed) {
        return canRemoveAll
    }

    if (canRemoveAll) {
        return true
    }

    const [firstEntry] = expense.log
    invariant(firstEntry.action === logActions.create)
    return firstEntry.userId === session.userId
}

const getRows = (
    expenses: ApiExpense[],
    date: Day,
    session: ApiSession,
    companyStatus: CompanyStatus,
    sortOption: SortOption<Row>,
    changeParams: AssetChangeParams | null,
): Row[] => {
    const rows: Row[] = []

    for (const expense of expenses) {
        const expenseDate = Day.fromYmd(expense.date)

        if (expense.type !== expenseTypes.asset || expenseDate.isAfter(date)) {
            continue
        }

        const { rev, confirmed } = expense

        for (const asset of expense.assets!) {
            const { type, description } = asset
            const isLand = type === assetTypes.land
            const accountNumber = getAssetAccountNumber(type)
            const { payableWithoutVat } = calculateAssetInitial(asset)
            const { amortRate, eolDate, residual, depreciation } = calculateAssetCurrent(
                date,
                asset,
            )

            let className, onClick

            if (changeParams) {
                if (changeParams.expenseId === expense._id && changeParams.assetId === asset.id) {
                    className = 'shaded'
                } else if (
                    !changeParams.expenseId ||
                    (changeParams.isNew && changeParams.existing)
                ) {
                    className = 'clickable'

                    let url =
                        '#/expenses/assets/add-value-change/' +
                        changeParams.date +
                        '/' +
                        expense._id +
                        '/' +
                        asset.id

                    if (isLand) {
                        // Can't change EoL, so auto-select residual
                        url += '/' + assetChangeModes.residual
                    }

                    onClick = () => setRoute(url)
                }
            }

            rows.push({
                className,
                onClick,
                isLand,
                date: expenseDate,
                account: getDefaultAccountName(accountNumber),
                description,
                amortRate,
                withoutVat: payableWithoutVat,
                depreciation,
                residual,
                eolDate,
                confirmed,
                viewRoute: '#/expenses/view/' + expense._id,
                confirm: canConfirm(session.companyRole, companyStatus)
                    ? async () => confirmExpense(expense._id, rev)
                    : undefined,
                confirmMessage: t.confirm.confirmExpense.get(),
                remove: canRemove(expense, session, companyStatus)
                    ? async () => remove(expense._id)
                    : undefined,
                removeMessage: confirmed
                    ? t.confirm.removeConfirmedInvoice.get()
                    : t.confirm.removeExpense.get(),
            })
        }
    }

    return sort(rows, sortOption)
}

const renderAddNewButton = () => (
    <a className="button button--primary" href="#/expenses/add/asset">
        {t.addNew.get()}
    </a>
)

const renderChangeValueButton = () => (
    <div className="top-margin">
        <a className="button button--primary" href="#/expenses/assets/add-value-change">
            {t.assets.valueChange.get()}
        </a>
    </div>
)

const renderDateButton = (inputValues: InputValues) => (
    <DateInput
        input={tableInputs.date}
        inputValues={inputValues}
        text={t.chooseDate.get()}
        maxDate={Day.today()}
        className="button--wide button--primary"
    />
)

const renderTopRightButtons = (
    inputValues: InputValues,
    excelButton: ExcelButtonProps | undefined,
) => (
    <div className="standard-buttons flex-vertical flex-end">
        {renderDateButton(inputValues)}
        {excelButton && wrapExcelButton(excelButton)}
    </div>
)

const renderPendingAssetChangeTable = (
    pendingAssetChanges: PendingAssetChange[] | null,
    expenses: ApiExpense[],
    inputValues: InputValues,
    processes: Processes,
) => {
    if (
        !pendingAssetChanges ||
        processes.has(CONFIRM_ASSET_CHANGE_PROCESS) ||
        processes.has(REMOVE_ASSET_CHANGE_PROCESS)
    ) {
        return <LoadingIcon color="black" />
    }

    const rows = pendingAssetChanges.map((change): Row => {
        const { expenseId, assetId } = change
        const expense = findByDbId(expenses, expenseId)!
        const asset = findById(expense.assets!, assetId)!

        const { type, description, amortBegin, valueChanges } = asset
        const accountNumber = getAssetAccountNumber(type)
        const refDate = Day.fromYmd(tableInputs.date.get(inputValues))
        const { payableWithoutVat: initial } = calculateAssetInitial(asset)
        const newValueChanges = addValueChange(valueChanges, change.valueChange)

        const { amortRate, eolDate, residual } = calculateAssetCurrentFromValues(
            refDate,
            type,
            initial,
            amortBegin,
            asset.eolDate,
            newValueChanges,
        )

        return {
            isLand: type === assetTypes.land,
            date: Day.fromYmd(expense.date),
            account: getDefaultAccountName(accountNumber),
            description,
            amortRate,
            withoutVat: initial,
            depreciation: initial - residual,
            residual,
            eolDate,
            confirmed: false,
            viewRoute: '#/expenses/assets/edit-value-change/' + change._id,
            confirm: async () => confirmAssetChange(change._id),
            confirmMessage: t.confirm.confirmChange.get(),
            remove: async () => removeAssetChange(change._id),
            removeMessage: t.confirm.removeChange.get(),
        }
    })

    return <AssetTable actionsVisible={true} rows={rows} />
}

const renderPendingAssetChanges = (
    pendingAssetChanges: PendingAssetChange[] | null,
    expenses: ApiExpense[],
    inputValues: InputValues,
    processes: Processes,
) => {
    if (pendingAssetChanges && !pendingAssetChanges.length) {
        return null
    }

    return (
        <div>
            <h3 className="section-title">{t.assets.valueChange.unconfirmed.get()}</h3>
            {renderPendingAssetChangeTable(pendingAssetChanges, expenses, inputValues, processes)}
        </div>
    )
}

const renderContent = (rootData: RootData, routeParams: string[]) => {
    const {
        companyData,
        expenseData: { expenses, pendingAssetChanges },
        formsReady,
        inputValues,
        processes,
        progress,
        session,
    } = rootData

    if (!companyData.companies) {
        return <LoadingIcon color="black" />
    }

    const company = getCompany(companyData, session)

    let date: Day | null = null
    let changeParams = null
    const hasChangePermission = canChangeAssetValues(session!.companyRole, company.status)

    if (hasChangePermission) {
        if (!pendingAssetChanges) {
            return <LoadingIcon color="black" />
        }

        changeParams = parseAssetRouteParams(routeParams, pendingAssetChanges)

        if (changeParams) {
            if (!changeParams.date) {
                return null
            }

            date = Day.fromYmd(changeParams.date)
        }
    }

    if (!formsReady.has('asset-list') || !expenses) {
        return <LoadingIcon color="black" />
    }

    date = date || Day.fromYmd(tableInputs.date.get(inputValues))
    const showAll = tableInputs.showAll.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const actionsVisible = !changeParams
    const columns = getColumns(actionsVisible)

    const expensesForRows = changeParams
        ? expenses.filter((expense) => expense.confirmed)
        : expenses

    const allRows = getRows(
        expensesForRows,
        date,
        session!,
        company.status,
        sortOptions[sortId],
        changeParams,
    )

    const hasMore = allRows.length > MAX_ROWS
    const rows: Row[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)

    const title = t.asset.get()
    const subtitle = t.asOf.get(date.longDate())

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        excelButton = getExcelButtonProps(
            { columns, rows, outputName: title + ' ' + subtitle },
            t.assets.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return (
        <>
            <h1 className="title">
                {title}
                <span className="title__sub-title">{subtitle}</span>
            </h1>
            <div className="flex space-between flex-end">
                {renderSortOptions({
                    input: tableInputs.sort,
                    inputValues,
                    options: keys(sortOptions).map((key) => ({
                        id: key,
                        label: t.fixedAssets.sortOption[key].get(),
                    })),
                })}
                {!changeParams && renderTopRightButtons(inputValues, excelButton)}
            </div>
            <div>
                <AssetTable actionsVisible={actionsVisible} rows={rows} />
                <div className="top-margin">
                    {!showAll && hasMore && (
                        <Button {...getShowAllButtonProps(tableInputs.showAll)} />
                    )}{' '}
                    {!changeParams && renderAddNewButton()}
                </div>
                {!changeParams && hasChangePermission && (
                    <>
                        {renderChangeValueButton()}
                        {renderPendingAssetChanges(
                            pendingAssetChanges,
                            expenses,
                            inputValues,
                            processes,
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export const AssetList: FC<RootData> = (rootData) => {
    const {
        expenseData: { expenses },
        view,
    } = rootData
    const { routeParams } = assertViewName(view, 'AssetList')

    if (expenses && !expenses.some((expense) => expense.type === expenseTypes.asset)) {
        return <NoData addRoute="#/expenses/add/asset" addButtonText={t.assets.add.get()} />
    }

    return (
        <div className="content-area asset-list">
            {renderSidebar(rootData, routeParams)}
            <div className="content">{renderContent(rootData, routeParams)}</div>
        </div>
    )
}
