import React, { FC } from 'react'

import { t } from '../i18n'
import { RadialProgress, RadialProgressProps } from './radial-progress'

export type CharactersLeftProps = RadialProgressProps

export const CharactersLeft: FC<CharactersLeftProps> = (props) => (
    <div className="text-right">
        {t.charactersLeft.get()} <RadialProgress {...props} />
    </div>
)
