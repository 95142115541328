import classnames from 'classnames'
import React, { FC } from 'react'

import {
    BusinessInputs,
    OtherBusiness,
    PrevBusiness,
    BusinessLookup as TBusinessLookup,
    VatState,
} from '../../common/types/business'
import { ValidationError } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { highlight } from '../business-utils'
import { t } from '../i18n'
import { renderInputOrValue } from '../input-utils'
import { lookupBusiness, selectBusiness } from '../state/business-lookup-actions'
import { valErr } from '../val-err'
import { LoadingIcon } from './loading-icon'

export interface BusinessLookupProps {
    collectionName?: 'Expenses' | 'Invoices'
    businessLookup: TBusinessLookup
    inputs: BusinessInputs
    inputValues: InputValues
    processes: Processes
    vatPayer: boolean
    errorPrefix: string
    valErrors?: ValidationError[]
    disabled?: boolean
}

const renderNonVatPayerNote = (lastSelectionVat: VatState) =>
    lastSelectionVat === 'non-payer' ? <div>{t.businessLookup.nonVatPayer.get()}</div> : null

const renderVatId = (props: BusinessLookupProps, lastSelectionVat: VatState) => {
    const { inputs, inputValues, vatPayer, errorPrefix, valErrors, disabled } = props

    if (disabled && !inputs.vatId.get(inputValues)) {
        return null
    }

    if (vatPayer) {
        return (
            <tr>
                <td className="table__body-cell table__body-cell--no-left-pad">
                    {t.business.vatId.get()}
                </td>
                <td>
                    {renderInputOrValue(!disabled, {
                        input: inputs.vatId,
                        inputValues,
                        className: 'business-lookup__input',
                    })}
                    {valErr(valErrors, errorPrefix + '.vatId')}
                    {renderNonVatPayerNote(lastSelectionVat)}
                </td>
            </tr>
        )
    } else {
        return null
    }
}

const renderFields = (
    props: BusinessLookupProps,
    inProgress: boolean,
    lastSelectionVat: VatState,
) => {
    const { collectionName, inputs, inputValues, errorPrefix, valErrors, disabled } = props

    const afterChange = async (text: string) => lookupBusiness(collectionName, text, inProgress)

    return (
        <table className="table align-top">
            <tbody>
                <tr>
                    <td className="table__body-cell table__body-cell--no-left-pad table__body-cell--no-top-pad">
                        {t.business.name.get()}
                    </td>
                    <td className="table__body-cell table__body-cell--no-top-pad">
                        {renderInputOrValue(!disabled, {
                            input: inputs.name,
                            inputValues,
                            afterChange,
                            className: classnames('business-lookup__input', {
                                'business-lookup__input--search':
                                    inputs.name.get(inputValues) === '',
                            }),
                            disabled,
                        })}{' '}
                        {valErr(valErrors, errorPrefix + '.name')}
                    </td>
                </tr>
                <tr>
                    <td className="table__body-cell table__body-cell--no-left-pad">
                        {t.business.regCode.get()}
                    </td>
                    <td>
                        {renderInputOrValue(!disabled, {
                            input: inputs.regCode,
                            inputValues,
                            disabled,
                            className: 'business-lookup__input',
                        })}
                        {valErr(valErrors, errorPrefix + '.regCode')}
                    </td>
                </tr>
                <tr>
                    <td className="table__body-cell table__body-cell--no-left-pad">
                        {t.business.address.get()}
                    </td>
                    <td>
                        {renderInputOrValue(!disabled, {
                            type: 'multiline',
                            input: inputs.address,
                            inputValues,
                            disabled,
                            className: 'business-lookup__input',
                        })}
                        {valErr(valErrors, errorPrefix + '.address')}
                    </td>
                </tr>
                {renderVatId(props, lastSelectionVat)}
            </tbody>
        </table>
    )
}

const isPrevBusiness = (business: OtherBusiness | PrevBusiness): business is PrevBusiness => {
    return 'invoiceCount' in business
}

const renderNote = (business: OtherBusiness | PrevBusiness) => {
    if (isPrevBusiness(business)) {
        return (
            <span className="business-lookup__match-note">
                {t.businessLookup.invoiceCount.get()}
                {': '}
                {business.invoiceCount}
            </span>
        )
    } else {
        return null
    }
}

const renderMatch = (
    business: OtherBusiness | PrevBusiness,
    search: string,
    inputs: BusinessInputs,
) => {
    const onClick = () => selectBusiness(business, inputs)

    return (
        <div onClick={onClick} className="business-lookup__match">
            {highlight(business.name, search)}
            {renderNote(business)}
        </div>
    )
}

const renderMatchSection = (
    businesses: Array<OtherBusiness | PrevBusiness>,
    search: string,
    inputs: BusinessInputs,
) => {
    if (!businesses.length) {
        return null
    }

    return (
        <div className="business-lookup__match-section">
            {businesses.map((business) => renderMatch(business, search, inputs))}
        </div>
    )
}

const renderMatches = (props: BusinessLookupProps, inProgress: boolean) => {
    const {
        businessLookup: { previous, other, search },
        inputs,
    } = props
    const count = previous.length + other.length

    if (inProgress) {
        return (
            <div className="business-lookup__matches-title">
                <LoadingIcon color="black" size={16} />
            </div>
        )
    }

    if (!count) {
        return null
    }

    return (
        <div>
            <div className="business-lookup__matches-title">{t.businessLookup.title.get()}:</div>
            <div className="business-lookup__matches business-lookup__matches--popup">
                {renderMatchSection(previous, search, inputs)}
                {renderMatchSection(other, search, inputs)}
            </div>
        </div>
    )
}

export const BusinessLookup: FC<BusinessLookupProps> = (props) => {
    const { businessLookup, processes } = props
    const inProgress = processes.has('business-lookup')

    return (
        <div className="business-lookup">
            {renderFields(props, inProgress, businessLookup.lastSelectionVat)}
            {renderMatches(props, inProgress)}
        </div>
    )
}
