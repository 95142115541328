import { Set as ImmutableSet } from 'immutable'
import React, { FC, ReactNode } from 'react'

import { commandNamesArray, UrlCommand, urlCommands } from '../../common/enums'
import { keys } from '../../common/keys'
import { pluralize } from '../../common/pluralize'
import { Time } from '../../common/time'
import { CommandName } from '../../common/types/api'
import { ApiCommand, ErrorInfo } from '../../common/types/command'
import { ChoiceOption, InputValues } from '../../common/types/inputs'
import { PaymentInput } from '../../common/types/payment'
import { Column } from '../../common/types/table'
import { inputs } from '../inputs'
import { renderJson } from '../render-json'
import { shortenIpv6 } from '../shorten-ipv6'
import { applyFilters, processErrors, toggle } from '../state/command-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { renderChoice } from './choice'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'
import { LoadingPage } from './loading-page'
import { type BaseRow, renderTable } from './table'
import { TimeRange } from './time-range'

type Obj = Record<string, any>

interface RegularRow extends BaseRow, ApiCommand {
    isDetails: false
    className: string
}

interface DetailRow extends BaseRow {
    isDetails: true
    id: string
    command: ApiCommand
}

type Row = RegularRow | DetailRow

// TODO multi-choice filter for status (success/warning/failure)
// TODO checkboxes for selecting shown columns? (add client version, server version etc)

// When changing this, make sure command resolver can filter all cases properly
const OBJECT_OPS = {
    addExpensePayment: { type: 'expense', op: 'payment' },
    addInvoicePayment: { type: 'invoice', op: 'payment' },
    addLabourPayment: { type: 'labourCost', op: 'payment' },
    confirmExpense: { type: 'expense', op: 'confirm' },
    confirmInvoice: { type: 'invoice', op: 'confirm' },
    confirmLabourCost: { type: 'labourCost', op: 'confirm' },
    createAccount: { type: 'account', op: 'create' },
    createEntry: { type: 'entry', op: 'create' },
    createExpense: { type: 'expense', op: 'create' },
    createInvoice: { type: 'invoice', op: 'create' },
    createLabourCost: { type: 'labourCost', op: 'create' },
    createUser: { type: 'user', op: 'create' },
    initFirstPayment: { type: 'cardPayment', op: 'create' },
    removeEntry: { type: 'entry', op: 'remove' },
    removeExpense: { type: 'expense', op: 'remove' },
    removeInvoice: { type: 'invoice', op: 'remove' },
    removeLabourCost: { type: 'labourCost', op: 'remove' },
    removeUser: { type: 'user', op: 'remove' },
    simulateCardPayment: { type: 'cardPayment', op: 'create' },
    updateExpense: { type: 'expense', op: 'update' },
    updateInvoice: { type: 'invoice', op: 'update' },
    updateGeneralSettings: { type: 'settings', op: 'update' },
    updateVatSettings: { type: 'settings', op: 'update' },
}

type ObjectOps = typeof OBJECT_OPS
type ObjectOpName = keyof ObjectOps

const renderAlert = (title: string) => (
    <span className="alert" title={title}>
        !
    </span>
)

const renderVersionMismatch = ({ clientVersion, serverVersion, command }: RegularRow) => {
    if ((clientVersion !== '' || command) && clientVersion !== serverVersion) {
        return renderAlert('Client/server version mismatch')
    } else {
        return null
    }
}

const renderUnprocessedInfo = ({ total, errors }: ErrorInfo) => {
    if (total > 0) {
        let action

        if (errors.length) {
            action = <div>They can not be automatically processed.</div>
        } else {
            action = (
                <Button onClick={processErrors} text="Process errors" className="button--primary" />
            )
        }

        return (
            <div id="unprocessed-info" className="bottom-margin">
                <div>There are some unprocessed errors.</div>
                {action}
            </div>
        )
    } else {
        return null
    }
}

const renderTimeFilter = (inputValues: InputValues) => {
    if (inputs.commandLog.filterTime.get(inputValues)) {
        return (
            <div className="bottom-margin">
                <TimeRange input={inputs.commandLog.time} inputValues={inputValues} />
            </div>
        )
    } else {
        return null
    }
}

const renderIpFilter = (inputValues: InputValues) => {
    if (inputs.commandLog.filterIp.get(inputValues)) {
        return <Input input={inputs.commandLog.ip} inputValues={inputValues} />
    } else {
        return null
    }
}

const renderUserFilter = (inputValues: InputValues) => {
    if (inputs.commandLog.filterUser.get(inputValues)) {
        return <Input input={inputs.commandLog.user} inputValues={inputValues} />
    } else {
        return null
    }
}

// TODO multi-choice? forceSelection? Or just freeform text?
const renderCommandFilter = (inputValues: InputValues) => {
    if (inputs.commandLog.filterCommand.get(inputValues)) {
        const options = [...commandNamesArray, ...urlCommands].map(
            (commandName): ChoiceOption<CommandName | UrlCommand> => ({
                id: commandName,
                label: commandName,
            }),
        )

        return renderChoice<CommandName | UrlCommand>({
            input: inputs.commandLog.command,
            inputValues,
            type: 'dropdown',
            options,
        })
    } else {
        return null
    }
}

const renderObjectFilter = (inputValues: InputValues) => {
    if (inputs.commandLog.filterObject.get(inputValues)) {
        const types = new Set<string>()

        for (const key of keys(OBJECT_OPS)) {
            types.add(OBJECT_OPS[key].type)
        }

        const sorted = Array.from(types).sort()
        const options = sorted.map((type) => ({ id: type, label: type }))

        return (
            <span>
                {renderChoice({
                    input: inputs.commandLog.object.type,
                    inputValues,
                    type: 'dropdown',
                    options,
                })}{' '}
                <Input input={inputs.commandLog.object.id} inputValues={inputValues} />
            </span>
        )
    } else {
        return null
    }
}

const renderFilters = (inputValues: InputValues) => (
    <div style={{ marginBottom: '1em' }}>
        <div>
            <Checkbox input={inputs.commandLog.filterTime} inputValues={inputValues} />
            {' Filter by time'}
        </div>
        {renderTimeFilter(inputValues)}
        <div>
            <Checkbox input={inputs.commandLog.filterIp} inputValues={inputValues} />
            {' Filter by IP '}
            {renderIpFilter(inputValues)}
        </div>
        <div>
            <Checkbox input={inputs.commandLog.filterUser} inputValues={inputValues} />
            {' Filter by user '}
            {renderUserFilter(inputValues)}
        </div>
        <div>
            <Checkbox input={inputs.commandLog.filterCommand} inputValues={inputValues} />
            {' Filter by command '}
            {renderCommandFilter(inputValues)}
        </div>
        <div>
            <Checkbox input={inputs.commandLog.filterObject} inputValues={inputValues} />
            {' Filter by object '}
            {renderObjectFilter(inputValues)}
        </div>
    </div>
)

// TODO scroll to top after clicking on a filter link?

const ipFilterLink = (ip: string) => {
    const onClick = () => {
        inputs.commandLog.filterIp.set(true)
        inputs.commandLog.ip.set(ip)
    }

    // Full IPv6 addresses take up too much screen space, so they are shortened.
    // The full address can be seen by hovering the mouse over the shortened one
    // or expanding the log entry details.
    return (
        <a onClick={onClick} title={ip}>
            {shortenIpv6(ip)}
        </a>
    )
}

const userFilterLink = (userId?: string, displayName?: string | null) => {
    if (!userId) {
        return null
    }

    const onClick = () => {
        inputs.commandLog.filterUser.set(true)
        inputs.commandLog.user.set(userId)
    }

    return (
        <span>
            <a onClick={onClick} title={userId}>
                {displayName || '(' + userId + ')'}
            </a>
        </span>
    )
}

const companyFilterLink = (companyId?: string | null) => {
    return <span>{companyId}</span> // TODO filter link
}

const commandFilterLink = (command: CommandName) => {
    const onClick = () => {
        inputs.commandLog.filterCommand.set(true)
        inputs.commandLog.command.set(command)
    }

    return <a onClick={onClick}>{command}</a>
}

const objectFilterLink = (type: string, id: string) => {
    const onClick = () => {
        inputs.commandLog.filterObject.set(true)
        inputs.commandLog.object.type.set(type)
        inputs.commandLog.object.id.set(id)
    }

    return <a onClick={onClick}>{id}</a>
}

const renderPaymentParam = (payment: PaymentInput, type: string, id: string) => (
    <span>
        {payment.amount}
        {' on '}
        {objectFilterLink(type, id)}
    </span>
)

const limitLength = (value: string, maxLength: number = 40) => {
    return value.length > maxLength ? value.substr(0, maxLength) + '...' : value
}

const renderParams = (row: RegularRow): ReactNode => {
    const { fromError, processed, command, url } = row
    const params: unknown = row.params

    if (url?.startsWith('/confirm-user/')) {
        return limitLength(url.substr(14), 20)
    }

    if (fromError && !processed) {
        return '(unprocessed)'
    }

    const paramsObj: Obj = params && typeof params === 'object' ? params : {}

    if (command && command in OBJECT_OPS) {
        const opName = command as ObjectOpName
        const { op, type } = OBJECT_OPS[opName]

        if (op === 'create') {
            return '...'
        } else if (op === 'update') {
            if (type === 'settings') {
                return null
            } else if (typeof paramsObj.id === 'string') {
                return (
                    <span>
                        {'Update '}
                        {objectFilterLink(type, paramsObj.id)}
                    </span>
                )
            }
        } else if (op === 'confirm' && 'id' in paramsObj && typeof paramsObj.id === 'string') {
            return (
                <span>
                    {'Confirm '}
                    {objectFilterLink(type, paramsObj.id)}
                </span>
            )
        } else if (op === 'remove' && 'id' in paramsObj && typeof paramsObj.id === 'string') {
            return (
                <span>
                    {'Remove '}
                    {objectFilterLink(type, paramsObj.id)}
                </span>
            )
        } else if (
            op === 'payment' &&
            typeof paramsObj.id === 'string' &&
            typeof paramsObj.payment === 'object'
        ) {
            return renderPaymentParam(paramsObj.payment, type, paramsObj.id)
        }
    }

    if (
        (command === CommandName.login || command === CommandName.initPasswordReset) &&
        'email' in paramsObj
    ) {
        return paramsObj.email
    } else if (command === CommandName.resetPassword && typeof paramsObj.resetCode === 'string') {
        return limitLength(paramsObj.resetCode, 20)
    } else if (command === CommandName.selectCompany) {
        return paramsObj.id // TODO company filter link (and filter logic in resolver)
    } else if (
        command === CommandName.signUp &&
        typeof paramsObj.user === 'object' &&
        paramsObj.user &&
        typeof paramsObj.user.email === 'string'
    ) {
        return limitLength(paramsObj.user.email)
    } else if (command === CommandName.load && paramsObj.resolver) {
        return 'Load ' + paramsObj.resolver
    } else if (command === CommandName.updateInitDate && typeof paramsObj.date === 'string') {
        return paramsObj.date
    } else if (command === CommandName.initCardTransaction && typeof paramsObj.type === 'string') {
        return paramsObj.type
    } else if (command === CommandName.inviteUser && typeof paramsObj.email === 'string') {
        return paramsObj.email
    } else if (command === CommandName.acceptInvite && typeof paramsObj.inviteId === 'string') {
        return paramsObj.inviteId
    } else if (command === CommandName.updateUserRole && typeof paramsObj.userId === 'string') {
        return userFilterLink(paramsObj.userId)
    } else if (command === CommandName.removeUserFromCompany && typeof paramsObj.id === 'string') {
        return userFilterLink(paramsObj.id)
    } else if (
        command === CommandName.createAssetChange &&
        typeof paramsObj.expenseId === 'string' &&
        typeof paramsObj.assetId === 'string'
    ) {
        return (
            <span>
                {'For '}
                {objectFilterLink('expense', paramsObj.expenseId)}/{paramsObj.assetId}
            </span>
        )
    } else if (
        command === CommandName.updateAssetChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Update ' + paramsObj.changeId
    } else if (
        command === CommandName.removeAssetChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Remove ' + paramsObj.changeId
    } else if (
        command === CommandName.confirmAssetChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Confirm ' + paramsObj.changeId
    } else if (command === CommandName.createStockChange && Array.isArray(paramsObj.items)) {
        return pluralize(paramsObj.items.length, 'item', 'items')
    } else if (
        command === CommandName.updateStockChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Update ' + paramsObj.changeId
    } else if (
        command === CommandName.removeStockChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Remove ' + paramsObj.changeId
    } else if (
        command === CommandName.confirmStockChange &&
        typeof paramsObj.changeId === 'string'
    ) {
        return 'Confirm ' + paramsObj.changeId
    } else if (command === CommandName.getBalanceReport && Array.isArray(paramsObj.dates)) {
        return pluralize(paramsObj.dates.length, 'date', 'dates')
    } else if (
        (command === CommandName.getIncomeReport || command === CommandName.getCashFlowReport) &&
        Array.isArray(paramsObj.periods)
    ) {
        return pluralize(paramsObj.periods.length, 'period', 'periods')
    } else if (
        command === CommandName.addExpenseFile &&
        typeof paramsObj.filename === 'string' &&
        typeof paramsObj.expenseId === 'string'
    ) {
        return (
            <span>
                {limitLength(paramsObj.filename)}
                {' on '}
                {objectFilterLink('expense', paramsObj.expenseId)}
            </span>
        )
    } else if (command === CommandName.removeExpenseFile && typeof paramsObj.hash === 'string') {
        return (
            <span>
                {limitLength(paramsObj.hash, 20)}
                {' on '}
                {objectFilterLink('expense', paramsObj.expenseId)}
            </span>
        )
    } else if (command === CommandName.assignVatMonth && typeof paramsObj.month === 'string') {
        return paramsObj.month
    } else if (
        command === CommandName.removeExpensePayment &&
        typeof paramsObj.expenseId === 'string' &&
        typeof paramsObj.paymentId === 'string'
    ) {
        return (
            <span>
                {objectFilterLink('expense', paramsObj.expenseId)}
                {' / '}
                {paramsObj.paymentId}
            </span>
        )
    } else if (
        command === CommandName.removeLabourPayment &&
        typeof paramsObj.labourCostId === 'string' &&
        typeof paramsObj.type === 'string' &&
        typeof paramsObj.paymentId === 'string'
    ) {
        return (
            <span>
                {objectFilterLink('labourCost', paramsObj.labourCostId)}
                {' / '}
                {paramsObj.type} {paramsObj.paymentId}
            </span>
        )
    } else if (
        command === CommandName.removeRevenuePayment &&
        typeof paramsObj.revenueId === 'string' &&
        typeof paramsObj.paymentId === 'string'
    ) {
        return (
            <span>
                {objectFilterLink('invoice', paramsObj.revenueId)}
                {' / '}
                {paramsObj.paymentId}
            </span>
        )
    } else if (
        command === CommandName.getTurnoverReport ||
        command === CommandName.removeCompanyLogo ||
        command === CommandName.uploadCompanyLogo ||
        command === CommandName.createCompany ||
        command === CommandName.updateCompanyGeneral ||
        command === CommandName.updateBillingDetails ||
        command === CommandName.updateInterimBalance ||
        command === CommandName.updateNote ||
        command === CommandName.updateInvoiceSettings ||
        command === CommandName.updateSelf ||
        command === CommandName.addVatPayment
    ) {
        return '...'
    } else if (params === null || params === undefined) {
        return ''
    }

    return <div className="raw-json">{renderJson(params)}</div>
}

const isValidationFailure = (success: boolean, response: any) =>
    !success &&
    response &&
    typeof response === 'object' &&
    response.errorCode === 'validation-failed'

const isSessionExpirationFailure = (success: boolean, response: any) =>
    !success && response && typeof response === 'object' && response.errorCode === 'session-expired'

const renderResponse = (row: RegularRow): ReactNode => {
    const { fromError, processed, command, url } = row
    const response: unknown = row.response

    if (fromError && !processed) {
        return '(unprocessed)'
    }

    const success = Boolean(row.success)
    const resObj: Obj = response && typeof response === 'object' ? response : {}

    if (url?.startsWith('/confirm-user/') && typeof resObj.status === 'string') {
        const userInfo =
            typeof resObj.userId === 'string' && typeof resObj.displayName === 'string'
                ? userFilterLink(resObj.userId, resObj.displayName)
                : String(resObj.email)

        return (
            <>
                {resObj.status}
                {' for '}
                {userInfo}
            </>
        )
    }

    if (success && command && command in OBJECT_OPS) {
        const opName = command as ObjectOpName
        const { op, type } = OBJECT_OPS[opName]

        if (op === 'create' && typeof response === 'string') {
            return (
                <>
                    {'Created '}
                    {objectFilterLink(type, response)}
                </>
            )
        } else if (
            (op === 'update' || op === 'confirm' || op === 'payment') &&
            typeof response === 'number'
        ) {
            return 'New revision: ' + response
        } else if (op === 'remove') {
            return '...'
        }
    }

    if (isValidationFailure(success, response)) {
        return 'Validation failed'
    } else if (isSessionExpirationFailure(success, response)) {
        return 'Session expired'
    } else if (command === CommandName.login && success) {
        if (typeof resObj.userId !== 'string') {
            // TODO clean live db and remove this
            return 'ERROR'
        }

        const parts: ReactNode[] = [userFilterLink(resObj.userId, resObj.displayName)]

        if (resObj.companyId) {
            parts.push(' at ', resObj.companyId) // TODO company filter link
        }

        return <span>{parts}</span>
    } else if (command === CommandName.selectCompany && success) {
        return 'Role: ' + resObj.companyRole
    } else if (command === CommandName.signUp && success && typeof response === 'string') {
        return limitLength(response, 20)
    } else if (command === CommandName.initPasswordReset && typeof resObj.resetCode === 'string') {
        return limitLength(resObj.resetCode, 20)
    } else if (command === CommandName.resetPassword && typeof resObj.userId === 'string') {
        return userFilterLink(resObj.userId, resObj.displayName)
    } else if (command === CommandName.renewSession && success) {
        return 'Extended until ' + Time.fromIso(resObj.expires).asLocal().hms()
    } else if (
        command === CommandName.logout &&
        success &&
        resObj &&
        Array.isArray(resObj.redactedIds)
    ) {
        const { length: count } = resObj.redactedIds
        return 'Cleared ' + pluralize(count, 'session', 'sessions')
    } else if (
        command === CommandName.createAccount &&
        success &&
        resObj &&
        typeof resObj.id === 'string'
    ) {
        return (
            <span>
                {'Created '}
                {objectFilterLink('account', resObj.id)}
                {' ('}
                {resObj.number})
            </span>
        )
    } else if (
        command === CommandName.createAssetChange &&
        success &&
        typeof response === 'string'
    ) {
        return 'Created ' + response
    } else if (
        (command === CommandName.confirmAssetChange ||
            command === CommandName.updateAssetChange ||
            command === CommandName.removeAssetChange) &&
        success &&
        resObj &&
        typeof resObj.expenseId === 'string' &&
        typeof resObj.assetId === 'string'
    ) {
        return (
            <span>
                {'For '}
                {objectFilterLink('expense', resObj.expenseId)}/{resObj.assetId}
            </span>
        )
    } else if (
        command === CommandName.createStockChange &&
        success &&
        typeof response === 'string'
    ) {
        return 'Created ' + response
    } else if (
        command === CommandName.confirmStockChange &&
        success &&
        Array.isArray(resObj.expenseIds)
    ) {
        return 'Updated ' + pluralize(resObj.expenseIds.length, 'expense', 'expenses')
    } else if (
        (command === CommandName.removeCompanyLogo ||
            command === CommandName.updateStockChange ||
            command === CommandName.uploadCompanyLogo ||
            command === CommandName.updateCompanyGeneral ||
            command === CommandName.updateInitDate ||
            command === CommandName.updateBillingDetails ||
            command === CommandName.updateInterimBalance ||
            command === CommandName.updateNote ||
            command === CommandName.removeExpensePayment ||
            command === CommandName.removeLabourPayment ||
            command === CommandName.removeRevenuePayment) &&
        success &&
        resObj &&
        typeof response === 'number'
    ) {
        return 'New revision: ' + response
    } else if (
        success &&
        command === CommandName.processErrors &&
        Array.isArray(resObj.processed)
    ) {
        return 'Processed ' + resObj.processed.length
    } else if (
        success &&
        command === CommandName.createCompany &&
        typeof resObj.companyId === 'string'
    ) {
        return 'Created ' + resObj.companyId
    } else if (
        (command === CommandName.initCardTransaction ||
            url.startsWith('/payment/check?') ||
            // Older entries may have /everypay URLs
            url.startsWith('/everypay/')) &&
        typeof resObj.paymentId === 'string'
    ) {
        const isNew = command === CommandName.initCardTransaction

        return (
            <>
                {isNew ? 'Created' : 'Updated'} {objectFilterLink('cardPayment', resObj.paymentId)}
                {isNew || typeof resObj.status !== 'string' ? null : ' (' + resObj.status + ')'}
            </>
        )
    } else if (
        success &&
        (command === CommandName.removeStockChange ||
            command === CommandName.activateCompany ||
            command === CommandName.archiveCompany ||
            command === CommandName.updateSelf ||
            command === CommandName.assignVatMonth)
    ) {
        return '...'
    } else if (!success && resObj.errorCode) {
        return resObj.errorCode
    } else if (typeof response === 'string') {
        if (command === CommandName.addExpenseFile) {
            return limitLength(response, 20)
        } else {
            return limitLength(response)
        }
    } else if (response === null) {
        return ''
    } else {
        return <div className="raw-json">{renderJson(response)}</div>
    }
}

const getColumns = (expanded: ImmutableSet<string>) => {
    const columns: Column<Row>[] = [
        {
            getProps: (row) =>
                row.isDetails
                    ? { colSpan: columns.length, className: 'details' }
                    : { className: 'toggle' },
            render: browserOnly((row) => {
                if (row.isDetails) {
                    // TODO button to toggle between highlighted version and copy-pastable plaintext?
                    return <div className="raw-json">{renderJson(row.command)}</div>
                } else {
                    const isExpanded = expanded.has(row.id) // TODO track expanded state in inputs?

                    return (
                        <span>
                            <button onClick={() => toggle(row.id)} className="button">
                                {isExpanded ? '-' : '+'}
                            </button>
                            {renderVersionMismatch(row)}
                        </span>
                    )
                }
            }),
        },
        {
            header: { content: 'Date' },
            getProps: () => ({ style: { whiteSpace: 'nowrap' } }),
            render: (row) => (row.isDetails ? '' : Time.fromIso(row.time).asLocal().ymd()),
        },
        {
            header: { content: 'Time' },
            render: (row) => (row.isDetails ? '' : Time.fromIso(row.time).asLocal().hms()),
        },
        {
            header: { content: 'IP' },
            render: browserOnly((row) => (row.isDetails ? null : ipFilterLink(row.ip))),
        },
        {
            header: { content: 'User' },
            render: browserOnly((row) => {
                if (row.isDetails) {
                    return null
                } else {
                    if (row.fromError && !row.processed) {
                        return '?'
                    }

                    return userFilterLink(row.userId!, row.displayName)
                }
            }),
        },
        {
            header: { content: 'Company' },
            render: browserOnly((row) => {
                if (row.isDetails) {
                    return null
                } else if (row.fromError && !row.processed) {
                    return '?'
                } else {
                    return companyFilterLink(row.companyId)
                }
            }),
        },
        {
            header: { content: 'Command' },
            render: browserOnly((row) => {
                if (!row.isDetails) {
                    if (row.command) {
                        return commandFilterLink(row.command)
                    } else if (row.url?.startsWith('/confirm-user/')) {
                        return '/confirm-user'
                    } else if (row.url?.startsWith('/payment/check?')) {
                        return '/payment/check'
                    }
                    // Older entries may have /everypay URLs
                    else if (row.url?.startsWith('/everypay/')) {
                        return row.url
                    }
                }

                return null
            }),
        },
        {
            header: { content: 'Params' },
            getProps: () => ({ className: 'params' }),
            render: browserOnly((row) => (row.isDetails ? null : renderParams(row))),
        },
        {
            header: { content: 'Response' },
            getProps: () => ({ className: 'response' }),
            render: browserOnly((row) => (row.isDetails ? null : renderResponse(row))),
        },
    ]

    return columns
}

const getRowClass = (command: ApiCommand) => {
    const { fromError, processed } = command
    const response: unknown = command.response
    const success = Boolean(command.success)

    if (fromError && !processed) {
        return 'unprocessed'
    } else if (
        isValidationFailure(success, response) ||
        isSessionExpirationFailure(success, response)
    ) {
        return 'warning'
    } else {
        return success ? 'success' : 'failure'
    }
}

const getRows = (commands: ApiCommand[], expanded: ImmutableSet<string>) => {
    const rows: Row[] = []

    for (const command of commands) {
        rows.push({ isDetails: false, ...command, className: getRowClass(command) })

        if (expanded.has(command.id)) {
            rows.push({ id: command.id + '-expanded', isDetails: true, command })
        }
    }

    return rows
}

const renderLogTable = (commands: ApiCommand[] | null, expanded: ImmutableSet<string>) => {
    if (commands) {
        const columns = getColumns(expanded)
        const rows = getRows(commands, expanded)

        return (
            <div>
                <div className="bottom-margin">
                    <Button
                        onClick={applyFilters}
                        text="Apply filters"
                        className="button--primary"
                    />
                </div>
                {renderTable({
                    columns,
                    rows,
                    noWrapper: true,
                    stickyHeader: true,
                    domId: 'command-log',
                    tableClassName: 'main-table',
                })}
            </div>
        )
    } else {
        return <LoadingIcon color="black" />
    }
}

export const CommandLog: FC<RootData> = (rootData) => {
    const {
        commandData: { commands, errorInfo, expanded },
        formsReady,
        inputValues,
    } = rootData

    if (formsReady.has('command-filter') && commands && errorInfo) {
        return (
            <div className="content-area">
                <div className="content">
                    {renderUnprocessedInfo(errorInfo)}
                    {renderFilters(inputValues)}
                    {renderLogTable(commands, expanded)}
                </div>
            </div>
        )
    } else {
        return <LoadingPage />
    }
}
