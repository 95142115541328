import React, { FC } from 'react'

import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { ARCHIVE_PROCESS, archiveCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button, ButtonProps } from '../button'
import { Input } from '../input'
import { LoadingIcon } from '../loading-icon'

const renderButton = (isConfirmed: boolean, processes: Processes) => {
    const props: ButtonProps = {
        text: t.settings.closeAccount.close.get(),
        className: 'settings__close-account-button',
    }

    if (isConfirmed) {
        props.onClick = archiveCompany
        props.processes = processes
        props.processName = ARCHIVE_PROCESS
    } else {
        props.disabled = true
    }

    return <Button {...props} />
}

const renderContent = (rootData: RootData) => {
    const {
        inputValues,
        processes,
        userData: { usersLimited: users },
    } = rootData
    const canArchive = users && users.length === 1

    if (canArchive) {
        const confirmInput = inputs.settings.closeAccount.confirm
        const confirmText = t.settings.closeAccount.confirm.get()
        const isConfirmed = confirmInput.get(inputValues) === confirmText

        return (
            <>
                <div className="text-warning">{t.settings.closeAccount.warning.get()}</div>
                <div className="text-warning">
                    {t.settings.closeAccount.confirmInstructions.get(confirmText)}:
                </div>
                <div className="top-margin">
                    <Input input={confirmInput} inputValues={inputValues} />
                </div>
                <div className="top-margin">{renderButton(isConfirmed, processes)}</div>
            </>
        )
    } else {
        return (
            <div className="text-multiline">{t.settings.closeAccount.usersStillLinked.get()}</div>
        )
    }
}

export const CloseCompanyAccount: FC<RootData> = (rootData) => {
    const {
        userData: { usersLimited: users },
    } = rootData

    if (!users) {
        return <LoadingIcon color="black" />
    }

    return (
        <div>
            <h1 className="title">{t.settings.closeAccount.get()}</h1>
            {renderContent(rootData)}
        </div>
    )
}
