import classnames from 'classnames'
import numeral from 'numeral'
import React, { FC } from 'react'
import DayPicker, { DayPickerProps } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

import { Day } from '../../common/time'
import { CompanyData } from '../../common/types/company'
import { DashboardData } from '../../common/types/dashboard'
import { InputValues } from '../../common/types/inputs'
import { Note } from '../../common/types/note'
import { Processes } from '../../common/types/processes'
import { ApiSession } from '../../common/types/session'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { getCompany } from '../state/company-actions'
import { SAVE_NOTE_PROCESS, saveNote, setDate } from '../state/dashboard-actions'
import { RootData } from '../state/root-data'
import { Button } from './button'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'

const monthInput = inputs.dashboard.month

const classNames = {
    // Removed many of DayPicker's default classes, added some dashboard-specific ones
    container: '',
    wrapper: 'dashboard__day-picker-wrapper',
    interactionDisabled: '',
    months: 'DayPicker-Months',
    month: '',

    navBar: '',
    navButtonPrev: '',
    navButtonNext: '',
    navButtonInteractionDisabled: '',

    caption: '',
    weekdays: 'DayPicker-Weekdays',
    weekdaysRow: 'DayPicker-WeekdaysRow',
    weekday: 'DayPicker-Weekday dashboard__weekday',
    body: 'DayPicker-Body',
    week: 'DayPicker-Week',
    weekNumber: '',
    day: 'DayPicker-Day dashboard__day',
    footer: '',
    todayButton: '',

    today: 'dashboard__day--today',
    selected: 'dashboard__day--selected',
    disabled: 'dashboard__day--disabled',
    outside: 'dashboard__day--outside',
}

const renderVatWarningPanel = () => (
    <div className="dashboard__vat-warning dashboard-panel dashboard-panel--titled">
        <div className="dashboard-panel__title">{t.attention.get()}</div>
        <div className="dashboard-panel__content dashboard-panel__content--text">
            {t.dashboard.vatWarning.get()}
        </div>
        <a
            href={
                'https://www.emta.ee/et/ariklient/tulu-kulu-kaive-kasum/kaibemaksuseaduse-selgitused' +
                '/maksukohustuslasena-registreerimine-ja'
            }
            className="button button--transparent"
            target="_blank"
            rel="noopener nofollow noreferrer"
        >
            {t.view.get()}
        </a>
    </div>
)

const renderDatePanel = (date: string) => {
    const parsedDate = Day.fromYmd(date)

    return (
        <div className="dashboard__date dashboard-panel dashboard-panel--titled">
            <div className="dashboard-panel__title">{parsedDate.monthName()}</div>
            <div className="dashboard-panel__content dashboard-panel__content--numeric">
                {parsedDate.dayOfMonth()}
            </div>
        </div>
    )
}

const renderLoadingIcon = () => (
    <div className="dashboard-panel__content dashboard-panel__content--centered">
        <LoadingIcon color="white" size={50} />
    </div>
)

const renderNote = (note: Note | null, inputValues: InputValues, processes: Processes) => {
    if (!note) {
        return renderLoadingIcon()
    } else {
        return (
            <>
                <Input
                    input={inputs.note.text}
                    inputValues={inputValues}
                    type="multiline"
                    afterChange={() => inputs.note.hasChanged.set(true)}
                    className="dashboard-panel__notes-input"
                    disabled={processes.has(SAVE_NOTE_PROCESS)}
                />
                <div className="text-center">
                    <Button
                        text={t.save.get()}
                        className="button--transparent dashboard__wide-button"
                        onClick={saveNote}
                        disabled={!inputs.note.hasChanged.get(inputValues)}
                        processes={processes}
                        processName={SAVE_NOTE_PROCESS}
                        loadingColor="white"
                    />
                </div>
            </>
        )
    }
}

const renderNotesPanel = (note: Note | null, inputValues: InputValues, processes: Processes) => (
    <div className="dashboard__notes dashboard-panel dashboard-panel--titled">
        <div className="dashboard-panel__title">{t.dashboard.notes.get()}</div>
        {renderNote(note, inputValues, processes)}
    </div>
)

const formatDashboardAmount = (amount: number) => numeral(amount).format('0,0$')

const renderNumber = (number: number | undefined) => {
    if (number === undefined) {
        return renderLoadingIcon()
    }

    return (
        <div className="dashboard-panel__content dashboard-panel__content--numeric">
            {formatDashboardAmount(number)}
        </div>
    )
}

const renderRevenue = (loaded: DashboardData | null) => {
    if (!loaded) {
        return renderLoadingIcon()
    } else if (loaded.hasReportData) {
        return (
            <>
                {renderNumber(loaded.revenue)}
                <a href="#/reports/income" className="button button--transparent">
                    {t.view.get()}
                </a>
            </>
        )
    } else {
        return (
            <>
                <div className="dashboard__revenue-no-data dashboard-panel__content dashboard-panel__content--text">
                    {t.reports.noData.get()}
                </div>
                <a href="#/financial-history/balance/edit" className="button button--transparent">
                    {t.reports.noData.enter.get()}
                </a>
            </>
        )
    }
}

const onDayClick: DayPickerProps['onDayClick'] = async (day, modifiers) => {
    if (!modifiers[classNames.disabled]) {
        await setDate(Day.fromDate(day))
    }
}

const renderMonthLink = (text: string, enabled: boolean, onClick: () => void) => {
    const className = 'dashboard__link dashboard__link--nav'

    if (enabled) {
        return (
            <a onClick={onClick} className={className}>
                {text}
            </a>
        )
    } else {
        return <span className={classnames(className, 'dashboard__link--disabled')}>{text}</span>
    }
}

const renderCalendarHeader = (firstOfMonth: Day, minDate: Day, maxDate: Day) => {
    const canSelectPrevious = firstOfMonth.isAfter(minDate)

    const selectPreviousMonth = () => {
        monthInput.set(firstOfMonth.firstOfPreviousMonth().ym())
    }

    const firstOfNextMonth = firstOfMonth.firstOfNextMonth()
    const canSelectNext = firstOfNextMonth.isSameOrBefore(maxDate)
    const selectNextMonth = () => monthInput.set(firstOfNextMonth.ym())

    return (
        <div className="dashboard__calendar-header">
            {renderMonthLink('<', canSelectPrevious, selectPreviousMonth)}
            {firstOfMonth.longMonth()}
            {renderMonthLink('>', canSelectNext, selectNextMonth)}
        </div>
    )
}

const renderCalendarPanel = (
    selectedDate: string,
    disabled: boolean,
    inputValues: InputValues,
    companyData: CompanyData,
    session: ApiSession | null,
) => {
    if (!companyData.companies) {
        return (
            <div className="dashboard__calendar dashboard__calendar--loading dashboard-panel">
                {renderLoadingIcon()}
            </div>
        )
    }

    const company = getCompany(companyData, session)
    const minDate = Day.fromYmd(company.interimDate)
    const maxDate = Day.today()

    const monthString = monthInput.get(inputValues)
    const firstOfMonth = Day.fromYm(monthString)

    return (
        <div className="dashboard__calendar dashboard-panel">
            <div className="dashboard-panel__content">
                <DayPicker
                    canChangeMonth={true}
                    captionElement={() => renderCalendarHeader(firstOfMonth, minDate, maxDate)}
                    classNames={classNames}
                    disabledDays={{ before: minDate.toDate(), after: maxDate.toDate() }}
                    fromMonth={minDate.toDate()}
                    locale="et"
                    localeUtils={MomentLocaleUtils}
                    modifiers={{ [classNames.today]: Day.today().toDate() }}
                    month={firstOfMonth.toDate()}
                    navbarElement={() => null}
                    onDayClick={disabled ? undefined : onDayClick}
                    selectedDays={Day.fromYmd(selectedDate).toDate()}
                    toMonth={maxDate.toDate()}
                />
            </div>
        </div>
    )
}

export const Dashboard: FC<RootData> = (rootData) => {
    const {
        companyData,
        dashboardData: { date, loaded, note },
        inputValues,
        processes,
        session,
    } = rootData

    const showVatWarning = loaded ? loaded.showVatWarning : false

    return (
        <div className="dashboard">
            {showVatWarning ? renderVatWarningPanel() : renderDatePanel(date)}
            <div className="dashboard__revenue dashboard-panel dashboard-panel--titled">
                <div className="dashboard-panel__title">{t.dashboard.revenue.get()}</div>
                {renderRevenue(loaded)}
            </div>
            {renderNotesPanel(note, inputValues, processes)}
            <div className="dashboard__add-new dashboard-panel">
                <a href="#/invoices/add" className="dashboard__add-new-link">
                    <div className="dashboard__add-new-text">{t.addNew.get()}</div>
                    <img className="dashboard__add-new-icon" src="/dashboard/revenue.svg" />
                    <div className="dashboard__add-new-text">{t.revenues.invoice.get()}</div>
                </a>
                <a href="#/expenses/add/regular" className="dashboard__add-new-link">
                    <div className="dashboard__add-new-text">{t.addNew.get()}</div>
                    <img className="dashboard__add-new-icon" src="/dashboard/expense.svg" />
                    <div className="dashboard__add-new-text">{t.expenses.invoice.get()}</div>
                </a>
                <a href="#/expenses/add/asset" className="dashboard__add-new-link">
                    <div className="dashboard__add-new-text">{t.addNew.get()}</div>
                    <img className="dashboard__add-new-icon" src="/dashboard/asset.svg" />
                    <div className="dashboard__add-new-text">{t.asset.get()}</div>
                </a>
            </div>
            <div className="dashboard__unpaid-revenues dashboard-panel dashboard-panel--titled">
                <div className="dashboard-panel__title">{t.revenues.unpaidInvoices.get()}</div>
                {renderNumber(loaded ? loaded.unpaidRevenues : undefined)}
            </div>
            <div className="dashboard__unpaid-expenses dashboard-panel dashboard-panel--titled">
                <div className="dashboard-panel__title">{t.expenses.unpaidInvoices.get()}</div>
                {renderNumber(loaded ? loaded.unpaidExpenses : undefined)}
            </div>
            {renderCalendarPanel(date, !loaded, inputValues, companyData, session)}
        </div>
    )
}
