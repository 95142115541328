import React from 'react'

import { Day } from '../../common/time'
import { Column } from '../../common/types/table'
import { upperCaseFirst } from '../../common/upper-case-first'
import { ExcelNumberFormat } from '../excel/types'
import { t } from '../i18n'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { renderExcelMoney } from '../render-excel-money'
import { browserOnly } from '../table-utils'
import { DeleteIcon } from './delete-icon'
import { Link } from './link'
import { renderTable } from './table'
import { ViewIcon } from './view-icon'

export interface Row {
    className?: string
    onClick?: () => void
    isLand: boolean
    date: Day
    account: string
    description: string
    amortRate: number
    withoutVat: number
    depreciation: number
    eolDate?: Day
    residual: number
    confirmed: boolean
    viewRoute: string
    confirm?: () => void
    confirmMessage?: string
    remove?: () => void
    removeMessage?: string
}

interface Props {
    actionsVisible: boolean
    rows: Row[]
}

const alignRight = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const renderConfirmLink = (confirmFunc: () => void, message: string) => (
    <Link
        text={t.confirm.get()}
        // TODO custom confirmation UI
        onClick={() => (confirm(message) ? confirmFunc() : null)}
        className="confirm-link"
    />
)

const renderRemoveLink = (removeFunc: () => void, message: string) => {
    // TODO custom confirmation UI
    const onClick = () => (confirm(message) ? removeFunc() : null)
    return <DeleteIcon onClick={onClick} />
}

export const getColumns = (actionsVisible: boolean) => {
    const columns: Column<Row>[] = [
        {
            header: { content: t.invoices.date.get() },
            render: (row) => ({ browser: row.date.dmy(), excel: row.date }),
            excelWidth: 14,
        },
        {
            header: { content: t.account.get() },
            render: (row) => row.account,
            excelWidth: 25,
        },
        {
            header: { content: t.description.get() },
            render: (row) => row.description,
            excelWidth: 30,
        },
        {
            header: { content: t.assets.amortRate.get(), getProps: alignRight },
            getProps: alignRight,
            render: (row) => {
                const value = row.amortRate

                return {
                    browser: Math.round(value * 100) + '%',
                    excel: {
                        value: Math.round(value * 100) / 100,
                        // TODO new style for rounded percentage that does not show decimals?
                        style: { numberFormat: ExcelNumberFormat.percentage },
                    },
                }
            },
            excelWidth: 12,
        },
        {
            header: { content: t.assets.price.get(), getProps: alignRight },
            getProps: getAmountClass,
            render: (row) => ({
                browser: renderAmountOrDash(row.withoutVat),
                excel: renderExcelMoney(row.withoutVat, false),
            }),
            excelWidth: 18,
        },
        {
            header: { content: t.depreciation.get(), getProps: alignRight },
            getProps: getAmountClass,
            render: (row) => ({
                browser:
                    row.isLand && row.depreciation === 0 ? '-' : renderAmount(row.depreciation),
                excel: renderExcelMoney(row.depreciation, true),
            }),
            excelWidth: 15,
        },
        {
            header: { content: t.assets.residual.get(), getProps: alignRight },
            getProps: getAmountClass,
            render: (row) => ({
                browser: renderAmount(row.residual),
                excel: renderExcelMoney(row.residual, true),
            }),
            excelWidth: 15,
        },
        {
            header: { content: t.assets.eol.get() },
            render: (row) => {
                if (row.amortRate === 0) {
                    return { browser: '-', excel: '-' }
                } else {
                    return {
                        browser: upperCaseFirst(row.eolDate!.shortMonth()),
                        excel: {
                            value: row.eolDate!,
                            style: { numberFormat: ExcelNumberFormat.month },
                        },
                    }
                }
            },
            excelWidth: 16,
        },
    ]

    if (actionsVisible) {
        columns.push(
            {
                header: {
                    content: t.actions.get(),
                    getProps: () => ({ className: 'text-center' }),
                },
                getProps: () => ({ className: 'actions' }),
                render: browserOnly((row) => (
                    <span>
                        <ViewIcon href={row.viewRoute} />
                        {row.confirmed && <span>{t.confirmed.get()}</span>}
                        {!row.confirmed &&
                            row.confirm &&
                            renderConfirmLink(row.confirm, row.confirmMessage!)}
                    </span>
                )),
                hideInExcel: true,
            },
            {
                render: browserOnly((row) => {
                    return row.remove ? renderRemoveLink(row.remove, row.removeMessage!) : null
                }),
                hideInExcel: true,
            },
        )
    }

    return columns
}

export const AssetTable = ({ actionsVisible, rows }: Props) =>
    renderTable({
        columns: getColumns(actionsVisible),
        rows,
        stickyHeader: true,
        tableClassName: 'table table--bottom-border main-table',
        wrapperClassName: 'main-table-wrapper',
    })
