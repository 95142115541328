import React, { FC } from 'react'

import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { Link, LinkProps } from '../link'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface RevenueArchiveContentsProps {
    title: string
    itemType: ChoiceProps<string>
    discount: ChoiceProps<string>
    clearFiltersButton?: ButtonProps
    showLink: LinkProps
}

export const RevenueArchiveContents: FC<RevenueArchiveContentsProps> = (props) => {
    const { title, itemType, discount, showLink, clearFiltersButton } = props

    return (
        <div className="content-area">
            <RevenueArchiveSidebar selected="contents" />
            <div className="content archive">
                <div>
                    <h1 className="title archive__title">{title}</h1>
                    <div>
                        {renderChoice({
                            ...itemType,
                            buttonClassName: 'button--secondary',
                            selectedButtonClassName: 'button--primary',
                        })}
                    </div>
                    <div className="top-margin">
                        {renderChoice({
                            ...discount,
                            buttonClassName: 'button--secondary',
                            selectedButtonClassName: 'button--primary',
                        })}
                    </div>
                </div>
                <div className="top-margin">
                    <Link {...showLink} className="button button--primary" />{' '}
                    {clearFiltersButton && <Button {...clearFiltersButton} />}
                </div>
            </div>
        </div>
    )
}
