import React, { FC } from 'react'

import { isSuccessful } from '../../../common/card-payment-utils'
import { CardPaymentStatus } from '../../../common/enums'
import { findByDbId } from '../../../common/find-by-db-id'
import { ApiCardPayment } from '../../../common/types/card-payment'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { RootData } from '../../state/root-data'
import { BackLink } from '../back-link'
import { LoadingPage } from '../loading-page'

const renderStatus = (payment: ApiCardPayment | undefined) => {
    if (!payment) {
        return t.settings.billing.updateCard.paymentNotFound.get()
    }

    const { status } = payment

    if (isSuccessful(status)) {
        return t.settings.billing.updateCard.success.get()
    } else if (status === CardPaymentStatus.initial) {
        return t.settings.billing.updateCard.inProgress.get()
    } else {
        return t.settings.billing.updateCard.failed.get()
    }
}

export const CardUpdateStatus: FC<RootData> = (rootData) => {
    const { companyData, view } = rootData

    if (!companyData.companies || !companyData.cardPayments) {
        return <LoadingPage />
    }

    const { pageParams } = assertViewName(view, 'Settings')
    const id = pageParams[1]
    const payment = findByDbId(companyData.cardPayments, id)

    return (
        <>
            <BackLink route="/#/settings/billing" />
            {renderStatus(payment)}
        </>
    )
}
