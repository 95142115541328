import React, { FC } from 'react'

import { Button, ButtonProps } from './button'

export interface ExcelButtonProps {
    button: ButtonProps
    progressText?: string
}

export const ExcelButton: FC<ExcelButtonProps> = ({ button, progressText }) => (
    <span>
        <Button {...button} />
        {progressText && <span> {progressText}</span>}
    </span>
)
