import React, { FC } from 'react'

import { isSuccessful } from '../../../common/card-payment-utils'
import { CardPaymentStatus } from '../../../common/enums'
import { findByDbId } from '../../../common/find-by-db-id'
import { Time } from '../../../common/time'
import { ApiCardPayment } from '../../../common/types/card-payment'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { renderAmount } from '../../render-amount'
import { ACTIVATE_PROCESS, activateCompany, getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

// TODO i18n

const renderStatus = (status: CardPaymentStatus) => {
    if (isSuccessful(status)) {
        return 'Õnnestunud'
    } else if (status === CardPaymentStatus.initial) {
        return 'Pooleli'
    } else {
        return 'Ebaõnnestunud'
    }
}

const renderActivateButton = (isVisible: boolean, processes: Processes) => {
    if (!isVisible) {
        return null
    }

    return (
        <div>
            <Button
                onClick={activateCompany}
                text="Hakka programmi kasutama"
                processes={processes}
                processName={ACTIVATE_PROCESS}
                className="button--white init-company__payment-button"
                loadingColor="white"
            />
        </div>
    )
}

const renderRetryButton = (isVisible: boolean) => {
    if (!isVisible) {
        return null
    }

    return (
        <div>
            <a
                href="#/init-company/billing"
                className="button button--white init-company__payment-button"
            >
                Proovi uuesti
            </a>
        </div>
    )
}

const renderPayment = (payment: ApiCardPayment | undefined, processes: Processes) => {
    if (!payment) {
        return <div>Makset ei õnnestunud leida</div>
    }

    const { amountWithVat, status, success, initTime } = payment

    return (
        <>
            <div>{Time.fromIso(initTime).asLocal().longDateTime()}</div>
            <div className="init-company__payment-sub-title">SUMMA</div>
            <div>{renderAmount(amountWithVat)} koos käibemaksuga</div>
            <div className="init-company__payment-sub-title">STAATUS</div>
            <div>{renderStatus(status)}</div>
            <div className="init-company__button-row">
                {renderActivateButton(success, processes)}
                {
                    // TODO reload info button if status === new?
                    renderRetryButton(status !== CardPaymentStatus.initial && !success)
                }
            </div>
        </>
    )
}

export const InitCompanyPaymentStatus: FC<RootData> = (rootData) => {
    const { companyData, processes, session, view } = rootData

    if (!companyData.companies || !companyData.cardPayments) {
        return <LoadingPage />
    }

    const company = getCompany(companyData, session)
    const { id } = assertViewName(view, 'InitCompanyPaymentStatus')
    const payment = findByDbId(companyData.cardPayments, id)

    return (
        <div className="init-company">
            <InitCompanyNav company={company} />
            <div className="init-company__main">
                <div className="init-company__main-inner init-company__payment-status">
                    <div className="init-company__payment-title">MAKSE</div>
                    {renderPayment(payment, processes)}
                </div>
            </div>
        </div>
    )
}
