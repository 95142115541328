import React, { FC } from 'react'

import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { setRoute } from '../../route-utils'
import { RootData } from '../../state/root-data'
import { loadLogoInputFile, REMOVE_LOGO_PROCESS, removeLogo } from '../../state/settings-actions'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { Logo } from './logo'
import { LogoInput } from './logo-input'
import { LogoNotes } from './logo-notes'

const loadInputFile = async (file: File) => {
    setRoute('#/settings/logo/edit')
    await loadLogoInputFile(file)
}

const renderDeleteButton = (processes: Processes) => (
    <Button
        className="button--secondary"
        onClick={() => (confirm(t.confirm.removeLogo.get()) ? removeLogo() : null)}
        text={t.remove.get()}
        processes={processes}
        processName={REMOVE_LOGO_PROCESS}
        domId="remove"
    />
)

const renderButtons = (processes: Processes, logoUrl?: string) => {
    const deleteButton = logoUrl ? renderDeleteButton(processes) : null

    return (
        <div className="top-margin">
            <LogoInput onSelect={loadInputFile} /> {deleteButton}
        </div>
    )
}

export const LogoView: FC<RootData> = (rootData) => {
    const { settingsData, processes } = rootData
    const { settings } = settingsData

    if (!settings) {
        return <LoadingIcon color="black" />
    } else {
        const { logoUrl } = settings

        return (
            <div>
                <h1 className="title">
                    {t.settings.logo.current.get()}
                    <span className="title__sub-title">
                        <LogoNotes />
                    </span>
                </h1>
                <div className="top-margin">
                    <Logo url={logoUrl} />
                </div>
                {renderButtons(processes, logoUrl)}
            </div>
        )
    }
}
